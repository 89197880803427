import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Stack from "@mui/system/Stack"
import AlertDialog from "components/dashboard/AlertDialog"
import TagField from "components/inputs/TagField"
import PageContainer from "components/layout/dashboard/PageContainer"
import { useDeleteUsersByEmails } from "features/users/hooks"
import { isEmpty } from "ramda"
import React, { useState } from "react"
import { toast } from "react-hot-toast"
import { useLocation } from "react-router-dom"

import { isNotEmpty, isNotNil } from "gather-common-including-video/dist/src/public/fpHelpers"

const PAGE_TITLE = "Bulk Manage Users"

const commaSeparatedEmailListToArray = (emailList: string) => emailList.split(",")

const IndexPage: React.FC = React.memo(function IndexPage() {
  const location = useLocation()
  const [emails, setEmails] = useState<string[]>(location.state?.emails ?? [])
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
  const deleteUsersByEmailsMutation = useDeleteUsersByEmails()

  const handleEmailInputChange = (newValues: string[]) => {
    const valuesToAdd = newValues
      .map((value) => commaSeparatedEmailListToArray(value))
      .flat()
      .map((email) => email.trim())
      .filter(isNotNil)

    setEmails(valuesToAdd)
  }

  const handleBulkDeleteUsers = async () => {
    try {
      const result = await deleteUsersByEmailsMutation.mutateAsync({ emails })

      toast.success(
        isEmpty(result)
          ? "No users with the specified emails were found"
          : `The following users have been successfully deleted: ${result.join(", ")}`,
      )

      setEmails([])
    } catch {
      // A toast will already show if the mutation fails
    }
  }

  const handleClickDeleteUsers = () => {
    setIsConfirmationModalOpen(true)
  }

  const handleConfirmationModalClose = (confirm: boolean) => {
    setIsConfirmationModalOpen(false)

    if (confirm) {
      handleBulkDeleteUsers()
    }
  }

  return (
    <>
      <PageContainer pageTitle={PAGE_TITLE}>
        <Box paddingTop={1} flexGrow={1}>
          <Stack spacing={2}>
            <TagField
              value={emails}
              name="emails"
              label="Emails"
              onChange={handleEmailInputChange}
              placeholder="Add an email by pressing enter (add multiple emails by separating with a comma and pressing enter)"
            />
            <Stack spacing={2}>
              <Box>
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleClickDeleteUsers}
                  disabled={!isNotEmpty(emails)}
                >
                  Delete Users
                </Button>
              </Box>
            </Stack>
          </Stack>
        </Box>
      </PageContainer>
      {/* To add multiple confirmation modals for different actions, add a new "confirmationModalType" state and
    switch rendering based on that */}
      <AlertDialog
        isOpen={isConfirmationModalOpen}
        onClose={handleConfirmationModalClose}
        title={`Permanently delete ${emails.length} users`}
        confirmButtonColor="error"
      >
        Are you sure you want to permanently delete the following users:{" "}
        <strong>{emails.join(", ")}</strong>
      </AlertDialog>
    </>
  )
})

export default IndexPage
