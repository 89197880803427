import { tsrAPI } from "gather-browser-common/dist/src/public/tsRestClient"
import { getErrorMessage } from "gather-common/dist/src/public/utils"
import { AdminSpaceMap } from "gather-http-common/dist/src/public/spaces"

export const listSpaceMaps: (spaceId: string | undefined) => Promise<AdminSpaceMap[]> = async (
  spaceId,
): Promise<Awaited<ReturnType<typeof tsrAPI.admin.spaces.maps.list>>> => {
  if (!spaceId) throw new Error("Missing spaceId")

  try {
    const spaceMaps = await tsrAPI.admin.spaces.maps.list({
      params: { spaceId },
    })
    return spaceMaps || []
  } catch (error) {
    throw new Error(getErrorMessage(error, "Failed to retrieve maps for space."))
  }
}
export const getSpaceMap: (
  spaceId?: string,
  mapId?: string,
) => Promise<AdminSpaceMap | undefined> = async (
  spaceId,
  mapId,
): Promise<Awaited<ReturnType<typeof tsrAPI.admin.spaces.maps.get>>> => {
  if (!spaceId) throw new Error("Missing spaceId")

  if (!mapId) throw new Error("Missing mapId")

  try {
    return await tsrAPI.admin.spaces.maps.get({
      params: { spaceId, mapId },
    })
  } catch (error) {
    throw new Error(getErrorMessage(error, "Failed to retrieve map."))
  }
}
