/**
 * The mime types we allow users to upload images for
 */
export const SUPPORTED_IMAGE_MIME_TYPES = [
  "image/apng",
  "image/flif",
  "image/webp",
  "image/png",
  "image/jpeg",
  "image/x-mng",
  "image/gif",
]

export const SUPPORTED_TRANSCRIPTION_MIME_TYPES = [
  "text/plain", // srt files do not have an specific mime type
  "text/vtt", // for WebVTT files
]

export enum UploadedFileType {
  ScreenRecording = "ScreenRecording",
  VirtualBackground = "VirtualBackground",
  Profile = "Profile",
}

// S3 buckets where we store user uploaded files
export enum S3Bucket {
  AudioOnlyRecording = "AudioOnlyRecording",
  ScreenRecording = "ScreenRecording",
  Transcriptions = "Transcriptions",
  MeetingSummaries = "MeetingSummaries",
  VirtualBackground = "VirtualBackground",
  Profile = "Profile",
}

/* UploadedFileType is what type of file the client wants to read/upload, S3Bucket
  is the S3Bucket that type of file is stored in. These are separate so we can easily
  swap out S3 for another storage service, or store multiple file types in the same bucket.
  For now UploadedFileType and S3Bucket have a 1-to-1 mapping
*/
export const FILE_TYPE_TO_BUCKET: Record<UploadedFileType, S3Bucket> = {
  [UploadedFileType.ScreenRecording]: S3Bucket.ScreenRecording,
  [UploadedFileType.VirtualBackground]: S3Bucket.VirtualBackground,
  [UploadedFileType.Profile]: S3Bucket.Profile,
}

export const getCacheControlFromUploadedFileType = (
  fileType: UploadedFileType,
): "max-age=604800" | undefined => {
  switch (fileType) {
    case UploadedFileType.ScreenRecording:
    case UploadedFileType.VirtualBackground:
      return undefined
    case UploadedFileType.Profile:
      return "max-age=604800"
  }
}

// Throws error if `type` is not valid UploadedFileType
export const getBucketFromUploadedFileType = (type: string): S3Bucket => {
  const validType =
    // This is necessary because `type` on FilePrisma is a string, not enum because we expect
    // UploadedFileType to change, and each change would require a migration if it were in prisma
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    Object.values<string>(UploadedFileType).includes(type) ? (type as UploadedFileType) : null
  if (!validType) throw new Error(`UploadedFileType ${type} is invalid`)

  return FILE_TYPE_TO_BUCKET[validType]
}

// `local`/`dev`/`test` should match those in `deploy/kubernetes/kustomize/localstack/init-localstack.sh`
export const S3_BUCKET_NAME_MAP: Record<
  S3Bucket,
  {
    [env: string]: string
  }
> = {
  [S3Bucket.VirtualBackground]: {
    local: "gather-town-virtual-backgrounds-dev",
    dev: "gather-town-virtual-backgrounds-dev",
    test: "gather-town-virtual-backgrounds-dev",

    staging: "gather-town-virtual-backgrounds-dev",
    prod: "gather-town-virtual-backgrounds-prod",
  },
  [S3Bucket.AudioOnlyRecording]: {
    local: "gather-town-av-audio-recordings-dev",
    dev: "gather-town-av-audio-recordings-dev",
    test: "gather-town-av-audio-recordings-dev",
    staging: "gather-town-av-audio-recordings-dev",
    prod: "gather-town-av-audio-recordings-prod",
  },
  [S3Bucket.ScreenRecording]: {
    local: "gather-town-av-recordings-dev",
    dev: "gather-town-av-recordings-dev",
    test: "gather-town-av-recordings-dev",

    staging: "gather-town-av-recordings-dev",
    prod: "gather-town-av-recordings-prod",
  },
  [S3Bucket.MeetingSummaries]: {
    local: "gather-town-av-meeting-summaries-dev",
    dev: "gather-town-av-meeting-summaries-dev",
    test: "gather-town-av-meeting-summaries-dev",

    staging: "gather-town-av-meeting-summaries-dev",
    prod: "gather-town-av-meeting-summaries-prod",
  },
  [S3Bucket.Transcriptions]: {
    local: "gather-town-av-meeting-transcripts-dev",
    dev: "gather-town-av-meeting-transcripts-dev",
    test: "gather-town-av-meeting-transcripts-dev",

    staging: "gather-town-av-meeting-transcripts-dev",
    prod: "gather-town-av-meeting-transcripts-prod",
  },
  [S3Bucket.Profile]: {
    local: "gather-town-photo-profiles-dev",
    dev: "gather-town-photo-profiles-dev",
    test: "gather-town-photo-profiles-dev",

    staging: "gather-town-photo-profiles-stg",
    prod: "gather-town-photo-profiles-prod",
  },
}

export const getS3BucketName = (bucket: S3Bucket): string | undefined => {
  const env = process.env.ENVIRONMENT || ""
  return S3_BUCKET_NAME_MAP[bucket][env]
}

export const S3_BUCKET_ALLOWED_MIME_TYPES: Record<S3Bucket, string[]> = {
  [S3Bucket.AudioOnlyRecording]: ["audio/mpeg"],
  [S3Bucket.ScreenRecording]: ["video/mp4"],
  [S3Bucket.Transcriptions]: SUPPORTED_TRANSCRIPTION_MIME_TYPES,
  [S3Bucket.MeetingSummaries]: ["application/json"],
  [S3Bucket.VirtualBackground]: SUPPORTED_IMAGE_MIME_TYPES,
  [S3Bucket.Profile]: SUPPORTED_IMAGE_MIME_TYPES,
}

export enum FilePermission {
  Any = "Any",
  User = "User",
  Space = "Space",
}

export interface UploadedFileInfo {
  id: string
  url: string
  headUrl?: string
  createdAt: Date
  filePath: string
}
