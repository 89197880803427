import { initContract } from "@ts-rest/core"
import { z } from "zod"

import { HttpV2Paths, RequestMethod } from "../../httpAPI"
import { ZodBuffer } from "../../zodHelpers"

const c = initContract()

export const wearableImages = c.router({
  create: {
    method: RequestMethod.POST,
    path: HttpV2Paths.AdminWearableImage,
    body: z.object({ bytes: ZodBuffer }),
    responses: { 200: c.type<string>() },
  },
})
