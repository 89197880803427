import { Buffer } from "buffer"

import { NewObjectVariant } from "gather-admin-common/dist/src/public/objectTemplates/types"
import { tsrAPI } from "gather-browser-common/dist/src/public/tsRestClient"
import { ObjectVariant } from "gather-common/dist/src/public/resources/objectTemplates"
import { UpdatedVariantFields } from "../../features/objectTemplates/mutations/types"
import { buildSpritesheet, buildVariantId } from "../../features/objectTemplates/utils"

/**
 * API request to create a new object variant
 * @param templateId - ID of the object template to add the variant to
 * @param fields - form body of object variant being created
 * @returns void promise
 */
export const submitVariant = async (
  templateId: string,
  fields: NewObjectVariant,
): Promise<string> => {
  try {
    return await tsrAPI.admin.objectTemplates.variants.create({
      params: { objectTemplateId: templateId },
      body: fields,
    })
  } catch (e) {
    let message = "Failed to create new variant."

    if (e instanceof Error) {
      message = e.message
    }
    return Promise.reject(message)
  }
}
/**
 * API request to update an object variant
 * @param templateId - ID of the template that the variant belongs to
 * @param variantId - ID of the variant to be updated
 * @param fields - form body of object variant being created
 * @returns void promise
 */
export const updateVariant = async (
  templateId: string,
  variantId: string,
  fields: UpdatedVariantFields,
): Promise<void> => {
  const spritesheet = buildSpritesheet(fields)
  const fieldsToSend = {
    spritesheet: spritesheet,
    color: fields.color,
    orientation: fields.orientation,
    default: fields.default,
    highlighted: fields.highlighted instanceof Buffer ? fields.highlighted : undefined,
    normal: fields.normal instanceof Buffer ? fields.normal : undefined,
  }

  try {
    return await tsrAPI.admin.objectTemplates.variants.update({
      params: { objectTemplateId: templateId, objectVariantId: variantId },
      body: fieldsToSend,
    })
  } catch (e) {
    let message = "Failed to update variant."

    if (e instanceof Error) {
      message = e.message
    }

    return Promise.reject(message)
  }
}
/**
 * Endpoint that does a switcheroo to assign a new default variant via two API requests
 * @param templateId - ID of the template that the variants belong to
 * @param currentDefault - the current default variant
 * @param newDefault - the new default variant
 * @returns Promise
 */
export const updateDefaultVariant = async (
  templateId: string,
  currentDefault: ObjectVariant | undefined,
  newDefault: ObjectVariant,
): Promise<void> => {
  try {
    // Update old default (if one exists) to be false
    if (currentDefault) {
      const currentId = buildVariantId(currentDefault)
      const currentDefaultFields = {
        ...currentDefault,
        spritesheet: undefined,
        highlighted: undefined,
        normal: undefined,
        default: false,
      }
      await tsrAPI.admin.objectTemplates.variants.update({
        params: { objectTemplateId: templateId, objectVariantId: currentId },
        body: currentDefaultFields,
      })
    }

    // Set new default to be true
    const newId = buildVariantId(newDefault)
    const newDefaultFields = {
      ...newDefault,
      spritesheet: undefined,
      highlighted: undefined,
      normal: undefined,
      default: true,
    }
    return await tsrAPI.admin.objectTemplates.variants.update({
      params: { objectTemplateId: templateId, objectVariantId: newId },
      body: newDefaultFields,
    })
  } catch (e) {
    let message = "Failed to update default variant."

    if (e instanceof Error) {
      message = e.message
    }

    return Promise.reject(message)
  }
}
/**
 * API request to delete a variant belonging to a specific object template
 * @param templateId - ID of object template the variant belongs to
 * @param variantId - ID of object variant to be deleted
 * @returns void Promise
 */
export const deleteVariant = async (templateId: string, variantId: string): Promise<void> => {
  try {
    return await tsrAPI.admin.objectTemplates.variants.delete({
      params: { objectTemplateId: templateId, objectVariantId: variantId },
    })
  } catch (e) {
    let message = "Failed to delete variant."

    if (e instanceof Error) {
      message = e.message
    }

    return Promise.reject(message)
  }
}
