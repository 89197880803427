import "firebase/auth"

import _firebase from "firebase/app"

import { config } from "./config"

// this data lives in the root `firebase.json`, but requiring JSON data from the root would be
// sketchy / impossible so just copy it here. 😞
const FIREBASE_AUTH_EMULATOR_PORT = "9099"

_firebase.initializeApp(config)

// We re-export the firebase namespace by aliasing the namespace (`import
// firebase = _firebase`) and exporting it.
export import firebase = _firebase
import { isTest } from "gather-env-config/dist/src/public/envShared"

// TODO @victor: one day when there are far fewer callsites importing auth from
// here, refactor this out into auth.ts for consistency
export const authObj = firebase.auth
export const auth = firebase.auth()
if (isTest) {
  auth.useEmulator(`http://127.0.0.1:${FIREBASE_AUTH_EMULATOR_PORT}`)
}

// We use the code below to exchange user information between different clients
// such as our Front-end App and the Chrome extension.
// More info [here](https://github.com/firebase/firebase-js-sdk/issues/1874).
// Firebase exports the User Class implementation but not the type, so we need
// to hardcode the types here. This may only work in the current version of the
// firebase SDK (8.10.1). Check the link above for other versions.
declare const FirebaseUser: {
  new (userData: unknown, tokens: unknown, data: unknown): _firebase.User
}
// @ts-expect-error
export const User: typeof FirebaseUser = firebase.User
