import {
  DarkModeRounded as DarkModeIcon,
  LightModeRounded as LightModeIcon,
} from "@mui/icons-material"
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Typography,
} from "@mui/material"
import useAuth from "features/authentication/useAuth"
import useRoles from "features/roles/useRoles"
import useSettings from "features/settings/useSettings"
import React, { useCallback, useRef, useState } from "react"
import { toast } from "react-hot-toast"
import { useNavigate } from "react-router-dom"
import { THEMES } from "theme/types"

const AccountPopover = () => {
  const anchorRef = useRef(null)
  const navigate = useNavigate()
  const { user, logout } = useAuth()
  const { roles } = useRoles()
  const { settings, saveSettings } = useSettings()
  const [open, setOpen] = useState<boolean>(false)

  const OppositeThemeIcon = settings.theme === THEMES.DARK ? LightModeIcon : DarkModeIcon

  const handleThemeChange = useCallback(() => {
    saveSettings({
      ...settings,
      theme: settings.theme === THEMES.DARK ? THEMES.LIGHT : THEMES.DARK,
    })
  }, [settings, saveSettings])

  const handleOpen = useCallback(() => {
    setOpen(true)
  }, [setOpen])

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [setOpen])

  const handleLogout = useCallback(async () => {
    try {
      handleClose()
      await logout()
      toast.success("You were successfully logged out!")
      navigate("/")
    } catch (err) {
      console.error(err)
      toast.error("Unable to logout :-(")
    }
  }, [handleClose, logout, navigate])

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={handleOpen}
        ref={anchorRef}
        sx={{
          alignItems: "center",
          display: "flex",
          border: 1,
          borderColor: "divider",
          borderRadius: "100%",
        }}
      >
        <Avatar
          src={user?.avatar}
          sx={{
            height: 32,
            width: 32,
          }}
        />
      </Box>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 240 },
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography color="textPrimary" variant="subtitle2">
            {user?.name}
          </Typography>
          <Typography color="textSecondary" variant="subtitle2">
            {roles?.join(", ")}
          </Typography>
        </Box>
        <Divider />
        <Box sx={{ mt: 2 }}>
          <MenuItem onClick={handleThemeChange}>
            <ListItemIcon>
              <OppositeThemeIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography color="textPrimary" variant="subtitle2">
                  {`Switch to ${settings.theme === THEMES.DARK ? "Light" : "Dark"} Mode`}
                </Typography>
              }
            />
          </MenuItem>
        </Box>
        <Box sx={{ p: 2 }}>
          <Button color="primary" fullWidth onClick={handleLogout} variant="outlined">
            Logout
          </Button>
        </Box>
      </Popover>
    </>
  )
}

export default AccountPopover
