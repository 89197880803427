import { always, cond, T } from "ramda"

import { RenderActionRow } from "./actions"
import { RenderConsoleRow } from "./Logs"
import { RenderNetworkTestResultsRow } from "./networkTestResults"
import { RenderRawRow } from "./raw"
import { RenderSFURow } from "./SFUMessages"
import {
  isActionMessage,
  isConsoleMessage,
  isNetworkTestResultMessage,
  isSFUMessage,
  isWebRTCIssueMessage,
} from "./utils"
import { RenderSimpleWebRTCIssueRow, RenderWebRTCIssueRow } from "./webrtcIssue"

export const sfuRenderer = cond([
  [isSFUMessage, always(RenderSFURow)],
  [T, always(null)],
])

export const actionRenderer = cond([
  [isActionMessage, always(RenderActionRow)],
  [T, always(null)],
])

export const webrtcIssueRenderer = cond([
  [isWebRTCIssueMessage, always(RenderSimpleWebRTCIssueRow)],
  [T, always(null)],
])

export const networkTestResultsRenderer = cond([
  [isNetworkTestResultMessage, always(RenderNetworkTestResultsRow)],
  [T, always(null)],
])

export const rawRenderer = () => RenderRawRow

export const combinedRenderers = cond([
  [isActionMessage, always(RenderActionRow)],
  [isWebRTCIssueMessage, always(RenderWebRTCIssueRow)],
  [isSFUMessage, always(RenderSFURow)],
  [isConsoleMessage, always(RenderConsoleRow)],
  [T, always(null)],
])
