import { ApiFetcherArgs, initClient } from "@ts-rest/core"
import { ClientArgs, InitClientArgs } from "@ts-rest/core/src/lib/client"
import { AppRoute, AppRouter } from "@ts-rest/core/src/lib/dsl"
import {
  ClientInferResponseBody,
  PartialClientInferRequest,
} from "@ts-rest/core/src/lib/infer-types"
import { AreAllPropertiesOptional, Prettify } from "@ts-rest/core/src/lib/type-utils"

import { axios, AxiosAdapter } from "gather-common-including-video/dist/src/public/axios"
import { contracts } from "gather-http-common/dist/src/public/contracts"
import { RequestMethod } from "gather-http-common/dist/src/public/httpAPI"
import { AxiosClientEvent, axiosClientEventEmitter } from "./axiosClientEventEmitter"
import { Logger } from "./Logger"

// TODO [Rebuild] dedupe type with other redundant definitions of AuthTokenManager
type AuthTokenManager = {
  waitForToken: () => Promise<string>
}

type SetupOptions = {
  apiBasePath: string
  authTokenManager: AuthTokenManager
  baseHeaders?: Record<string, string>
  customAdapter?: AxiosAdapter
}

const baseHeaders = { "Content-Type": "application/json" }

// This client is the "regular" ts-rest client, which allows specifying different response types
// based on the status code. It's a worse DX, but can be useful when returning non-200 status codes.
// Sample usage:
//
//       const res = await fullClient.ping.list();
//       if (res.status === 201) {
//         res.body // typed for the 201 response;
//       }
//
export const createTsrApiFullClient = (options: SetupOptions) =>
  initClient(contracts, {
    baseUrl: `${options.apiBasePath}/api/v2`,
    baseHeaders: { ...baseHeaders, ...options.baseHeaders },
    api: async (req) => {
      try {
        const result = await sendAxiosRequest(req, options.authTokenManager, options.customAdapter)
        return {
          status: result.status,
          body: result.data,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/consistent-type-assertions
          headers: result.headers as any,
        }
      } catch (e) {
        if (axios.isAxiosError(e)) throw e

        Logger.error("tsrAPIFullClient received non-axios error")
        throw e
      }
    },
  })

// This client extracts the data payload from axios, similar to the original http client. It throws
// an error if it receives a status code other than 200, because it means there's a mismatch between
// the expected type and what was received (should never happen).
export const createTsrApiClient = (options: SetupOptions) =>
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  (initClient as unknown as InitClientRetrieving200StatusResponse)(contracts, {
    baseUrl: `${options.apiBasePath}/api/v2`,
    baseHeaders: { ...baseHeaders, ...options.baseHeaders },
    api: async (req) => {
      try {
        const result = await sendAxiosRequest(req, options.authTokenManager, options.customAdapter)
        // TODO [Rebuild] before prod release we should probably change this to just log an error and
        //      notify us
        if (result.status !== 200) {
          // noinspection ExceptionCaughtLocallyJS
          throw new Error("Received non-200 success status code on client retrieving 200 statuses")
        }
        return result.data
      } catch (e) {
        return handleAxiosClientError(e)
      }
    },
  })

export type Extracted200StatusResponse<TRoute extends AppRoute> = Promise<
  Prettify<ClientInferResponseBody<TRoute, 200>>
>

/**
 * Code copied from https://github.com/ts-rest/ts-rest/blob/792d7a/libs/ts-rest/core/src/lib/client.ts#L14-L44.
 * Modified to extract the 200 status code type, which is predominantly what we will use and want.
 * The other option was to more deeply modify the contract type logic to specify a single response
 * type, but this seemed easier for now. Modifying the contract type system might require a much
 * heavier modification of the library.
 */
export type AppRouteFunctionExtracting200StatusResponse<
  TRoute extends AppRoute,
  TClientArgs extends ClientArgs,
  TArgs = PartialClientInferRequest<TRoute, TClientArgs>,
> = AreAllPropertiesOptional<TArgs> extends true
  ? (args?: Prettify<TArgs>) => Extracted200StatusResponse<TRoute>
  : (args: Prettify<TArgs>) => Extracted200StatusResponse<TRoute>

type RecursiveProxyObj<T extends AppRouter, TClientArgs extends ClientArgs> = {
  [TKey in keyof T]: T[TKey] extends AppRoute
    ? AppRouteFunctionExtracting200StatusResponse<T[TKey], TClientArgs>
    : T[TKey] extends AppRouter
    ? RecursiveProxyObj<T[TKey], TClientArgs>
    : never
}

type InitClientRetrieving200StatusResponse = <
  T extends AppRouter,
  TClientArgs extends InitClientArgs,
>(
  router: T,
  args: TClientArgs,
) => RecursiveProxyObj<T, TClientArgs>

const sendAxiosRequest = async (
  { path, method, headers, body }: ApiFetcherArgs,
  authTokenManager: AuthTokenManager,
  customAdapter?: AxiosAdapter,
) => {
  const authToken = await authTokenManager.waitForToken()
  return axios.request({
    // unclear why `method` is typed as `string` when it's always a known request method, but this
    // seems safe, any non-REST method defined in the API results in all sorts of TS / runtime errors
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    method: method as RequestMethod,
    url: path,
    headers: {
      authorization: `Bearer ${authToken}`,
      ...headers,
    },
    data: body,
    adapter: customAdapter,
  })
}

// TODO [Rebuild] test it works
const handleAxiosClientError = (error: unknown) => {
  if (!axios.isAxiosError(error)) throw error

  if (error.response?.status === 403 && error.response?.data?.error === "SSO_AUTHENTICATION") {
    axiosClientEventEmitter.publishEvent(AxiosClientEvent.SSOAuthenticationFailed)
  }
  throw error
}
