import "react-perfect-scrollbar/dist/css/styles.css"
import "nprogress/nprogress.css"

// import { Provider as ReduxProvider } from 'react-redux';
import { StyledEngineProvider } from "@mui/styled-engine"
import { AdapterLuxon as DateAdapter } from "@mui/x-date-pickers/AdapterLuxon"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AuthProvider } from "features/authentication/AuthContext"
import { SettingsProvider } from "features/settings/SettingsContext"
import React, { StrictMode } from "react"
import { createRoot } from "react-dom/client"
import { HelmetProvider } from "react-helmet-async"
import { BrowserRouter } from "react-router-dom"

// import store from 'store/index';
import App from "./App"

const rootElement = document.getElementById("root")
if (rootElement) {
  const root = createRoot(rootElement)
  root.render(
    <StrictMode>
      <LocalizationProvider dateAdapter={DateAdapter}>
        <HelmetProvider>
          <StyledEngineProvider injectFirst>
            <SettingsProvider>
              <BrowserRouter>
                <AuthProvider>
                  <App />
                </AuthProvider>
              </BrowserRouter>
            </SettingsProvider>
          </StyledEngineProvider>
        </HelmetProvider>
      </LocalizationProvider>
    </StrictMode>,
  )
} else {
  console.error("Could not find React root!")
}
