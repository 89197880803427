import { SvgIconComponent } from "@mui/icons-material"
import { Box, Link, SvgIconProps } from "@mui/material"
import React, { FC } from "react"
import { Link as RouterLink } from "react-router-dom"

interface Props {
  to: string
  title: string
  StartIcon?: SvgIconComponent
}

const BreadcrumbLink: FC<Props> = ({ to, title, StartIcon }) => {
  const iconProps: SvgIconProps = {
    fontSize: "small",
    sx: { mr: 1 },
  }

  const link = (
    <Link color="textPrimary" component={RouterLink} to={to} variant="subtitle2">
      {title}
    </Link>
  )

  if (StartIcon) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <StartIcon {...iconProps} />
        {link}
      </Box>
    )
  } else {
    return link
  }
}

export default BreadcrumbLink
