import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import Pagination from "@mui/material/Pagination"
import TabWrapper from "components/dashboard/TabWrapper"
import { useSpaceMaps } from "features/spaces/hooks"
import { applyPagination, applySort } from "features/tables/utils"
import MapCard from "pages/dashboard/mapInspector/MapsTab/partials/MapCard"
import React, { ChangeEvent, FC, useCallback, useMemo, useState } from "react"

import { AdminSpaceMap } from "gather-http-common/dist/src/public/spaces"

const PER_PAGE = 6

interface Props {
  spaceId?: string
}
const MapsTab: FC<Props> = ({ spaceId }) => {
  const { data, isLoading } = useSpaceMaps(spaceId)
  const allMaps = useMemo(() => data || [], [data])

  const [currentPage, setCurrentPage] = useState<number>(0)
  const pageCount = Math.ceil(allMaps.length / PER_PAGE)

  const mapsToDisplay: AdminSpaceMap[] = useMemo(() => {
    const sortedMaps = applySort<AdminSpaceMap>(allMaps, "id|desc")
    return applyPagination<AdminSpaceMap>(sortedMaps, currentPage, PER_PAGE)
  }, [allMaps, currentPage])

  const handlePageChange = useCallback(
    (_event: ChangeEvent<unknown>, page: number) => {
      setCurrentPage(page - 1)
    },
    [setCurrentPage],
  )

  return (
    <TabWrapper>
      <Box sx={{ width: "100%" }}>
        {isLoading && <Box sx={{ p: 2, width: "100%" }}>Loading...</Box>}

        {!isLoading && data && (
          <>
            <Grid container spacing={3}>
              {mapsToDisplay.map((map) => (
                <MapCard key={map.id} map={map} />
              ))}
            </Grid>

            {pageCount > 1 && (
              <Box
                sx={{
                  mt: 3,
                  mb: 3,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Pagination
                  count={pageCount}
                  onChange={handlePageChange}
                  page={currentPage + 1} // Pagination and TablePagination work differently :(
                />
              </Box>
            )}
          </>
        )}
      </Box>
    </TabWrapper>
  )
}

export default MapsTab
