import { initContract } from "@ts-rest/core"

import {
  SpaceRecording,
  SpaceRecordingBudgetInfo,
} from "gather-common/dist/src/public/resources/recording"
import { HttpV2Paths, RequestMethod } from "../../httpAPI"

const c = initContract()

export const recordings = c.router({
  list: {
    method: RequestMethod.GET,
    path: HttpV2Paths.SpaceRecordings,
    responses: { 200: c.type<SpaceRecording[]>() },
  },

  budget: {
    get: {
      method: RequestMethod.GET,
      path: HttpV2Paths.SpaceRecordingBudget,
      responses: { 200: c.type<SpaceRecordingBudgetInfo>() },
    },
  },
})
