// TODO: maybe only do this for local development?
// is it safe to assume process.env.ENVIRONMENT exists?

export const addToGlobalWindowForLocalDebugging = (properties: Record<string, unknown>): void => {
  const g = globalThis

  if (!("gatherDev" in g)) {
    Object.assign(g, { gatherDev: {} })
  }
  if (!("gatherDev" in g)) throw new Error("Object.assign to globalThis failed")

  const { gatherDev } = g
  if (typeof gatherDev !== "object" || gatherDev == null)
    throw new Error("globalThis.gatherDev is not an object?!")

  Object.assign(gatherDev, properties)
}
