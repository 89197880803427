import { Button, TableCell, TableRow, Typography } from "@mui/material"
import React, { FC, useCallback, useState } from "react"
import { toast } from "react-hot-toast"

import {
  PERSIST_RECORDING_TIME_MS,
  RecordingPrisma,
} from "gather-common/dist/src/public/resources/recording"
import { createSpaceUserRecording } from "../../../../api/spaces/users/recordings"

interface Props {
  recording: RecordingPrisma
  userId?: string
}

const defaultExpiry = (ended: Date | null) => {
  if (!ended) return ""
  const endedAt = new Date(ended)
  const expiry = endedAt.getTime() + PERSIST_RECORDING_TIME_MS
  return new Date(expiry).toISOString()
}

const URL_EXPIRATION_HOURS = 24
const URL_EXPIRATION_SECONDS = URL_EXPIRATION_HOURS * 60 * 60

const UserRecordingRow: FC<Props> = ({ recording }) => {
  const [downloadURLs, setDownloadURLs] = useState(new Map<string, string>())
  const updateURL = (recordingId: string, recordingS3URL: string) => {
    setDownloadURLs((map) => new Map(map.set(recordingId, recordingS3URL)))
  }

  const handleGenerateDownloadLink = useCallback(async () => {
    try {
      const url = await createSpaceUserRecording(
        recording.spaceId,
        recording.userId,
        recording.id,
        URL_EXPIRATION_SECONDS,
      )
      updateURL(recording.id, url)
      await navigator.clipboard.writeText(url)
      toast.success(`Download link copied to clipboard; expires in ${URL_EXPIRATION_HOURS} hours`)
    } catch (e) {
      toast.error("Failed to generate download link")
    }
  }, [])

  return (
    <TableRow hover key={recording.id}>
      <TableCell>
        <Typography color="textPrimary" variant="body2">
          {recording.id}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography color="textPrimary" variant="body2">
          {/* {"startedat"} recording.startedAt.toISOString()} */}
          {recording.startedAt.toString()}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography color="textPrimary" variant="body2">
          {/* {recording.endedAt?.toISOString()} */}
          {recording.endedAt?.toString() || "not ended"}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography color="textPrimary" variant="body2">
          {defaultExpiry(recording.endedAt)}
        </Typography>
      </TableCell>

      <TableCell>
        {downloadURLs.has(recording.id) ? (
          <Typography color="textPrimary" variant="body2">
            {downloadURLs.get(recording.id)}
          </Typography>
        ) : (
          <Button variant="contained" color="primary" onClick={handleGenerateDownloadLink}>
            Generate Download Link
          </Button>
        )}
      </TableCell>
    </TableRow>
  )
}

export default UserRecordingRow
