import { last } from "ramda"

import { validateSpaceId } from "gather-common/dist/src/public/stringHelpers"
import { createErrorClass } from "gather-common-including-video/dist/src/public/errors"
import { just } from "gather-common-including-video/dist/src/public/fpHelpers"
import { getAppBasePath } from "./basePath"

export const ThirdPartyURLError = createErrorClass("ThirdPartyURLError", {
  defaultMessage: `URL must include ${getAppBasePath()} and cannot be a third-party link. Try again or pass the space ID by itself instead.`,
})

export const UndefinedBasePathError = createErrorClass("UndefinedBasePathError", {
  defaultMessage: "API_BASE_PATH is undefined",
})

export const InvalidRoomPathError = createErrorClass("InvalidRoomPathError", {
  defaultMessage:
    "Path for Gather Space is invalid. Double check URL is a valid RoomPath before trying again.",
})

export const InvalidSpaceIDError = createErrorClass("InvalidSpaceIDError", {
  defaultMessage: "Space ID (on left-hand side of the slash) must be 16 characters long.",
})

export const isGatherURL: (str: string) => boolean = (str): boolean => {
  const basePath = getAppBasePath()

  if (!basePath) throw new UndefinedBasePathError()

  const gatherPath = new URL(basePath)
  const targetPath = new URL(str)
  const pathTest = new RegExp(gatherPath.host)
  return pathTest.test(targetPath.host)
}

export const isValidURL: (str: string) => boolean = (str): boolean => {
  try {
    new URL(str) // if this line fails, it will throw an error and indicate it is not a valid URL
    return true
  } catch (_) {
    return false
  }
}

export const parseSpaceIDInput: (str: string) => string = (str): string => {
  if (!isValidURL(str)) return str

  if (!isGatherURL(str)) throw new ThirdPartyURLError()

  const url = new URL(str)
  /**
   * This approach of splitting up the pathname means that /app/,
   * /dashboard/, and other urls that have a spaceID appended to
   * them will be usable in the search input field.
   */
  const splitted = decodeURI(url.pathname).split("/")
  const enoughParams = splitted.length > 2

  if (!enoughParams) throw new InvalidRoomPathError()

  return just(last(splitted))
}

export const extractSpaceIDFromUserInput: (userInput: string) => string = (userInput): string => {
  const spaceId = parseSpaceIDInput(userInput)

  if (!validateSpaceId(spaceId))
    throw new InvalidSpaceIDError("SpaceID is invalid. Please try again.")

  return spaceId
}
