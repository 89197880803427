import { DateTime } from "luxon"
import { z } from "zod"

const validateISODate = (isoDate: string) => DateTime.fromISO(isoDate).isValid

export const zodUpdateGuestBodySchema = z.discriminatedUnion("action", [
  z.object({ action: z.literal("revoke") }),
  z.object({
    action: z.literal("updateAccessExpiry"),
    expiresAt: z.string().refine(validateISODate, { message: "Must be a valid ISO date string." }),
  }),
  z.object({ action: z.literal("updateAccessIndefinite") }),
  z.object({ action: z.literal("hideFromList") }),
  z.object({ action: z.literal("promoteToMember") }),
])

export type UpdateGuestBody = z.infer<typeof zodUpdateGuestBodySchema>
