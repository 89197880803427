import { initContract } from "@ts-rest/core"
import { z } from "zod"

import { SpaceUserResource } from "gather-common/dist/src/public/resources/space"
import { HttpV2Paths, RequestMethod } from "../../../httpAPI"

const c = initContract()

export const users = c.router({
  delete: {
    method: RequestMethod.DELETE,
    path: HttpV2Paths.AdminAuthUsers,
    body: z.object({
      emails: z.array(z.string().email()),
    }),
    responses: { 200: c.type<{ emails: string[] }>() },
  },
  getByEmailOrId: {
    method: RequestMethod.GET,
    path: HttpV2Paths.AdminAuthUser,
    responses: {
      200: c.type<SpaceUserResource>(),
    },
  },
})
