/* External modules */
import { Buffer } from "buffer"

import ArrowLeftIcon from "@mui/icons-material/ArrowBack"
/* Material Icons */
import ArrowDownIcon from "@mui/icons-material/ArrowDownward"
import ArrowRightIcon from "@mui/icons-material/ArrowForward"
import ArrowUpIcon from "@mui/icons-material/ArrowUpward"
import DotsIcon from "@mui/icons-material/MoreHoriz"
import StarEmpty from "@mui/icons-material/StarBorderPurple500Sharp"
import StarFull from "@mui/icons-material/StarPurple500Sharp"
import { SvgIconTypeMap } from "@mui/material"
/* MUI Components */
import Avatar from "@mui/material/Avatar"
import Box from "@mui/material/Box"
import IconButton from "@mui/material/IconButton"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import { OverridableComponent } from "@mui/material/OverridableComponent"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import Typography from "@mui/material/Typography"
/* Local Modules */
import Label from "components/dashboard/Label"
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react"

import { NewObjectVariant } from "gather-admin-common/dist/src/public/objectTemplates/types"
import { getSrc } from "gather-browser-common/dist/src/public/imageUploadUtils"
import { Orientation } from "gather-common/dist/src/public/constants"
import { ObjectVariant } from "gather-common/dist/src/public/resources/objectTemplates"

interface Props {
  variant: ObjectVariant | NewObjectVariant
  index: number
  onEdit?: (variant: ObjectVariant | NewObjectVariant) => void
  onRemove?: (variant: ObjectVariant | NewObjectVariant) => void
  onUpdateDefault?: (variant: ObjectVariant | NewObjectVariant) => void
}

interface StarProps {
  show: boolean
}

const NoStar: FC<StarProps> = ({ show }) => (
  <Box
    sx={{
      opacity: show ? 1 : 0.2,
      transition: "opacity 0.2s ease-in-out",
    }}
  >
    <StarEmpty />
  </Box>
)

const ObjectVariantRow: FC<Props> = ({ variant, index, onEdit, onRemove, onUpdateDefault }) => {
  const moreRef = useRef(null)
  const [openMenu, setOpenMenu] = useState<boolean>(false)
  const [currentAvatar, setCurrentAvatar] = useState<string | Buffer | undefined>(variant.normal)
  const [showEmptyStar, setShowEmptyStar] = useState<boolean>(false)

  const showNormal = useCallback(
    () => setCurrentAvatar(variant.normal),
    [variant, setCurrentAvatar],
  )
  const showHighlight = useCallback(
    () => setCurrentAvatar(variant.highlighted ?? variant.normal),
    [variant, setCurrentAvatar],
  )

  const handleMenuOpen = () => {
    setOpenMenu(true)
  }

  const handleMenuClose = () => {
    setOpenMenu(false)
  }

  const handleDelete = () => {
    setOpenMenu(false)
    onRemove?.(variant)
  }

  const handleEdit = () => {
    setOpenMenu(false)
    onEdit?.(variant)
  }

  const handleUpdateDefault = useCallback(() => {
    if (variant && !variant.default) return onUpdateDefault?.(variant)
  }, [variant, onUpdateDefault])

  const enableStar = useCallback(() => setShowEmptyStar(true), [setShowEmptyStar])
  const disableStar = useCallback(() => setShowEmptyStar(false), [setShowEmptyStar])

  const DirectionIcon: OverridableComponent<SvgIconTypeMap> = useMemo(() => {
    switch (variant.orientation) {
      case Orientation.Up:
        return ArrowUpIcon
      case Orientation.Down:
        return ArrowDownIcon
      case Orientation.Left:
        return ArrowLeftIcon
      case Orientation.Right:
        return ArrowRightIcon
      default:
        return ArrowUpIcon
    }
  }, [variant])

  useEffect(() => {
    setCurrentAvatar(variant.normal)
  }, [variant])

  return (
    <TableRow
      hover
      key={`variantRow-${variant.color}-${index}`}
      onMouseEnter={enableStar}
      onMouseLeave={disableStar}
    >
      <TableCell>
        <Avatar
          variant="square"
          src={getSrc(currentAvatar)}
          onMouseEnter={showHighlight}
          onMouseLeave={showNormal}
        />
      </TableCell>

      <TableCell>
        <Box sx={{ display: "flex", alignItems: "baseline", gap: 1 }}>
          <Box
            sx={{
              bgcolor: variant.color,
              width: 14,
              height: 14,
              border: "solid 1px white",
              borderRadius: "3px",
            }}
          />
          <Typography color="textPrimary" variant="body2">
            {variant.color}
          </Typography>
        </Box>
      </TableCell>

      <TableCell>
        <Label>
          <DirectionIcon fontSize="small" sx={{ mr: 1 }} />
          {Orientation[variant.orientation]}
        </Label>
      </TableCell>

      <TableCell onClick={handleUpdateDefault} sx={{ cursor: "pointer" }}>
        {variant.default ? <StarFull /> : <NoStar show={showEmptyStar} />}
      </TableCell>

      {(onEdit || onRemove) && (
        <TableCell>
          <IconButton color="inherit" edge="end" onClick={handleMenuOpen} ref={moreRef}>
            <DotsIcon fontSize="small" />
          </IconButton>

          <Menu
            anchorEl={moreRef.current}
            anchorOrigin={{
              horizontal: "center",
              vertical: "bottom",
            }}
            keepMounted
            onClose={handleMenuClose}
            open={openMenu}
          >
            {onEdit && <MenuItem onClick={handleEdit}>Edit</MenuItem>}
            {onRemove && !variant.default && <MenuItem onClick={handleDelete}>Delete</MenuItem>}
          </Menu>
        </TableCell>
      )}
    </TableRow>
  )
}

export default ObjectVariantRow
