import { initContract } from "@ts-rest/core"

import { SpaceRolePermissionsMap } from "gather-common/dist/src/public/resources/space"
import { HttpV2Paths, RequestMethod } from "../../httpAPI"

const c = initContract()

export const permissions = c.router({
  list: {
    method: RequestMethod.GET,
    path: HttpV2Paths.SpacePermissions,
    responses: { 200: c.type<SpaceRolePermissionsMap>() },
  },
})
