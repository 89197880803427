import { SUPPORTED_IMAGE_MIME_TYPES } from "gather-common/dist/src/public/resources/files"

export const SUPPORTED_AUDIO_FORMATS: {
  [key: string]: string[]
} = {
  "audio/mpeg": ["audio/mpeg", "audio/MPA"],
  "audio/wav": ["audio/vnd.wave", "audio/wav", "audio/wave", "audio/x-wav", "audio/x-pn-wav"],
  "audio/ogg": ["audio/ogg"],
}

export const isValidAudio = (fileType: string): boolean =>
  !!Object.keys(SUPPORTED_AUDIO_FORMATS).find((mimeType) =>
    SUPPORTED_AUDIO_FORMATS[mimeType]?.includes(fileType),
  )

export const isValidImage = (fileType: string): boolean =>
  SUPPORTED_IMAGE_MIME_TYPES.includes(fileType)
