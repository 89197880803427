import { initContract } from "@ts-rest/core"
import { z } from "zod"

import { SurveyResponseType } from "gather-prisma-types/dist/src/public/client"
import { HttpV2Paths, RequestMethod } from "../../../httpAPI"
import { PerfSurveyAllowedResponse } from "../../../resources/perfSurveys"

const c = initContract()

const zodOptionalString = z.string().optional()
const zodOptionalNumber = z.number().optional()

export const perfSurveyAnswersValidation = z.record(z.string(), z.array(z.string()))

export const perf = c.router({
  get: {
    method: RequestMethod.GET,
    path: HttpV2Paths.SpacePerfSurvey,
    responses: { 200: c.type<PerfSurveyAllowedResponse>() },
  },
  create: {
    method: RequestMethod.POST,
    path: HttpV2Paths.SpacePerfSurvey,
    body: z.object({
      type: z.nativeEnum(SurveyResponseType),
      answers: perfSurveyAnswersValidation.optional(),
      elapsedTime: z.number().optional(),
      debugInfo: z
        .object({
          browserName: zodOptionalString,
          spaceUseCase: zodOptionalString,
          deviceInfo: zodOptionalString,
          locale: zodOptionalString,
          browserLanguage: zodOptionalString,
          commitHash: zodOptionalString,
          buildTimestamp: zodOptionalString,
          version: zodOptionalString,
          electron: z.boolean().optional(),
          operatingSystem: zodOptionalString,
          operatingSystemVersion: zodOptionalString,
          hwGpuPerformance: zodOptionalNumber,
          hwGpuPowerLevel: zodOptionalNumber,
          hwMemory: zodOptionalNumber,
          hwConcurrency: zodOptionalNumber,
          hwMeetsMinSpecs: zodOptionalString,
        })
        .optional(),
    }),
    responses: { 200: c.noBody() },
  },
})
