/* External modules */
import { BreadcrumbTrail } from "components/layout/dashboard/Breadcrumbs"
/* Local modules */
import PageContainer from "components/layout/dashboard/PageContainer"
import React, { FC } from "react"

import NewWearablesForm from "./partials/NewWearablesForm"

const PAGE_TITLE = "New Wearables"

const breadcrumbs: BreadcrumbTrail[] = [
  {
    to: "/dashboard/wearables",
    title: "Manage Wearables",
  },
]

const WearableNewFormPage: FC = () => (
  <PageContainer pageTitle={PAGE_TITLE} breadcrumbs={breadcrumbs}>
    <NewWearablesForm />
  </PageContainer>
)

export default WearableNewFormPage
