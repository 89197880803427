import { initContract } from "@ts-rest/core"

import { Wearable } from "gather-common/dist/src/public/resources/users"
import { HttpV2Paths, RequestMethod } from "../httpAPI"

const c = initContract()

export const wearables = c.router({
  get: {
    method: RequestMethod.GET,
    path: HttpV2Paths.Wearable,
    responses: { 200: c.type<Wearable | null>() },
  },
})
