import React, { useMemo } from "react"
import {
  findStepByTimestamp,
  usePointsOfInterest,
  useSessionMessages,
  useTimestamps,
} from "state/avClientStateViewerSlice"

import Timeline from "./Timeline"

const TimelineWrapper = () => {
  const messages = useSessionMessages()
  const timestamps = useTimestamps()
  const pointsOfInterestTypes = usePointsOfInterest()

  const pointsOfInterest = useMemo(
    () =>
      messages
        .filter(({ type }) => pointsOfInterestTypes.some((i) => type.startsWith(i)))
        .map((message) => ({
          value: findStepByTimestamp(message.timestamp, timestamps),
        })),
    [messages, timestamps],
  )

  return (
    <>
      <Timeline marks={pointsOfInterest} />
    </>
  )
}

export default TimelineWrapper
