import { initContract } from "@ts-rest/core"
import { z } from "zod"

import { HttpV2Paths, RequestMethod } from "../../../httpAPI"
import { SSOLinkedSpace } from "../../../resources/sso"
import { zodUuid } from "../../../zodHelpers"

const c = initContract()

export const linkedSpaces = c.router({
  list: {
    method: RequestMethod.GET,
    path: HttpV2Paths.SpaceSSOLinkedSpaces,
    responses: { 200: c.type<SSOLinkedSpace[]>() },
  },
  update: {
    method: RequestMethod.PATCH,
    path: HttpV2Paths.SpaceSSOLinkedSpaces,
    body: z.object({
      spaceId: zodUuid,
      verified: z.boolean().optional(),
    }),
    responses: { 200: c.noBody() },
  },
  delete: {
    method: RequestMethod.DELETE,
    path: HttpV2Paths.SpaceSSOLinkedSpaces,
    body: z.object({
      spaceId: zodUuid,
    }),
    responses: { 200: c.noBody() },
  },
})
