import { initContract } from "@ts-rest/core"
import { z } from "zod"

import { HttpV2Paths, RequestMethod } from "../../httpAPI"
import { CalendarEventPrisma } from "../../resources/calendars"

const c = initContract()

export const calendarEvents = c.router({
  list: {
    method: RequestMethod.GET,
    path: HttpV2Paths.SpaceCalendarEvents,
    query: z.object({
      // TODO: Validate / sanitize these as dates rather than directly passing them to Google's API.
      timeMax: z.string().optional(),
      timeMin: z.string().optional(),
      showDeclined: z
        .string()
        .optional()
        .transform((showDeclined) => showDeclined === "true"),
    }),
    responses: { 200: c.type<CalendarEventPrisma[]>() },
  },
  create: {
    method: RequestMethod.POST,
    path: HttpV2Paths.SpaceCalendarEvents,
    body: z.object({
      title: z.string(),
      start: z.object({
        dateTime: z.string(),
        timeZone: z.string(),
      }),
      end: z.object({
        dateTime: z.string(),
        timeZone: z.string(),
      }),
      location: z.string().optional(),
      description: z.string().optional(),
    }),
    responses: { 200: c.type<{ link: string | null | undefined }>() },
  },
  update: {
    method: RequestMethod.PATCH,
    path: HttpV2Paths.SpaceCalendarEvent,
    body: z.object({ location: z.string() }),
    responses: { 200: c.noBody() },
  },
})
