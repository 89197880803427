/* External modules */
/* MUI Components */
import Box from "@mui/material/Box"
import Divider from "@mui/material/Divider"
import { NEW_OBJECT_TEMPLATE_SCHEMA } from "features/objectTemplates/constants"
/* Local modules */
import useCreateObjectTemplate from "features/objectTemplates/mutations/useCreateObjectTemplate"
import { ObjectTemplateFields } from "features/objectTemplates/types"
import { Formik } from "formik"
import React, { FC } from "react"
import { toast } from "react-hot-toast"
import { useNavigate } from "react-router-dom"

import { Interaction } from "gather-http-common/dist/src/public/spaces"
import BulkUploadForm from "./BulkUploadForm"
import ObjectTemplateForm from "./ObjectTemplateForm"

const INITIAL_VALUES: ObjectTemplateFields = {
  desc: "",
  name: "",
  tags: [],
  type: Interaction.NONE,
  variants: [],
  distThreshold: undefined,
  previewMessage: undefined,
  deterministicUrlPrefix: undefined,
  url: undefined,
  preview: undefined,
  previewFileType: undefined,
  blurb: undefined,
  image: undefined,
  imageFileType: undefined,
  video: undefined,
  zoomLink: undefined,
  message: undefined,
  src: undefined,
  srcFileType: undefined,
  volume: 100,
  audioLoop: false,
  maxDistance: 5,
  isPositional: false,
}

const NewObjectTemplateForm: FC = () => {
  const navigate = useNavigate()

  const onSuccessfulCreation = () => {
    toast.success("Object Template was successfully created.")
  }

  const onCreationError = () => {
    toast.error(`Error: Object Template could not be created.`)
  }

  const { mutate: createObjTemplate } = useCreateObjectTemplate({
    onSuccess: onSuccessfulCreation,
    onError: onCreationError,
  })

  return (
    <Box sx={{ minWidth: 1100 }}>
      <Formik
        initialValues={INITIAL_VALUES}
        validationSchema={NEW_OBJECT_TEMPLATE_SCHEMA}
        onSubmit={async (values, { setStatus, setSubmitting }) => {
          try {
            const { src, volume, audioLoop, maxDistance, isPositional, ...fields } = values

            const toSubmit = {
              ...fields,
              sound:
                src !== undefined
                  ? {
                      src,
                      volume,
                      loop: audioLoop,
                      maxDistance,
                      isPositional,
                    }
                  : undefined,
            }

            createObjTemplate(toSubmit)
            setStatus({ success: true })
            setSubmitting(false)
            navigate("/dashboard/mapmaker/objects")
          } catch (error) {
            if (error instanceof Error) {
              const msg = error.message
              toast.error(msg)
            }
            setStatus({ success: false })
            setSubmitting(false)
          }
        }}
      >
        {(formik) => <ObjectTemplateForm {...formik} />}
      </Formik>

      <Divider sx={{ mt: 5 }} />

      <BulkUploadForm />
    </Box>
  )
}

export default NewObjectTemplateForm
