import { initContract } from "@ts-rest/core"
import { z } from "zod"

import { SpawnToken } from "gather-common/dist/src/public/resources/space"
import { HttpV2Paths, RequestMethod } from "../../httpAPI"

const c = initContract()

export const meetings = c.router({
  get: {
    method: RequestMethod.GET,
    path: HttpV2Paths.SpaceMeeting,
    responses: { 200: c.type<SpawnToken | null>() },
  },
  create: {
    method: RequestMethod.POST,
    path: HttpV2Paths.SpaceMeeting,
    body: z.object({
      /**
       * If this request does not have a "spawnToken" then,
       * `createSpaceMeeting` uses "capacity" to check
       * for an available nook with this capacity or greater.
       *
       * This will default to a "best fit" nook if
       * "useLargestAvailableArea" is false.
       *
       * If there is no valid meeting room, this endpoint defaults
       * to the space's default spawn location.
       */
      capacity: z
        .string()
        .optional()
        .transform((capacity) => (capacity ? parseInt(capacity) : undefined)),
      /**
       * This is a spawn token that specifies the meeting
       * location for the eventId provided.
       *
       * If this token is omitted, first this endpoint will
       * search for a pre-existing valid spawnToken to use.
       *
       * If there is no valid spawn token, this endpoint
       * will create a new spawnToken for the event using
       * the other criteria in this request.
       */
      spawnToken: z.string().optional(),
      /**
       * If a spawn token needs to be generated, should this
       * endpoint choose the largest available room for the
       * provided capacity or a "best fit" room.
       */
      useLargestAvailableArea: z
        .string()
        .optional()
        .transform((useLargestAvailableArea) => useLargestAvailableArea === "true"),
    }),
    responses: { 200: c.type<SpawnToken>() },
  },
})
