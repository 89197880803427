import { initContract } from "@ts-rest/core"
import { z } from "zod"

import { UserDevice } from "gather-prisma-types/dist/src/public/client"
import { HttpV2Paths, RequestMethod } from "../../httpAPI"

const c = initContract()

export const devices = c.router({
  update: {
    path: HttpV2Paths.UserDevice,
    method: RequestMethod.PATCH,
    body: z.object({
      oldToken: z.string().optional(),
    }),
    headers: z.object({
      "user-agent": z.string().optional(),
    }),
    responses: { 200: c.type<UserDevice>() },
  },

  delete: {
    path: HttpV2Paths.UserDevice,
    method: RequestMethod.DELETE,
    body: c.noBody(),
    responses: { 200: c.noBody() },
  },
})
