export function applyPagination<Type>(list: Array<Type>, page: number, limit: number): Array<Type> {
  return list.slice(page * limit, page * limit + limit)
}

// Lint warning auto-ignored when enabling the no-explicit-any rule. Fix this the next time this code is edited! TODO: @ENG-4294 Clean these up! See the linear task for guidance on how to do so.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const descendingComparator: (a: any, b: any, orderBy: string) => number = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) return -1

  if (b[orderBy] > a[orderBy]) return 1

  return 0
}

// Lint warning auto-ignored when enabling the no-explicit-any rule. Fix this the next time this code is edited! TODO: @ENG-4294 Clean these up! See the linear task for guidance on how to do so.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getComparator: (order: string, orderBy: string) => (a: any, b: any) => number = (
  order,
  orderBy,
) =>
  order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)

export function applySort<Type>(list: Array<Type>, sort: string): Array<Type> {
  const [orderBy, order] = sort.split("|")
  // @ts-expect-error Error auto-ignored when enabling TS noUncheckedIndexedAccess. If you're already touching this code, please clean this up while you're at it.
  // TODO: @ENG-4257 Clean these up! See the linear task for more context and advice for cleaning up.
  const comparator = getComparator(order, orderBy)
  // Lint warning auto-ignored when enabling the no-explicit-any rule. Fix this the next time this code is edited! TODO: @ENG-4294 Clean these up! See the linear task for guidance on how to do so.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const listForSorting = list.map((el: any, index: number) => [el, index])

  // Lint warning auto-ignored when enabling the no-explicit-any rule. Fix this the next time this code is edited! TODO: @ENG-4294 Clean these up! See the linear task for guidance on how to do so.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  listForSorting.sort((a: any, b: any) => {
    const newOrder = comparator(a[0], b[0])

    if (newOrder !== 0) return newOrder

    return a[1] - b[1]
  })

  // Lint warning auto-ignored when enabling the no-explicit-any rule. Fix this the next time this code is edited! TODO: @ENG-4294 Clean these up! See the linear task for guidance on how to do so.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return listForSorting.map((el: any) => el[0])
}
