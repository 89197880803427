import { toast } from "react-hot-toast"

import { tsrAPI } from "gather-browser-common/dist/src/public/tsRestClient"
import { RecordingPrisma } from "gather-common/dist/src/public/resources/recording"
import { getErrorMessage } from "gather-common/dist/src/public/utils"

export const listSpaceUserRecordings: (
  spaceId: string,
  userId: string,
) => Promise<RecordingPrisma[]> = async (
  spaceId,
  userId,
): Promise<Awaited<ReturnType<typeof tsrAPI.admin.spaces.users.recordings.list>>> => {
  try {
    const response = await tsrAPI.admin.spaces.users.recordings.list({
      params: {
        spaceId,
        userId,
      },
    })

    return response
  } catch (error) {
    const message = getErrorMessage(error, "Failed to retrieve recordings for user.")
    toast.error(message)
    return Promise.reject(message)
  }
}
export const createSpaceUserRecording = async (
  spaceId: string,
  userId: string,
  recordingId: string,
  expiresIn: number,
): Promise<string> => {
  try {
    const recording = await tsrAPI.admin.spaces.users.recordings.create({
      params: {
        spaceId,
        userId,
        recordingId,
      },
      body: {
        expires: expiresIn,
      },
    })
    return recording.s3URL
  } catch (e) {
    throw new Error(getErrorMessage(e, "Failed to generate recording URL for recording."))
  }
}
