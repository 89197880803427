import { DynamicGates } from "@gathertown/gather-game-common/dist/src/public/generated_DO_NOT_TOUCH/events"

export const DEFAULT_GATE_VALUE = { exposure: 0, spaces: [] }
export const VIDEO_SYSTEM_AUTOMATED_REPORT = "Video system automated debug reports"
export enum AvStrategy {
  Base = "base",
  Gather = "gather",
}

export interface LiveKitToken {
  serverURL: string
  token: string
}

export const TWILIO_TOKEN_TTL_S = 2 * 60 * 60

export const AVCLIENTSTATE_DEFAULT_PAGE_SIZE = 200

export const getFullDynamicGates = (dynamicGates: Partial<DynamicGates>): DynamicGates => ({
  livekitEnabled: dynamicGates["livekitEnabled"] ?? { exposure: 0, spaces: [] },
  livekitSelfhostEnabled: dynamicGates["livekitSelfhostEnabled"],
  gatherEnabled: dynamicGates["gatherEnabled"],
})

export const calculateDynamicAvStrategy = (
  spaceId: string | undefined,
  gates: DynamicGates,
): {
  avStrategy: AvStrategy
  minimumBuildTimestamp?: number
} => {
  const baseResult = {
    avStrategy: AvStrategy.Base,
  }
  const gatherResult = {
    avStrategy: AvStrategy.Gather,
    minimumBuildTimestamp: gates.gatherEnabled?.minimumBuildTimestamp,
  }

  // if we don't have a spaceId, just return the default, which is Base AV
  if (!spaceId) return baseResult

  if (gates.gatherEnabled?.spaces.includes(spaceId)) return gatherResult

  // No hits, fallback to Gather
  return gatherResult
}
