// TODO [Rebuild] This file currently need to stay as Node.js CommonJS module since it's also used in Webpack
// https://linear.app/gather-town/issue/PP-1720/pre-build-gather-modules-needed-by-webpack

const { isLocalOrTest } = require("gather-env-config/src/envShared")

const getApiBasePath = () => {
  // Meant to be replaced at compile time by Webpack
  // by `EnvironmentPlugin` from modules that will import this
  // or default to `window.location.origin` for backward compatibility
  if (process.env.API_BASE_PATH) {
    return process.env.API_BASE_PATH.match(/^https?:\/\//)
      ? `${process.env.API_BASE_PATH}`
      : `https://${process.env.API_BASE_PATH}`
  }

  if (isLocalOrTest) {
    // noinspection HttpUrlsUsage
    const host = typeof window === "undefined" ? "localhost" : window.location.hostname
    return `http://${host}:3000`
  }

  // This file gets loaded into amplitudes web workers where window doesn't exist.
  // window is also undefined in webpack build environments
  // It's safe to return nothing here as amplitude is not using this value
  if (typeof window === "undefined") return ""

  // note: this is unsafe because the url is normally app.[environment.]gather.town
  // TODO [Rebuild] clean up this logic (whole function)
  // As far as I (@nheaps) know, when API_BASE_PATH (not the env var) is used, it almost never makes it to this line.
  // API_BASE_PATH is supposed to be the route to the http server, but the origin is webpack/s3, so it's never the
  // actual http server. I think this may also be similar to why cloudflare had some routing for /api/ but didn't
  // look at that to be honest. It's "unsafe" because if you try to make an API call to the origin, then it won't work.
  return window.location.origin
}

const API_BASE_PATH = getApiBasePath()

const getSpriteServerUrl = () => {
  if (process.env.SPRITE_BASE_PATH) {
    return process.env.SPRITE_BASE_PATH.match(/^https?:\/\//)
      ? `${process.env.SPRITE_BASE_PATH}`
      : `https://${process.env.SPRITE_BASE_PATH}`
  }

  const env = process.env.ENVIRONMENT || "prod"
  const branchName = process.env.GIT_BRANCH
  const isBrowser = typeof window !== "undefined"

  // noinspection HttpUrlsUsage
  const urls = {
    prod: "https://dynamic-assets.gather.town",
    staging: "https://dynamic-assets.staging.gather.town",
    dev: branchName
      ? `https://${branchName}-dynamic-assets.dev.k8s.gather.town`
      : "https://dynamic-assets.staging.gather.town",
    local: `http://${isBrowser ? window.location.hostname : "localhost"}:3002`,
    test: `http://${isBrowser ? window.location.hostname : "localhost"}:3002`,
  }

  return urls[env]
}

const SPRITE_BASE_PATH = getSpriteServerUrl()

module.exports = { API_BASE_PATH, SPRITE_BASE_PATH }
