import { useQuery, UseQueryResult } from "react-query"

import { AVCLIENTSTATE_DEFAULT_PAGE_SIZE } from "gather-common/dist/src/public/av"
import * as AvClientStateSessionsAPI from "../../api/avClientStateSessions"

/**
 * Fetches all A/V client state sessions
 * @returns ReactQuery object where data is type of BrowserVideoClientStateSession[]
 */
export const useAvClientStateSessions = (
  page = 1,
  pageSize = AVCLIENTSTATE_DEFAULT_PAGE_SIZE,
  params: AvClientStateSessionsAPI.AvClientSessionParams = {},
): UseQueryResult<Awaited<ReturnType<typeof AvClientStateSessionsAPI.listAvClientStateSessions>>> =>
  useQuery(
    ["avClientStateSessions", { page, pageSize, params }],
    () => AvClientStateSessionsAPI.listAvClientStateSessions(page, pageSize, params),
    {
      initialData: undefined,
      retry: false,
    },
  )

/**
 * Fetches an A/V client state session by id
 * @returns ReactQuery object where data is type of BrowserVideoClientStateSession | undefined
 */
export const useAvClientStateSessionById = (
  sessionId?: string,
): UseQueryResult<
  Awaited<ReturnType<typeof AvClientStateSessionsAPI.fetchAvClientStateSessionById>>
> =>
  useQuery(
    ["avClientStateSession", sessionId],
    () => AvClientStateSessionsAPI.fetchAvClientStateSessionById(sessionId),
    {
      initialData: undefined,
      enabled: !!sessionId,
      retry: false,
      refetchOnWindowFocus: false,
    },
  )

export const useAvClientStateUserFeedbackBySessionId = (
  sessionId?: string,
): UseQueryResult<
  Awaited<ReturnType<typeof AvClientStateSessionsAPI.fetchAvClientStateUserFeedbackBySessionId>>
> =>
  useQuery(
    ["avClientStateUserFeedback", sessionId],
    () => AvClientStateSessionsAPI.fetchAvClientStateUserFeedbackBySessionId(sessionId),
    {
      initialData: undefined,
      enabled: !!sessionId,
      retry: false,
      refetchOnWindowFocus: false,
    },
  )
