import { initContract } from "@ts-rest/core"

import { UserAccess } from "gather-common/dist/src/public/resources/space"
import { HttpV2Paths, RequestMethod } from "../../../httpAPI"

const c = initContract()

export const access = c.router({
  get: {
    method: RequestMethod.GET,
    path: HttpV2Paths.UserSpaceAccess,
    responses: { 200: c.type<UserAccess>() },
  },
})
