import {
  ChevronRightRounded as ChevronRightIcon,
  ExpandMoreRounded as ChevronDownIcon,
} from "@mui/icons-material"
import { Box, Button, Collapse, ListItem } from "@mui/material"
import { SxProps } from "@mui/system"
import React, { FC, useState } from "react"
import { NavLink as RouterLink } from "react-router-dom"

import { AdminPermission } from "gather-admin-common/dist/src/public/roles/types"

export interface NavItemProps {
  active?: boolean
  children?: React.ReactNode
  depth?: number
  icon?: React.ReactNode
  info?: React.ReactNode
  open?: boolean
  path?: string
  title?: string
  sx?: SxProps
  permissions?: AdminPermission[]
  requiresAll?: boolean
}

const NavItem: FC<NavItemProps> = ({
  active,
  children,
  depth = 0,
  icon,
  info,
  open: openProp,
  path,
  title,
  ...other
}) => {
  const [open, setOpen] = useState(openProp)

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  let paddingLeft = 16

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth
  }

  // Branch
  if (children) {
    return (
      <ListItem
        disableGutters
        sx={{
          display: "block",
          py: 0,
        }}
        {...other}
      >
        <Button
          endIcon={
            !open ? <ChevronRightIcon fontSize="small" /> : <ChevronDownIcon fontSize="small" />
          }
          onClick={handleToggle}
          startIcon={icon}
          sx={{
            color: "text.secondary",
            fontWeight: "fontWeightMedium",
            justifyContent: "flex-start",
            pl: `${paddingLeft}px`,
            pr: "8px",
            py: "12px",
            textAlign: "left",
            textTransform: "none",
            width: "100%",
          }}
          variant="text"
        >
          <Box sx={{ flexGrow: 1 }}>{title}</Box>
          {info}
        </Button>
        <Collapse in={open}>{children}</Collapse>
      </ListItem>
    )
  }

  // Leaf
  return (
    <ListItem
      disableGutters
      sx={{
        display: "flex",
        py: 0,
      }}
    >
      <Button
        component={path ? RouterLink : "button"}
        startIcon={icon}
        sx={{
          color: "text.secondary",
          fontWeight: "fontWeightMedium",
          justifyContent: "flex-start",
          textAlign: "left",
          pl: `${paddingLeft}px`,
          pr: "8px",
          py: "12px",
          textTransform: "none",
          width: "100%",
          ...(active && {
            color: "primary.main",
            fontWeight: "fontWeightBold",
            "& svg": {
              color: "primary.main",
            },
          }),
        }}
        variant="text"
        to={path}
      >
        <Box sx={{ flexGrow: 1 }}>{title}</Box>
        {info}
      </Button>
    </ListItem>
  )
}

export default NavItem
