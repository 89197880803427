import { Box } from "@mui/material"
import { nth, pipe, split } from "ramda"
import React, { FC, memo } from "react"

import { just } from "gather-common-including-video/dist/src/public/fpHelpers"
import { RenderRowProps, WithRestProps } from "./types"
import { formatMultiArgumentPayloads, formatTimestamp, isConsoleMessage } from "./utils"

const getConsoleLevel = pipe(split("/"), nth(1), just)

export const RenderConsoleRow: FC<WithRestProps<RenderRowProps>> = memo(
  ({ message, className = "", ...props }) => {
    if (!isConsoleMessage(message)) return null
    const { payload, type, timestamp } = message

    return (
      <div
        className={`row-message log-message log-message-${getConsoleLevel(type)} ${className}`}
        {...props}
      >
        <Box flexShrink={0}>{formatTimestamp(timestamp)}</Box>
        <div>{formatMultiArgumentPayloads(payload)}</div>
      </div>
    )
  },
)
