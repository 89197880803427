import { Box, Button, Divider, Typography } from "@mui/material"
import { equals, propSatisfies } from "ramda"
import React, { FC } from "react"
import { getLiveKitSessionURL } from "utils/livekit"

import { BrowserVideoClientStateSession } from "gather-prisma-types/dist/src/public/client"

const isLiveKitStrategy = propSatisfies(equals("livekit"), "avStrategy")

export type LiveKitMetadataProps = {
  session?: BrowserVideoClientStateSession
}

const LiveKitMetadata: FC<LiveKitMetadataProps> = ({ session }) => {
  if (!session) return null

  const isLivekit = isLiveKitStrategy(session)

  const { liveKitParticipantId, liveKitSessionId } = session

  return isLivekit ? (
    <>
      <Typography variant="h4">LiveKit Metadata</Typography>
      <Divider sx={{ margin: "16px 0" }} />
      <Box mb={2} marginX={2}>
        <dl>
          <dt>Session ID</dt>
          <dd>{liveKitSessionId}</dd>
          <dt>Participant ID</dt>
          <dd>{liveKitParticipantId}</dd>
        </dl>
        <Button
          variant="contained"
          target="_blank"
          href={getLiveKitSessionURL(liveKitSessionId ?? "", liveKitParticipantId ?? "")}
        >
          View in LiveKit Cloud
        </Button>
      </Box>
    </>
  ) : null
}

export default LiveKitMetadata
