import QueryKeys from "features/queryKeys"
import { useQuery, UseQueryResult } from "react-query"

import { getSpace } from "../../api/spaces"
import { getSpaceMap, listSpaceMaps } from "../../api/spaces/maps"
import { getSpaceRecordingBudget } from "../../api/spaces/recordings"
import { listSpaceUsers } from "../../api/spaces/users"

/**
 * Fetches a space's details using React Query
 * @param spaceId - the ID of the space
 * @returns React Query object where data is type of SpaceInfo | undefined
 */
export const useSpace = (spaceId?: string): UseQueryResult<Awaited<ReturnType<typeof getSpace>>> =>
  useQuery([QueryKeys.Space, spaceId], () => getSpace(spaceId), {
    initialData: undefined,
    enabled: !!spaceId && spaceId.length > 0,
    retry: false,
    refetchOnWindowFocus: false,
  })

/**
 * Fetches the raw data for a specific space map using React Query
 * @param mapId - the ID of the map being requested
 * @param spaceId - the ID of the space that the map belongs to
 * @returns React Query object where data is type of GameMapDB[] | undefined
 */
export const useSpaceMap = (
  mapId: string,
  spaceId?: string,
): UseQueryResult<Awaited<ReturnType<typeof getSpaceMap>>> =>
  useQuery([QueryKeys.SpaceMaps, spaceId, { id: mapId }], () => getSpaceMap(spaceId, mapId), {
    initialData: undefined,
    enabled: mapId.length > 0,
    retry: false,
    refetchOnWindowFocus: false,
  })

/**
 * Fetches a list of maps for a space using React Query
 * @param spaceId - the ID of the space that the maps belong to
 * @returns React Query object where data is type of GameMapDB[] | undefined
 */
export const useSpaceMaps = (
  spaceId?: string,
): UseQueryResult<Awaited<ReturnType<typeof listSpaceMaps>>> =>
  useQuery([QueryKeys.SpaceMaps, spaceId], () => listSpaceMaps(spaceId), {
    initialData: undefined,
    enabled: !!spaceId,
    retry: false,
    refetchOnWindowFocus: false,
  })

/**
 * Fetches a list of users that belong to a space using React Query
 * @param spaceId - the ID of the space that the users belong to
 * @returns React Query object where data is type of User[] | undefined
 */
export const useSpaceUsers = (
  spaceId?: string,
): UseQueryResult<Awaited<ReturnType<typeof listSpaceUsers>>> =>
  useQuery([QueryKeys.SpaceUsers, spaceId], () => listSpaceUsers(spaceId), {
    initialData: undefined,
    enabled: !!spaceId,
    retry: false,
    refetchOnWindowFocus: false,
  })

export const useSpaceRecordingBudgetInfo = (
  spaceId?: string,
): UseQueryResult<Awaited<ReturnType<typeof getSpaceRecordingBudget>>> =>
  useQuery([QueryKeys.SpaceRecordingBudgetInfo, spaceId], () => getSpaceRecordingBudget(spaceId), {
    initialData: undefined,
    enabled: !!spaceId,
    retry: false,
    refetchOnWindowFocus: false,
  })
