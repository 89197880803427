import { initContract } from "@ts-rest/core"
import { z } from "zod"

import { AdminRoleTypePrisma } from "gather-prisma-types/dist/src/public/client"
import { HttpV2Paths, RequestMethod } from "../../httpAPI"
import { AdminUser } from "../../superAdminUsers"

const c = initContract()

export const superAdminUsers = c.router({
  list: {
    method: RequestMethod.GET,
    path: HttpV2Paths.SuperAdminUsers,
    responses: { 200: c.type<AdminUser[]>() },
  },
  create: {
    method: RequestMethod.POST,
    path: HttpV2Paths.SuperAdminUsers,
    body: z.object({
      email: z.string().email(),
      roles: z.array(z.nativeEnum(AdminRoleTypePrisma)),
    }),
    responses: {
      200: c.type<string>(),
    },
  },
  update: {
    method: RequestMethod.PATCH,
    path: HttpV2Paths.SuperAdminUser,
    body: z.object({
      roles: z.array(z.nativeEnum(AdminRoleTypePrisma)),
    }),
    responses: {
      200: c.noBody(),
    },
  },
  delete: {
    method: RequestMethod.DELETE,
    path: HttpV2Paths.SuperAdminUser,
    body: c.noBody(),
    responses: {
      200: c.noBody(),
    },
  },
})
