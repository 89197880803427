import { initContract } from "@ts-rest/core"

import { HttpV2Paths, RequestMethod } from "../../../httpAPI"

const c = initContract()

export const capacity = c.router({
  get: {
    method: RequestMethod.GET,
    path: HttpV2Paths.AdminSpaceCapacity,
    responses: {
      200: c.type<{ maxCapacity: number; hasActiveReservation: boolean }>(),
    },
  },
})
