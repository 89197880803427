import { DateTime } from "luxon"

import { RecordingPrisma } from "gather-prisma-types/dist/src/public/client"

// Information about the current egress (recording) returned by livekit
export interface EgressInfo {
  egressId?: string
  roomId?: string
  roomName?: string
  startedAt?: number
  endedAt?: number
  fileId: string
  fileName: string
  recordingClientId: string
}

export enum StopRecordingStatus {
  Success = "Success",
  StillStarting = "Still Starting",
  RecordingFailed = "Recording Failed",
  Unknown = "Unknown",
  InitFailure = "InitFailure",
}

export type SpaceRecording = Omit<
  RecordingPrisma,
  "createdAt" | "updatedAt" | "startedAt" | "endedAt" | "fileId" | "expiresAt"
> & {
  startedAt: string | null
  endedAt: string | null
  createdAt: string
  updatedAt: string
  expiresAt: string
  fileId: string
}

export interface SpaceRecordingBudgetInfo {
  // annual or monthly plan
  hasValidPaidPlanForRecording: boolean
  recordingTimeUsedMs: number
  recordingBudgetMs: number
  currentSubscriptionStart: string
}

export const createRecordingFileName = (): string => {
  const now = DateTime.now()
  return `gather-recording-${now.startOf("second").toISO({
    includeOffset: false,
    includePrefix: false,
    suppressMilliseconds: true,
  })}.mp4`
}

// how long we tell people we will keep it for, used to calculate countdown time
export const PERSIST_RECORDING_TIME_DAYS = 5
export const PERSIST_RECORDING_TIME_MS = PERSIST_RECORDING_TIME_DAYS * 24 * 60 * 60 * 1000
// how long we actually still show the recording in the menu
export const PERSIST_RECORDING_TIME_STILL_SHOW_MS = PERSIST_RECORDING_TIME_MS + 60 * 60 * 1000 // show recording for an additional hour
// how long until we fully delete the recording
export const DELETE_RECORDING_TIME_MS = 30 * 24 * 60 * 60 * 1000
export const MAXIMUM_RECORDING_LENGTH_MS = 24 * 60 * 60 * 1000
export const RECORDING_PER_USER_MS = 2 * 60 * 60 * 1000

// we ignore recording info if it's this old without having started
export const IGNORE_STALE_INITIATING_RECORDINGS_THRESHOLD = 60_000
