import SplashScreen from "components/SplashScreen"
import useAuth from "features/authentication/useAuth"
import React, { createContext, FC, useCallback, useEffect, useReducer } from "react"
import { toast } from "react-hot-toast"

import { getPermissionsFromRoles } from "gather-admin-common/dist/src/public/roles/permissions"
import { AdminPermission } from "gather-admin-common/dist/src/public/roles/types"
import { AdminRoleTypePrisma } from "gather-prisma-types/dist/src/public/client"
import { listAdminUserRoles } from "../../api/superAdminUsers/roles"

interface RoleState {
  isInitialized: boolean
  permissions: AdminPermission[]
  roles: AdminRoleTypePrisma[]
}

const initialState: RoleState = {
  isInitialized: false,
  permissions: [],
  roles: [],
}

// Lint warning auto-ignored when enabling the no-explicit-any rule. Fix this the next time this code is edited! TODO: @ENG-4294 Clean these up! See the linear task for guidance on how to do so.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reducer = (state: any, action: any) => {
  if (action.type === "ROLE_STATE_CHANGED") {
    const { permissions, roles } = action.payload

    return {
      ...state,
      isInitialized: true,
      permissions,
      roles,
    }
  }

  return state
}

const RoleContext = createContext({
  ...initialState,
})

interface RoleProviderProps {
  children: string | React.ReactNode
}

export const RoleProvider: FC<RoleProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const { user } = useAuth()
  const origin = process.env.API_BASE_PATH

  const fetchRoles = useCallback(
    async (userId: string): Promise<AdminRoleTypePrisma[]> => {
      try {
        const roles = await listAdminUserRoles(userId)
        const permissions: AdminPermission[] = getPermissionsFromRoles(roles)

        dispatch({
          type: "ROLE_STATE_CHANGED",
          payload: {
            roles,
            permissions,
          },
        })

        return roles
      } catch (error) {
        const message = "Failed to retrieve user roles."
        toast.error(message)
        return Promise.reject(message)
      }
    },
    [dispatch, origin],
  )

  useEffect(() => {
    if (user?.id) {
      fetchRoles(user.id)
    }
  }, [user, fetchRoles])

  return (
    <RoleContext.Provider
      value={{
        ...state,
        fetchRoles,
      }}
    >
      {state.isInitialized ? children : <SplashScreen />}
    </RoleContext.Provider>
  )
}

export default RoleContext
