export enum Env {
  test = "test",
  local = "local",
  dev = "dev",
  staging = "staging", // TODO [rebuild] can this be staging => stg to align with elsewhere? (also in config_continue.yml)
  prod = "prod",
}

export const currentEnv = (): Env => {
  const env = process.env.ENVIRONMENT || ""

  // normally I'd use enumFromValue but didn't want to make a dependency for this package
  if (!(env in Env)) throw new Error(`Unexpected environment: ${env}`)

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return Env[env as Env]
}

function resolveValue<T>(value: T | (() => T)): T {
  // if it's a function, call it, otherwise return the value, which works for
  // both null and non-function/primative values so no null check is necessary
  return value instanceof Function ? value() : value
}

// all these type overloads are to make sure that if you define them all you'll never get a null
// value back. If any of them might return or are null, the return value might be too. Order of the
// overrides matter, because () => T is ALSO T. T as a primative ALSO matches null
// This means that if you pass an object with values instead of functions, they'll all be evaluated too
// so don't wrap values in a simple object in `just()`.
export function switchEnv<T>(envs: { [env in Env]: () => T }, env?: Env): T
export function switchEnv<T>(envs: { [env in Env]: (() => T) | T }, env?: Env): T
export function switchEnv<T>(envs: { [env in Env]: T }, env: Env = currentEnv()): T {
  // in testing, we want to use the live value of process.env.ENVIRONMENT since
  // tests will overwrite it, but no need to keep looking it up during normal execution
  const res = envs[env]
  return resolveValue(res)
}
