import { initContract } from "@ts-rest/core"
import { z } from "zod"

import { WearableLayerLegacy } from "gather-common/dist/src/public/resources/users"
import { WearablePart } from "gather-prisma-types/dist/src/public/client"
import { HttpV2Paths, RequestMethod } from "../../httpAPI"

const c = initContract()

export const wearableParts = c.router({
  update: {
    method: RequestMethod.PATCH,
    path: HttpV2Paths.AdminWearablePart,
    body: z.object({
      spritesheetUrl: z.string().optional(),
      layerId: z.nativeEnum(WearableLayerLegacy).optional(),
    }),
    responses: { 200: c.type<WearablePart>() },
  },
})
