import { Box, Typography } from "@mui/material"
import Breadcrumbs, { BreadcrumbTrail } from "components/layout/dashboard/Breadcrumbs"
import React, { FC } from "react"
import { Helmet } from "react-helmet-async"

interface Props {
  pageTitle: string
  children: string | React.ReactNode
  breadcrumbs?: BreadcrumbTrail[]
  buttons?: string | React.ReactNode
}

const PageContainer: FC<Props> = ({ pageTitle, children, breadcrumbs, buttons }) => (
  <>
    <Helmet>
      <title>{pageTitle} | Gather Admin</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: "background.default",
        display: "flex",
        flexDirection: "column",
        minHeight: "100%",
        width: "100%",
        overflowY: "auto",
      }}
    >
      <Breadcrumbs pageTitle={pageTitle} trail={breadcrumbs} />

      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box sx={{ p: 3 }}>
          <Typography color="textPrimary" variant="h5">
            {pageTitle}
          </Typography>
        </Box>

        <Box sx={{ m: -1, mb: -2, p: 3 }}>{buttons}</Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          flexShrink: 1,
          overflowX: "auto",
          overflowY: "hidden",
        }}
      >
        <Box
          sx={{
            display: "flex",
            p: 3,
            pt: 0,
            width: "100%",
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  </>
)

export default PageContainer
