import SearchIcon from "@mui/icons-material/Search"
import { Theme } from "@mui/material"
/* Material UI Components */
import Box from "@mui/material/Box"
import InputAdornment from "@mui/material/InputAdornment"
import TextField from "@mui/material/TextField"
import { SxProps } from "@mui/system"
import React, { ChangeEvent, FC, KeyboardEvent } from "react"

interface Props {
  onKeyPress: (e: KeyboardEvent<HTMLInputElement>) => void
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  id: string
  placeholder: string
  label: string
  sx?: SxProps<Theme>
}

const SearchIdInput: FC<Props> = ({ onKeyPress, onChange, id, placeholder, label, sx = {} }) => (
  <Box
    sx={{
      m: 1,
      ml: 0,
      maxWidth: "100%",
      width: 350,
      ...sx,
    }}
  >
    <TextField
      fullWidth
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon fontSize="small" />
          </InputAdornment>
        ),
      }}
      label={label}
      onChange={onChange}
      onKeyPress={onKeyPress}
      placeholder={placeholder}
      value={id}
      variant="outlined"
    />
  </Box>
)

export default SearchIdInput
