import { isArray, isObject, isPlainObject, uniqueId } from "lodash"
import { DateTime } from "luxon"
import {
  allPass,
  clone,
  compose,
  Dictionary,
  has,
  hasIn,
  identity,
  isEmpty,
  not,
  nthArg,
  omit,
  pipe,
  scan,
  startsWith,
  tryCatch,
} from "ramda"

import {
  BaseMessage,
  DevicesChangedMessage,
  Message,
  NetworkTestResultMessage,
  RawMessage,
  SelectedDeviceChangedMessage,
  SFUMessage,
  timestampMessageObject,
  timestampPlayerInfo,
  timestampSelfInfo,
  ViewerMessage,
  ViewerMessageObject,
  ViewerMessagePrimitive,
} from "./types"

export const stripDetails = omit(["timestamp", "messageId"])

export const formatMultiArgumentPayloads = (val: string): string => {
  const result = JSON.parse(val)

  if (isArray(result))
    return result.map((i) => (isObject(i) ? JSON.stringify(i) : `${i}`)).join(" ")

  return val
}

export const addUniqueMessageId = (messages: Message[]): ViewerMessage[] =>
  messages.map((i) => ({ ...i, messageId: uniqueId() }))

export const isValidMessage = (value: unknown): value is Message =>
  allPass([compose(not, isEmpty), has("timestamp"), has("type")])(value)

export const isValidSession = (
  messages: unknown,
): messages is { timestamp: number; type: string }[] =>
  isArray(messages) && messages.every((message) => isValidMessage(message))

export const isMessageType =
  <T extends Message>(prefixes: string[]): ((target: Message) => target is T) =>
  (target: Message): target is T =>
    hasIn("type", target) && prefixes.some((prefix) => startsWith(prefix)(target.type))

export const isReplayMessageType =
  <T extends ViewerMessage>(prefixes: string[]): ((target: unknown) => target is T) =>
  (target: unknown): target is T =>
    isValidMessage(target) && isMessageType<T>(prefixes)(target) && hasIn("messageId", target)

export const isPlayerInfoMessage = isReplayMessageType<ViewerMessageObject>([
  "PLAYER_INFO",
  "DELETE_PLAYER_INFO",
])
export const isSelfInfoMessage = isReplayMessageType<ViewerMessageObject>(["SELF_INFO"])
export const isConsoleMessage = isReplayMessageType<ViewerMessagePrimitive>(["CONSOLE_MESSAGE"])
export const isSFUMessage = isReplayMessageType<SFUMessage>(["SFU_MESSAGE"])
export const isActionMessage = isReplayMessageType<ViewerMessagePrimitive>(["ACTION"])
export const isWebRTCIssueMessage = isReplayMessageType<ViewerMessagePrimitive>(["WEBRTC_ISSUE"])
export const isDevicesChangedMessage = isReplayMessageType<DevicesChangedMessage>([
  "DEVICES_CHANGED",
])
export const isSelectedDeviceChangedMessage = isReplayMessageType<ViewerMessageObject>([
  "SELECTED_DEVICE_CHANGED",
])
export const isNetworkTestResultMessage = isReplayMessageType<NetworkTestResultMessage>([
  "NETWORK_TEST_RESULTS",
])
export const isRawMessage = isReplayMessageType<RawMessage>([""])

export function getSnapshotBy<T extends timestampMessageObject>(
  messages: ViewerMessage[],
  condition: (message: ViewerMessage) => boolean,
): T[] {
  return scan<ViewerMessage, T[]>(
    (prev, msg) => [condition(msg) ? [msg.timestamp, msg] : [msg.timestamp, prev?.[0]?.[1] ?? {}]],
    [],
    messages,
  ).flat(1)
}

export function getSelectedDeviceSnapshot(
  messages: ViewerMessage[],
  deviceType: string,
): [number, SelectedDeviceChangedMessage][] {
  return getSnapshotBy(
    messages,
    (message) => isSelectedDeviceChangedMessage(message) && message.payload.type === deviceType,
  )
}

export function getSnapshots<T extends ViewerMessage>(messages: T[]): timestampPlayerInfo[] {
  return scan<T, timestampPlayerInfo[]>(
    (prev, msg) => {
      if (msg.type === "PLAYER_INFO" && isPlayerInfoMessage(msg)) {
        const aggregatedMsg: { [id: string]: unknown } = clone(prev?.[0]?.[1]) ?? {}
        const participantId = msg.payload.id
        if (participantId && typeof participantId === "string") {
          aggregatedMsg[participantId] = msg.payload
          return [[msg.timestamp, aggregatedMsg]]
        }
      } else if (msg.type === "DELETE_PLAYER_INFO" && isPlayerInfoMessage(msg)) {
        const newMsg: { [id: string]: unknown } = clone(prev?.[0]?.[1]) ?? {}
        const participantId = msg.payload.id
        if (participantId && typeof participantId === "string") {
          delete newMsg[participantId]
          return [[msg.timestamp, newMsg]]
        }
      }
      return [[msg.timestamp, prev?.[0]?.[1] ?? {}]]
    },
    [],
    messages,
  ).flat(1)
}

export function getDeviceSnapshots(messages: ViewerMessage[]): [number, DevicesChangedMessage][] {
  return getSnapshotBy(messages, isDevicesChangedMessage)
}

export function getSelfSnapshots(messages: ViewerMessage[]): timestampSelfInfo[] {
  return getSnapshotBy(messages, isSelfInfoMessage)
}

export const isDictionary = (target: unknown): target is Dictionary<unknown> =>
  isPlainObject(target)

export const attempJSONParse = tryCatch(JSON.parse, pipe(nthArg(1), identity))

export const formatTimestamp = (timestamp: number): string =>
  `${DateTime.fromMillis(timestamp ?? 0).toFormat("yyyy-MM-dd TT:SSS")}`

export const isViewerMessageObject = (message: BaseMessage): message is ViewerMessageObject =>
  "payload" in message && typeof message.payload === "object"
