import { TabContext, TabList } from "@mui/lab"
import { Box, Tab } from "@mui/material"
import { fromPairs } from "ramda"
import React, { FC, useMemo } from "react"
import { useCurrentTimestamp, useSessionMessages } from "state/avClientStateViewerSlice"

import { calculateDiff } from "./state.logic"
import StateTree from "./StateTree"
import { Pane, TabPanel, useTabs } from "./ui"
import { getSelfSnapshots, getSnapshots } from "./utils"

const StatePane: FC = () => {
  const timestamp = useCurrentTimestamp()
  const messages = useSessionMessages()

  const rawSnapshots = useMemo(() => getSnapshots(messages), [messages])
  const rawSelfSnapshots = useMemo(() => getSelfSnapshots(messages), [messages])

  const snapshots = useMemo(() => fromPairs(rawSnapshots), [rawSnapshots])
  const selfSnapshots = useMemo(() => fromPairs(rawSelfSnapshots), [rawSelfSnapshots])

  const differences = useMemo(() => calculateDiff(rawSnapshots), [rawSnapshots])
  const selfDifferences = useMemo(() => calculateDiff(rawSelfSnapshots), [rawSelfSnapshots])

  const currentState = snapshots[timestamp]
  const currentDifference = differences[timestamp]

  const currentSelfState = selfSnapshots[timestamp]
  const currentSelfDifference = selfDifferences[timestamp]

  const { currentTab, handleChange } = useTabs("selfState")

  return (
    <Pane>
      <TabContext value={currentTab}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList value={currentTab} onChange={handleChange}>
            <Tab label="State" value="state" />
            <Tab label="Diff" value="stateDiff" />
            <Tab label="(Self) State" value="selfState" />
            <Tab label="(Self) Diff" value="selfStateDiff" />
          </TabList>
        </Box>
        <Box sx={{ height: "100%", position: "relative" }}>
          <TabPanel value="state">
            <StateTree state={currentState?.payload ?? {}} />
          </TabPanel>
          <TabPanel value="stateDiff">
            <StateTree state={currentDifference ?? []} />
          </TabPanel>
          <TabPanel value="selfState">
            <StateTree state={currentSelfState?.payload ?? {}} />
          </TabPanel>
          <TabPanel value="selfStateDiff">
            <StateTree state={currentSelfDifference ?? []} />
          </TabPanel>
        </Box>
      </TabContext>
    </Pane>
  )
}

export default StatePane
