export type SnowflakeRecordType = string | number | Date | null

export type SnowflakeRowRecord = { [key: string]: SnowflakeRecordType }

export type SnowflakeResponse = SnowflakeRowRecord[]

export enum QueryName {
  // Office
  ActiveMembers = "activeMembers",
  PeakConcurrentUsers = "peakConcurrentUsers",
  ActiveDaysByMember = "activeDaysByMember",
  ConversationHours = "conversationHours",
  MedianConversationLength = "medianConversationLength",
  ConversationLength = "conversationLength",
  OnboardingMeasures = "onboardingMeasures",
  // Members
  ConversationTimeTable = "conversationTimeTable",
  ActiveDaysTable = "activeDaysTable",
  TimeInOfficeTable = "timeInOfficeTable",
  TimeInAvailableStatusTable = "timeInAvailableStatusTable",
  OnboardingTable = "onboardingTable",
}

export enum QueryDetailsName {
  // Members Details
  ConversationTimeTable = "conversationTimeTable",
}

export enum MultiQueryKeys {
  ActiveUsers = "activeUsers",
  ConversationCount = "conversationCount",
  MedianConversationLength = "medianConversationLength",
  PreviousActiveUsers = "previousActiveUsers",
  PreviousConversationCount = "previousConversationCount",
  PreviousMedianConversationLength = "previousMedianConversationLength",
}

export enum MultiQueryUnits {
  Users = "users",
  Hours = "hours",
  Minutes = "minutes",
  Conversations = "conversations",
}

export enum MultiQueryName {
  // Office Summary
  OfficeSummary = "officeSummary",
}

export enum InsightsResponseType {
  ChartData = "chartData",
  Summary = "summary",
}

export enum ChartFieldType {
  DateTime = "dateTime",
  Number = "number",
  String = "string",
}

export type ChartFieldDefinition = {
  fieldName: string
  fieldType: ChartFieldType
}

export type DataRow = (number | string | Date | null)[]

export type InsightsChartData = {
  type: InsightsResponseType.ChartData
  fields: ChartFieldDefinition[]
  data: DataRow[]
}

export type InsightsResponse = InsightsChartData

export type MultiDataRow = {
  [nameKey: string]: {
    value: number
    unit: MultiQueryUnits
  }
}

export type InsightsSummaryChartData = {
  type: InsightsResponseType.Summary
  data: MultiDataRow
}

export type LowDataInfo = {
  hasLowData: boolean
  spaceMembersCount: number
}

export type InsightsSummaryResponse = InsightsSummaryChartData & LowDataInfo
