import { concat, head, keys, pipe, reduce, reduceBy, toString, uniq } from "ramda"

import { BaseMessage, ViewerMessageObject } from "./types"
import { isPlayerInfoMessage } from "./utils"

export const getAllParticipants = <T extends BaseMessage>(messages: T[]) =>
  pipe(
    reduce<T, string[]>((acc, message) => {
      if (isPlayerInfoMessage(message)) {
        if (message.payload.hasOwnProperty("id") && typeof message.payload.id === "string")
          return concat(acc)([message.payload.id])
      }

      return acc
    }, []),
    uniq,
  )(messages)

// Used to get the timestamp from snapshots as key for objects
const getTimestampFromSnapshot = pipe(head, toString)

export const getParticipantsFromSnapshots = (snapshots: [number, ViewerMessageObject][]) =>
  reduceBy<[number, ViewerMessageObject], string[]>(
    (acc, [_, snapshot]) => pipe(keys, concat(acc), uniq)(snapshot.payload),
    [],
    getTimestampFromSnapshot,
    snapshots,
  )

export const activeParticipant = (currentParticipants: string[] = [], participant: string) =>
  currentParticipants.includes(participant) ? `active-participant` : ""
