import { initContract } from "@ts-rest/core"
import { z } from "zod"

import { HttpV2Paths, RequestMethod } from "../../../httpAPI"
import { RecurringCalendarEvent } from "../../../resources/calendars"

const c = initContract()

export const recurrent = c.router({
  list: {
    method: RequestMethod.GET,
    path: HttpV2Paths.SpaceCalendarRecurrent,
    responses: { 200: c.type<RecurringCalendarEvent[]>() },
  },
  create: {
    method: RequestMethod.POST,
    path: HttpV2Paths.SpaceCalendarRecurrent,
    body: z.array(z.object({ id: z.string(), calendarId: z.string(), capacity: z.number() })),
    responses: { 200: c.noBody() },
  },
})
