import React, { FC, ReactNode } from "react"

import Section from "./ui/Section"

export type LayoutProps = {
  left?: ReactNode
  right?: ReactNode
  center?: ReactNode
  mid?: ReactNode
  bottom?: ReactNode
  footer?: ReactNode
}

const Layout: FC<LayoutProps> = ({ left, right, center, mid, bottom, footer }) => (
  <>
    <div className="container">
      <Section area="left">{left}</Section>
      <Section area="center">{center}</Section>
      <Section area="right">{right}</Section>
      <Section area="mid">{mid}</Section>
      <Section area="bottom">{bottom}</Section>
      <Section area="footer" disableMaximize>
        {footer}
      </Section>
    </div>
  </>
)

export default Layout
