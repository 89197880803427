import { TextField, TextFieldProps } from "@mui/material"
import React, { ChangeEvent, FC, useCallback, useState } from "react"

export interface SelectOption {
  label: string
  value: string
}

interface Props {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  value: string
  options: SelectOption[]
  label?: string
}

export const useSelect = (initialValue = "") => {
  const [value, setValue] = useState(initialValue)
  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value)
  }, [])

  return {
    value,
    setValue,
    handleChange,
  }
}

const Select: FC<Props & TextFieldProps> = ({ options, label, ...props }) => (
  <TextField label={label} select SelectProps={{ native: true }} variant="outlined" {...props}>
    {options.map(({ label, value }) => (
      <option key={value} value={value}>
        {label}
      </option>
    ))}
  </TextField>
)

export default Select
