import { initContract } from "@ts-rest/core"
import { z } from "zod"

import { RecordingPrisma } from "gather-prisma-types/dist/src/public/client"
import { HttpV2Paths, RequestMethod } from "../../../../httpAPI"

const c = initContract()

export const recordings = c.router({
  list: {
    method: RequestMethod.GET,
    path: HttpV2Paths.AdminSpaceUserRecordings,
    responses: {
      200: c.type<RecordingPrisma[]>(),
    },
  },
  create: {
    method: RequestMethod.POST,
    path: HttpV2Paths.AdminSpaceUserRecording,
    body: z.object({
      expires: z.number(),
    }),
    responses: {
      200: c.type<RecordingPrisma & { s3URL: string }>(),
    },
  },
})
