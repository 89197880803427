import { Box, Button, Divider, TextField, Typography } from "@mui/material"
import { Formik } from "formik"
import React, { FC } from "react"

interface Props {
  onCancel: () => void
  onSubmit: (arg: { title: string; body: string }) => Promise<void>
}

const SendPushNotificationForm: FC<Props> = ({ onCancel, onSubmit }) => (
  <Formik
    initialValues={{
      title: "",
      body: "",
    }}
    onSubmit={onSubmit}
  >
    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
      <form onSubmit={handleSubmit}>
        <Box sx={{ p: 3 }}>
          <Typography align="center" color="textPrimary" gutterBottom variant="h5">
            Send push notification
          </Typography>
          <Typography align="center" color="red" gutterBottom variant="body1">
            Clicking send will send a real push notification to all of the user's registered devices
          </Typography>
        </Box>

        <Box sx={{ px: 3, pb: 2 }}>
          <TextField
            sx={{ m: 1 }}
            error={Boolean(touched.title && errors.title)}
            fullWidth
            helperText={touched.title && errors.title}
            label="Title"
            name="title"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.title}
            variant="outlined"
          />
          <TextField
            sx={{ m: 1 }}
            error={Boolean(touched.body && errors.body)}
            fullWidth
            helperText={touched.body && errors.body}
            label="Body"
            name="body"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.body}
            variant="outlined"
          />
        </Box>

        <Divider />

        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            p: 2,
          }}
        >
          <Box sx={{ flexGrow: 1 }} />
          <Button color="primary" onClick={onCancel} variant="text">
            Cancel
          </Button>

          <Button
            color="primary"
            disabled={isSubmitting}
            sx={{ ml: 1 }}
            type="submit"
            variant="contained"
          >
            Send
          </Button>
        </Box>
      </form>
    )}
  </Formik>
)

export default SendPushNotificationForm
