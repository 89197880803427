import { initContract } from "@ts-rest/core"

import { SpaceUserResource } from "gather-common/dist/src/public/resources/space"
import { HttpV2Paths, RequestMethod } from "../../../httpAPI"
import { grapes } from "./users/grapes"
import { recordings } from "./users/recordings"

const c = initContract()

export const users = c.router({
  list: {
    method: RequestMethod.GET,
    path: HttpV2Paths.AdminSpaceUsers,
    responses: {
      200: c.type<SpaceUserResource[]>(),
    },
  },
  grapes,
  recordings,
})
