import styled from "@emotion/styled"
import { Button, Card, TablePagination } from "@mui/material"
import { Box } from "@mui/system"
import SearchIdInput from "components/inputs/SearchIdInput"
import Select, { useSelect } from "components/inputs/Select"
import PageContainer from "components/layout/dashboard/PageContainer"
import { anyPass, complement, equals, filter, isEmpty } from "ramda"
import { ChangeEvent, MouseEvent, useCallback, useEffect, useMemo, useState } from "react"

import { AVCLIENTSTATE_DEFAULT_PAGE_SIZE } from "gather-common/dist/src/public/av"
import { useAvClientStateSessions } from "../../../features/avClientStateSessions/hooks"
import ClientStateSessionsTable from "./partials/ClientStateSessionsTable"
import useDeepLinking from "./useDeeplinking"

const DropdownStyled = styled.div`
  display: flex;
  gap: 8px;
  & > div {
    width: auto;
  }
`

const PAGE_TITLE = "A/V Client State Sessions"

const useSearchInput = (initialValue = "") => {
  const [draftValue, setDraftValue] = useState(initialValue)
  const [value, setValue] = useState(initialValue)
  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setDraftValue(event.target.value)
  }, [])

  const handleKeyPress = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
        setValue(draftValue)
      }
    },
    [draftValue],
  )

  const clear = useCallback(() => {
    setDraftValue("")
    setValue("")
  }, [])

  return {
    draftValue,
    setDraftValue,
    value,
    setValue,
    handleChange,
    handleKeyPress,
    clear,
  }
}

const IndexPage = () => {
  const { params: urlParams, setParams: setUrlParams } = useDeepLinking()
  const [pageIndex, setPageIndex] = useState(Number(urlParams.get("pageIndex") ?? 0))
  const [pageSize, setPageSize] = useState(
    Number(urlParams.get("pageSize") ?? AVCLIENTSTATE_DEFAULT_PAGE_SIZE),
  )
  const {
    value: strategyValue,
    handleChange: handleStrategyChange,
    setValue: setStrategyValue,
  } = useSelect(urlParams.get("avStrategy") ?? "all")
  const {
    value: browserValue,
    handleChange: handleBrowserChange,
    setValue: setBrowserValue,
  } = useSelect(urlParams.get("userAgentBrowser") ?? "all")
  const {
    value: OSValue,
    handleChange: handleOSChange,
    setValue: setOSValue,
  } = useSelect(urlParams.get("userAgentOs") ?? "all")

  const {
    draftValue,
    value: idValue,
    handleKeyPress,
    handleChange,
    clear: clearId,
  } = useSearchInput(urlParams.get("spaceIdOrUserId") ?? "")

  const {
    draftValue: draftEmailValue,
    value: emailValue,
    handleKeyPress: handleEmailKeyPress,
    handleChange: handleEmailChange,
    clear: clearEmail,
  } = useSearchInput(urlParams.get("email") ?? "")

  const {
    draftValue: draftSessionIdValue,
    value: sessionIdValue,
    handleKeyPress: handleSessionIdKeyPress,
    handleChange: handleSessionIdChange,
    clear: clearSessionId,
  } = useSearchInput(urlParams.get("id") ?? "")

  const clearAll = useCallback(() => {
    setUrlParams({})
    setPageSize(AVCLIENTSTATE_DEFAULT_PAGE_SIZE)
    setPageIndex(0)
    clearId()
    clearEmail()
    clearSessionId()
    setStrategyValue("all")
    setBrowserValue("all")
    setOSValue("all")
  }, [])

  const params = useMemo(
    () => ({
      ...filter(complement(anyPass([equals("all"), isEmpty])), {
        userAgentBrowser: browserValue,
        userAgentOs: OSValue,
        avStrategy: strategyValue,
        spaceIdOrUserId: idValue,
        email: emailValue,
        id: sessionIdValue,
      }),
    }),
    [strategyValue, browserValue, OSValue, idValue, emailValue, sessionIdValue],
  )

  useEffect(() => {
    setUrlParams({
      ...params,
      pageIndex: `${pageIndex}`,
      pageSize: `${pageSize}`,
    })
  }, [params, pageIndex, pageSize, setUrlParams])

  const { data: sessions, isLoading } = useAvClientStateSessions(pageIndex + 1, pageSize, params)

  const handlePageChange = useCallback(
    (_event: MouseEvent<HTMLButtonElement> | null, pageIndex: number) => {
      setPageIndex(pageIndex)
    },
    [setPageIndex],
  )

  const handleLimitChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setPageSize(parseInt(event.target.value, 10))
      setPageIndex(0)
    },
    [setPageIndex, setPageSize],
  )

  return (
    <PageContainer pageTitle={PAGE_TITLE}>
      <Box sx={{ minWidth: 1100 }}>
        {isLoading && <Box>Loading...</Box>}
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            gap: 1,
            mb: 2,
            mt: 2,
          }}
        >
          <SearchIdInput
            onKeyPress={handleSessionIdKeyPress}
            onChange={handleSessionIdChange}
            id={draftSessionIdValue}
            placeholder="Search by UUID"
            label="Filter by CST ID"
          />

          <SearchIdInput
            onKeyPress={handleKeyPress}
            onChange={handleChange}
            id={draftValue}
            placeholder="Search by spaceId or userId"
            label="Filter by spaceId or userId"
          />

          <SearchIdInput
            onKeyPress={handleEmailKeyPress}
            onChange={handleEmailChange}
            id={draftEmailValue}
            placeholder="Search by email"
            label="Filter by email"
          />

          <DropdownStyled>
            <Select
              onChange={handleStrategyChange}
              value={strategyValue}
              options={[
                { value: "all", label: "All" },
                { value: "livekit", label: "LiveKit" },
                { value: "gather", label: "Gather" },
                { value: "agora", label: "Agora" },
              ]}
              label="Filter by AV Strategy"
            />

            <Select
              onChange={handleBrowserChange}
              value={browserValue}
              options={[
                { value: "all", label: "All" },
                { value: "Chrome", label: "Chrome" },
                { value: "Firefox", label: "Firefox" },
                { value: "Electron", label: "Electron" },
                { value: "Microsoft Edge", label: "Microsoft Edge" },
                { value: "Safari", label: "Safari" },
              ]}
              label="Filter by Browser"
            />

            <Select
              onChange={handleOSChange}
              value={OSValue}
              options={[
                { value: "all", label: "All" },
                { value: "macOS", label: "Mac OS" },
                { value: "Windows", label: "Windows" },
                { value: "Linux", label: "Linux" },
                { value: "Chrome OS", label: "Chrome OS" },
                { value: "Android", label: "Android" },
                { value: "iOS", label: "iOS" },
              ]}
              label="Filter by OS"
            />
          </DropdownStyled>
          <div>
            <Button onClick={clearAll}>Clear</Button>
          </div>
        </Box>
        {!isLoading && sessions && (
          <Card>
            <Box>
              <ClientStateSessionsTable sessions={sessions} />
              <TablePagination
                component="div"
                count={-1}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={pageIndex}
                rowsPerPage={pageSize}
                rowsPerPageOptions={[200, 500, 1000]}
              />
            </Box>
          </Card>
        )}

        {!isLoading && !sessions && <Box>There's nothing here</Box>}
      </Box>
    </PageContainer>
  )
}

export default IndexPage
