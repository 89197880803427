import { initContract } from "@ts-rest/core"
import zod from "zod"

import { HttpV2Paths, RequestMethod } from "../../httpAPI"

const c = initContract()

export const audioRecordingHook = c.router({
  receive: {
    method: RequestMethod.POST,
    path: HttpV2Paths.HookAVAudioRecordingS3,
    headers: zod.object({
      "x-gather-auth-key": zod.string(),
    }),
    body: zod.object({
      recordingId: zod.string(),
      url: zod.string().optional(),
    }),
    responses: { 200: c.noBody() },
  },
})
