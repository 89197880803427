import { initContract } from "@ts-rest/core"

import { HttpV2Paths, RequestMethod } from "../../../../httpAPI"

const c = initContract()

export const grapes = c.router({
  create: {
    method: RequestMethod.POST,
    path: HttpV2Paths.AdminSpaceUserGrapes,
    body: c.noBody(),
    responses: {
      200: c.noBody(),
    },
  },
})
