import { initContract } from "@ts-rest/core"
import { z } from "zod"

import { ObjectTemplateCategoryGroup } from "gather-common/dist/src/public/resources/objectTemplates"
import { HttpV2Paths, RequestMethod } from "../../public/httpAPI"

const c = initContract()

export const objectTemplateCategories = c.router({
  list: {
    method: RequestMethod.GET,
    path: HttpV2Paths.AdminObjectTemplateCategoryGroups,
    responses: {
      200: c.type<ObjectTemplateCategoryGroup[]>(),
    },
  },
  update: {
    method: RequestMethod.PUT,
    path: HttpV2Paths.AdminObjectTemplateCategoryGroup,
    body: z.object({
      name: z.string().optional(),
      categories: z.array(z.string()),
    }),
    responses: {
      200: c.noBody(),
    },
  },
})
