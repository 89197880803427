import { initContract } from "@ts-rest/core"

import { HttpV2Paths, RequestMethod } from "../../httpAPI"
import { SpawnPointResource } from "../../resources/spawnPoint"

const c = initContract()

export const spawns = c.router({
  list: {
    method: RequestMethod.GET,
    path: HttpV2Paths.SpaceSpawns,
    responses: { 200: c.type<SpawnPointResource[] | undefined>() },
  },
})
