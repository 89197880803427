import { initContract } from "@ts-rest/core"

import { HttpV2Paths, RequestMethod } from "../../../httpAPI"
import { ZodObjectVariant, ZodUpdatedObjectVariant } from "../objectTemplates.types"

const c = initContract()

export const variants = c.router({
  create: {
    method: RequestMethod.POST,
    path: HttpV2Paths.AdminObjectTemplateVariants,
    body: ZodObjectVariant,
    responses: {
      200: c.type<string>(),
    },
  },
  update: {
    method: RequestMethod.PATCH,
    path: HttpV2Paths.AdminObjectTemplateVariant,
    body: ZodUpdatedObjectVariant,
    responses: {
      200: c.noBody(),
    },
  },
  delete: {
    method: RequestMethod.DELETE,
    path: HttpV2Paths.AdminObjectTemplateVariant,
    body: c.noBody(),
    responses: {
      200: c.noBody(),
    },
  },
})
