import { switchEnv } from "gather-env-config/dist/src/public/env"

interface FirebaseClientConfig {
  apiKey: string
  appId: string
  authDomain: string
  databaseURL: string
  projectId: string
  storageBucket: string
  messagingSenderId: string
}

const firebaseProject = switchEnv({
  prod: "gather-town-v2",
  staging: "gather-town-v2",
  dev: "gather-town-v2-dev",
  local: "gather-town-v2-dev",
  test: "gather-town-v2-dev",
})

// v2 note: This project is intentional, it's cdn.gather.town (firebase storage bucket), which is not migrating to static.gather.town yet (s3 bucket)
const storageFirebaseProject = switchEnv({
  prod: "gather-town",
  staging: "gather-town",
  dev: "gather-town-dev",
  local: "gather-town-dev",
  test: "gather-town-dev",
})

export const config: FirebaseClientConfig = {
  apiKey: process.env.FRONTEND_FIREBASE_API_KEY ?? "",
  appId: process.env.FRONTEND_FIREBASE_APP_ID ?? "",
  // TODO [rebuild]: this URL is hard-coded A records to firebaseapp.com, which fails HTTPS TLS validation. Can we set this to the firebaseapp.com domain?
  authDomain: switchEnv({
    prod: "google-auth.gather.town",
    staging: "google-auth.gather.town",
    dev: `${firebaseProject}.firebaseapp.com`,
    local: `${firebaseProject}.firebaseapp.com`,
    test: `${firebaseProject}.firebaseapp.com`,
  }),
  databaseURL: `https://${firebaseProject}.firebaseio.com`,
  messagingSenderId: process.env.FRONTEND_FIREBASE_MESSAGING_SENDER_ID ?? "",
  projectId: firebaseProject,
  storageBucket: `${storageFirebaseProject}.appspot.com`,
}
