import AddIcon from "@mui/icons-material/Add"
import { Box, Button, TextField } from "@mui/material"
import { objectTemplateCategoryGroupMutation } from "features/objectTemplateCategories/mutations"
import React, { FC, KeyboardEvent, useState } from "react"
import { toast } from "react-hot-toast"
import { useMutation, useQueryClient } from "react-query"

import { ObjectTemplateCategoryGroup } from "gather-common/dist/src/public/resources/objectTemplates"
import * as ObjectTemplateCategoryGroupAPI from "../../../../api/objectTemplateCategories"

interface Props {
  group: ObjectTemplateCategoryGroup
}

const AddCategory: FC<Props> = ({ group, ...other }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const [name, setName] = useState("")
  const queryClient = useQueryClient()
  const mutation = useMutation(
    (categoryGroup: ObjectTemplateCategoryGroup) =>
      ObjectTemplateCategoryGroupAPI.updateAdminObjectCategories(categoryGroup),
    objectTemplateCategoryGroupMutation(queryClient),
  )

  const createCategory = (name: string) => {
    group.categories.push(name)

    // Update source categoryGroup
    mutation.mutate(group)
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value)
  }

  const handleAddInit = () => {
    setIsExpanded(true)
  }

  const handleAddCancel = () => {
    setIsExpanded(false)
    setName("")
  }

  const handleAddConfirm = () => {
    try {
      createCategory(name)
      setIsExpanded(false)
      setName("")
    } catch (err) {
      console.error(err)
      toast.error("Something went wrong!")
    }
  }

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleAddConfirm()
    }
  }

  return (
    <div {...other}>
      {isExpanded ? (
        <Box
          sx={{
            px: 2,
            py: 2,
          }}
        >
          <TextField
            fullWidth
            label="Name"
            name="cardName"
            onChange={handleChange}
            value={name}
            variant="outlined"
            autoFocus={true}
            onKeyDown={handleKeyPress}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mt: 1,
            }}
          >
            <Button color="primary" onClick={handleAddCancel} variant="text">
              Cancel
            </Button>
            <Button color="primary" onClick={handleAddConfirm} variant="contained">
              Add
            </Button>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "left",
          }}
        >
          <Button
            color="primary"
            onClick={handleAddInit}
            variant="text"
            fullWidth={true}
            startIcon={<AddIcon />}
            sx={{
              px: 2,
              py: 1,
            }}
          >
            Add Category
          </Button>
        </Box>
      )}
    </div>
  )
}

export default AddCategory
