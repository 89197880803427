import { Box, TextField } from "@mui/material"
import React, { ChangeEvent, FC } from "react"

export interface SortOption {
  label: string
  value: string
}

interface Props {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  value: string
  options: SortOption[]
  label?: string
}

const SortSelect: FC<Props> = ({ options, label, ...props }) => (
  <Box
    sx={{
      m: 1,
      width: 240,
    }}
  >
    <TextField
      label={label ?? "Sort By"}
      name="sort"
      select
      SelectProps={{ native: true }}
      variant="outlined"
      {...props}
    >
      {options.map(({ label, value }) => (
        <option key={value} value={value}>
          {label}
        </option>
      ))}
    </TextField>
  </Box>
)

export default SortSelect
