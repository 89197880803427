import { tsrAPI } from "gather-browser-common/dist/src/public/tsRestClient"
import { EditableWearablePartFields } from "./wearables"

/**
 * API request to update a wearable
 * @param wearablePartId - ID of wearable part to be patched
 * @param data - data to be patched
 * @returns void Promise
 */
export const updateWearablePart = async (
  wearablePartId: string,
  data: EditableWearablePartFields,
): Promise<undefined> => {
  try {
    await tsrAPI.admin.wearableParts.update({
      params: { wearablePartId },
      body: data,
    })
  } catch (e) {
    let message = "Failed to patch wearable part."

    if (e instanceof Error) {
      message = e.message
    }

    return Promise.reject(message)
  }
}
