import { GatherEventEmitter } from "gather-common-including-video/dist/src/public/GatherEventEmitter"

export enum AxiosClientEvent {
  SSOAuthenticationFailed = "SSOAuthenticationFailed",
}

type AxiosClientEventDataMap = {
  [AxiosClientEvent.SSOAuthenticationFailed]: []
}

export const axiosClientEventEmitter = new GatherEventEmitter<
  AxiosClientEvent,
  AxiosClientEventDataMap
>()
