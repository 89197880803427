import { Box, Divider, Paper, Typography } from "@mui/material"
import React, { FC } from "react"
import { Draggable, Droppable } from "react-beautiful-dnd"

import { ObjectTemplateCategoryGroup } from "gather-common/dist/src/public/resources/objectTemplates"
import AddCategory from "./AddCategory"
import Category from "./Category"

interface Props {
  group: ObjectTemplateCategoryGroup
}

const CategoryGroup: FC<Props> = ({ group }) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      flexGrow: 0,
      mr: 2,
      overflowX: "hidden",
      width: {
        xs: 300,
        sm: 360,
      },
    }}
  >
    <Paper>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          px: 2,
          py: 1,
        }}
      >
        <Typography color="inherit" variant="h6">
          {group.name}
        </Typography>
      </Box>

      <Divider />

      <Droppable droppableId={group.id} type="card">
        {(provided) => (
          <Box
            ref={provided.innerRef}
            sx={{
              overflowY: "auto",
              minHeight: "150px",
              px: 2,
              py: 1,
            }}
          >
            {group.categories &&
              group.categories.map((category, index) => (
                <Draggable index={index} draggableId={category} key={category}>
                  {(_provided, snapshot) => (
                    <Category
                      category={category}
                      dragging={snapshot.isDragging}
                      group={group}
                      index={index}
                      ref={_provided.innerRef}
                      {..._provided.draggableProps}
                      {..._provided.dragHandleProps}
                    />
                  )}
                </Draggable>
              ))}
            {provided.placeholder}
          </Box>
        )}
      </Droppable>

      <Divider />

      <AddCategory group={group} />
    </Paper>
  </Box>
)

export default CategoryGroup
