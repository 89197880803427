import { AppBar, experimentalStyled, Toolbar } from "@mui/material"
import Logo from "components/Logo"
import React from "react"
import { Link as RouterLink } from "react-router-dom"

const PublicNavbarRoot = experimentalStyled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === "light" && {
    backgroundColor: theme.palette.primary.main,
    boxShadow: "none",
    color: theme.palette.primary.contrastText,
  }),
  ...(theme.palette.mode === "dark" && {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: "none",
  }),
  zIndex: theme.zIndex.drawer + 100,
}))

const PublicNavbar = () => (
  <PublicNavbarRoot elevation={0}>
    <Toolbar sx={{ minHeight: 64 }}>
      <RouterLink to="/">
        <Logo
          sx={{
            display: {
              md: "inline",
              xs: "none",
            },
            height: 35,
          }}
        />
      </RouterLink>
    </Toolbar>
  </PublicNavbarRoot>
)

export default PublicNavbar
