import { initContract } from "@ts-rest/core"
import { z } from "zod"

import { BrowserUpdateInfo } from "../clientReleases"
import { HttpV2Paths, RequestMethod } from "../httpAPI"

const c = initContract()

const desktopPathParamsSchema = z.object({
  platform: z.union([z.literal("darwin"), z.literal("linux"), z.literal("win32")]),
  releaseVersion: z.string(),
  releaseChannel: z.string(),
})

const desktopQuerySchema = z.object({
  arch: z.string().nullish(),
  osVersion: z.string().nullish(),
  userId: z.string().nullish(),
  spaceId: z.string().nullish(),
  gates: z.array(z.string()).nullish(),
})

export const releases = c.router({
  browser: {
    get: {
      method: RequestMethod.POST,
      path: HttpV2Paths.BrowserClientReleases,
      body: z.object({
        pageLoadTimestamp: z.string().datetime({ offset: true }),
        buildTimestamp: z.string().datetime({ offset: true }),

        osName: z.string().nullish(),
        osVersion: z.string().nullish(),
        browserVersion: z.string().nullish(),

        userId: z.string().nullish(),
        spaceId: z.string().nullish(),
        gates: z.array(z.string()).nullish(),
        // region: can be checked with `process.env.REGION` (clients are "sticked" to their region)
      }),
      responses: { 200: c.type<BrowserUpdateInfo>() },
    },
  },

  desktop: {
    get: {
      method: RequestMethod.GET,
      path: HttpV2Paths.DesktopClientReleases,
      pathParams: desktopPathParamsSchema,
      query: desktopQuerySchema,
      metadata: { desktopPathParamsSchema, desktopQuerySchema },
      responses: { 200: c.type<BrowserUpdateInfo>() },
    },

    latest: {
      get: {
        method: RequestMethod.GET,
        path: HttpV2Paths.DesktopClientLatest,
        pathParams: desktopPathParamsSchema,
        query: desktopQuerySchema,
        metadata: { desktopPathParamsSchema, desktopQuerySchema },
        responses: { 200: c.type<{ version: string }>() },
      },
    },
  },
})
