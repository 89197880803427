import { initContract } from "@ts-rest/core"
import { z } from "zod"

import { RegisterUserResponse } from "gather-common/dist/src/public/resources/user"
import { HttpV2Paths, RequestMethod } from "../httpAPI"

const c = initContract()

export const users = c.router({
  get: {
    method: RequestMethod.GET,
    path: HttpV2Paths.User,
    query: z.object({
      // This is currently not used in the logic, but it's useful to check in logs which space the user is
      // trying to join.
      // Include this whenever we have context of spaceId
      spaceId: z.string().nullable().optional(),
    }),
    responses: { 200: c.type<RegisterUserResponse>() },
  },
  delete: {
    method: RequestMethod.DELETE,
    path: HttpV2Paths.User,
    body: c.noBody(),
    responses: { 200: c.noBody() },
  },
})
