import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import Fade from "@mui/material/Fade"
import Slide from "@mui/material/Slide"
import React, { FC } from "react"

interface Props {
  isOpen: boolean
  title: string
  children: string | React.ReactNode
  onClose: (confirm: boolean) => void
  transition?: string
  confirmButtonColor?: "primary" | "error"
}

const getTransitionComponent = (choice: string) => {
  switch (choice) {
    case "slide":
      return Slide
    default:
      return Fade
  }
}

const AlertDialog: FC<Props> = ({
  isOpen,
  onClose,
  title,
  children,
  confirmButtonColor = "primary",
  transition = "fade",
}) => (
  <Dialog
    open={isOpen}
    onClose={() => onClose(false)}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    TransitionComponent={getTransitionComponent(transition)}
  >
    <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">{children}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => onClose(false)}>Cancel</Button>
      <Button onClick={() => onClose(true)} autoFocus color={confirmButtonColor}>
        Confirm
      </Button>
    </DialogActions>
  </Dialog>
)

export default AlertDialog
