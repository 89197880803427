import QueryKeys from "features/queryKeys"
import { useMutation, useQueryClient } from "react-query"

import { ObjectTemplate } from "gather-common/dist/src/public/resources/objectTemplates"
import * as ObjectTemplatesAPI from "../../../api/objectTemplates"
import { ObjTempMutationCallbacks } from "./types"

/**
 * Mutation that deletes an object template document
 * @param callbacks Object containing onError and onSuccess callback methods
 * @returns ReactQuery useMutation hook
 */
const useDeleteObjectTemplate = (callbacks?: ObjTempMutationCallbacks) => {
  const queryClient = useQueryClient()

  return useMutation((tempId: string) => ObjectTemplatesAPI.deleteObjectTemplate(tempId), {
    onMutate: async (tempId: string) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      queryClient.cancelQueries([QueryKeys.ObjectTemplates, tempId])

      // Snapshot the previous values of ObjectTemplate
      const previousTemplates = queryClient.getQueryData<ObjectTemplate[]>([
        QueryKeys.ObjectTemplates,
      ])

      const previousTemplate = queryClient.getQueryData<ObjectTemplate>([
        QueryKeys.ObjectTemplates,
        tempId,
      ])

      // Remove the ObjectTemplate from the current list
      if (previousTemplates) {
        const updatedTemplateArray = previousTemplates.filter((current) => current.id !== tempId)

        queryClient.setQueryData<ObjectTemplate[]>(
          [QueryKeys.ObjectTemplates],
          updatedTemplateArray,
        )
      }

      if (previousTemplate) {
        queryClient.removeQueries([QueryKeys.ObjectTemplates, tempId], { exact: true })
      }

      return { previousTemplates, previousTemplate }
    },
    onError: (_err, tempId, context) => {
      if (context?.previousTemplates) {
        const { previousTemplates } = context

        if (previousTemplates) {
          queryClient.setQueryData<ObjectTemplate[]>([QueryKeys.ObjectTemplates], previousTemplates)
        }
      }

      if (context?.previousTemplate) {
        const { previousTemplate } = context

        if (previousTemplate) {
          queryClient.setQueryData<ObjectTemplate>(
            [QueryKeys.ObjectTemplates, tempId],
            previousTemplate,
          )
        }
      }

      if (callbacks?.onError) {
        callbacks?.onError()
      }
    },

    onSuccess: () => {
      if (callbacks?.onSuccess) {
        callbacks?.onSuccess()
      }
    },

    onSettled: (_data, _err, tempId) => {
      queryClient.invalidateQueries([QueryKeys.ObjectTemplates])
      queryClient.invalidateQueries([QueryKeys.ObjectTemplates, tempId])
    },
  })
}

export default useDeleteObjectTemplate
