import { initContract } from "@ts-rest/core"
import { z } from "zod"

import { HttpV2Paths, RequestMethod } from "../../httpAPI"

type Floor = {
  id: string
}

const c = initContract()

export const floors = c.router({
  create: {
    method: RequestMethod.POST,
    path: HttpV2Paths.ExperimentalSpaceFloors,
    body: z.object({}),
    responses: { 200: c.type<Floor>() },
  },
})
