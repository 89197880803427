import { initContract } from "@ts-rest/core"
import { z } from "zod"

import {
  BrowserVideoClientStateSession,
  BrowserVideoClientStateSessionIndex,
} from "gather-prisma-types/dist/src/public/client"
import { HttpV2Paths, RequestMethod } from "../../../httpAPI"

const c = initContract()

export const avClientStateSessions = c.router({
  list: {
    method: RequestMethod.GET,
    path: HttpV2Paths.AdminAvClientStateSessions,
    query: z.object({
      page: z.preprocess(Number, z.number()).optional(),
      pageSize: z.preprocess(Number, z.number()).optional(),
      userAgentOs: z.string().optional(),
      userAgentBrowser: z.string().optional(),
      avStrategy: z.string().optional(),
      spaceIdOrUserId: z.string().optional(),
      email: z.string().optional(),
      id: z.string().optional(),
    }),
    responses: { 200: c.type<BrowserVideoClientStateSessionIndex[]>() },
  },
  get: {
    method: RequestMethod.GET,
    path: HttpV2Paths.AdminAvClientStateSession,
    responses: { 200: c.type<BrowserVideoClientStateSession>() },
  },
})
