/* External modules */
/* Material Icons */
import PlusIcon from "@mui/icons-material/Add"
/* MUI Components */
import { SelectChangeEvent } from "@mui/material"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Card from "@mui/material/Card"
import TablePagination from "@mui/material/TablePagination"
/* Local modules */
import AlertDialog from "components/dashboard/AlertDialog"
import MultipleSelectChip from "components/inputs/MultipleSelectChip"
import SearchIdInput from "components/inputs/SearchIdInput"
import SortSelect from "components/inputs/SortSelect"
import PageContainer from "components/layout/dashboard/PageContainer"
import { InteractionTypes } from "features/maps/types"
import { useObjectTemplates } from "features/objectTemplates/hooks"
import useDeleteObjectTemplate from "features/objectTemplates/mutations/useDeleteObjectTemplate"
import useRoles from "features/roles/useRoles"
import { applyPagination, applySort } from "features/tables/utils"
import { toLower } from "ramda"
import React, {
  ChangeEvent,
  FC,
  KeyboardEvent,
  MouseEvent,
  useCallback,
  useMemo,
  useState,
} from "react"
import { toast } from "react-hot-toast"
import { useNavigate } from "react-router-dom"

import { AdminPermission as Can } from "gather-admin-common/dist/src/public/roles/types"
import { ObjectTemplate } from "gather-common/dist/src/public/resources/objectTemplates"
import ObjectTemplatesTable from "./partials/ObjectTemplatesTable"

const PAGE_TITLE = "Manage Object Templates"
const ROW_LIMITS = [25, 50, 100, 250]
const INTERACTION_TYPE_FILTERS = Object.keys(InteractionTypes)
  .filter((x) => !(parseInt(x) >= 0))
  .map((intType, index) => ({
    label: intType,
    value: index.toString(),
  }))

const TYPE_FILTER_OPTIONS = [
  {
    label: "All",
    value: "all",
  },
  ...INTERACTION_TYPE_FILTERS,
]
const DELETE_CONFIRMATION =
  "Are you sure you want to delete this object template? This action is not reversible."

const ObjectTemplatesIndexPage: FC = () => {
  const { data: objTemplates, isLoading } = useObjectTemplates()
  const allTemplates = useMemo(() => objTemplates ?? [], [objTemplates])
  const { permissions } = useRoles()
  const [searchQuery, setSearchQuery] = useState<string>("")
  const [searchQueryInputValue, setSearchQueryInputValue] = useState<string>("")
  const [sort, setSort] = useState<string>("name|asc") // Name (asc) will be the default option
  const [selectedInteractionType, setSelectedInteractionType] = useState<string>("all")
  const [selectedTags, setSelectedTags] = useState<string[]>([]) //
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [limit, setLimit] = useState(25)
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false)
  const [templateToDelete, setTemplateToDelete] = useState<ObjectTemplate>()
  const navigate = useNavigate()

  const tags = useMemo(() => {
    const flattened = allTemplates.map((template) => template.tags).flat()
    const tagsList = flattened.map((tag) => tag.toLowerCase().trim())
    return [...new Set(tagsList)].sort()
  }, [allTemplates])

  const applyFilters = useCallback(
    (templates: ObjectTemplate[], query: string) =>
      templates.filter((temp: ObjectTemplate) => {
        if (
          selectedInteractionType !== "all" &&
          !(temp.type === parseInt(selectedInteractionType))
        ) {
          return false
        }

        if (
          selectedTags.length > 0 &&
          temp.tags &&
          !selectedTags.every((t) => temp.tags.map(toLower)?.includes(toLower(t)))
        ) {
          return false
        }

        if (query) {
          const properties: (keyof ObjectTemplate)[] = ["name", "desc"]
          const containsQuery = properties.some((property) =>
            temp[property]?.toString().toLowerCase().includes(query.toLowerCase()),
          )

          if (!containsQuery) return false
        }

        return true
      }),
    [selectedTags, selectedInteractionType],
  )

  const filteredTemplates: ObjectTemplate[] = useMemo(
    () => applyFilters(allTemplates, searchQuery),
    [allTemplates, searchQuery, applyFilters],
  )

  const objTemplatesToDisplay: ObjectTemplate[] = useMemo(() => {
    const sortedTemplates = applySort<ObjectTemplate>(filteredTemplates, sort)
    return applyPagination<ObjectTemplate>(sortedTemplates, currentPage, limit)
  }, [currentPage, limit, filteredTemplates, sort])

  /**
   * Updates the current value of the search query text field every time
   * a key is pressed and the field is focused.
   */
  const handleSearchQueryChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSearchQueryInputValue(event.target.value)
    },
    [setSearchQueryInputValue],
  )

  /**
   * Triggers an update to filtered results after the enter key is pressed
   */
  const handleSearchQueryKeyPress = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
        setSearchQuery(searchQueryInputValue)
      }
    },
    [setSearchQuery, searchQueryInputValue],
  )

  const handleInteractionFilterChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSelectedInteractionType(event.target.value)
    },
    [setSelectedInteractionType],
  )

  const toggleSortOrder = useCallback(() => {
    const currentSortAsc = sort === "name|asc"

    if (currentSortAsc) {
      setSort("name|desc")
    } else {
      setSort("name|asc")
    }
  }, [setSort, sort])

  const handlePageChange = useCallback(
    (_event: MouseEvent<HTMLButtonElement> | null, page: number) => {
      setCurrentPage(page)
    },
    [setCurrentPage],
  )

  const handleLimitChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setLimit(parseInt(event.target.value, 10))
      setCurrentPage(0)
    },
    [setLimit, setCurrentPage],
  )

  const handleSelectedTagsChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event
    const result =
      typeof value === "string"
        ? value.split(",") // autofill returns a string but we know the string array only has AdminRoleTypes in it
        : value
    setSelectedTags(result)
  }

  const onSuccessfulDeletion = () => {
    toast.success("Object Template was successfully deleted.")
  }

  const onDeletionError = () => {
    toast.error(`Error: Object template "${templateToDelete}" could not be deleted.`)
  }

  const confirmDeletion = useCallback(
    (template: ObjectTemplate) => {
      setShowDeleteConfirmation(true)
      setTemplateToDelete(template)
    },
    [setShowDeleteConfirmation],
  )

  const { mutate: deleteObjTemplate } = useDeleteObjectTemplate({
    onSuccess: onSuccessfulDeletion,
    onError: onDeletionError,
  })

  const handleDelete = useCallback(
    (confirm: boolean) => {
      setShowDeleteConfirmation(false)

      if (confirm && templateToDelete?.id) {
        deleteObjTemplate(templateToDelete.id)
      }
    },
    [setShowDeleteConfirmation, deleteObjTemplate, templateToDelete],
  )

  const handleNewTemplate = useCallback(() => {
    navigate("/dashboard/mapmaker/objects/new")
  }, [navigate])

  return (
    <PageContainer
      pageTitle={PAGE_TITLE}
      buttons={
        <Button
          color="primary"
          startIcon={<PlusIcon fontSize="small" />}
          variant="contained"
          onClick={handleNewTemplate}
        >
          Add Template
        </Button>
      }
    >
      <Box sx={{ minWidth: 1100 }}>
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flexWrap: "wrap",
            gap: 1,
            mb: 2,
            mt: 2,
          }}
        >
          <SearchIdInput
            onKeyPress={handleSearchQueryKeyPress}
            onChange={handleSearchQueryChange}
            id={searchQueryInputValue}
            placeholder="Search by name or description"
            label="Filter Templates"
          />

          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            <MultipleSelectChip
              options={tags}
              value={selectedTags}
              onChange={handleSelectedTagsChange}
              label="Filter by Tags"
            />
          </Box>

          <Box
            sx={{
              maxWidth: "100%",
              width: 150,
            }}
          >
            <SortSelect
              onChange={handleInteractionFilterChange}
              value={selectedInteractionType}
              options={TYPE_FILTER_OPTIONS}
              label="Filter by interaction"
            />
          </Box>
        </Box>

        <Card>
          {isLoading && <Box sx={{ p: 2 }}>Loading...</Box>}

          {!isLoading && allTemplates.length > 0 && (
            <>
              <ObjectTemplatesTable
                objTemplates={objTemplatesToDisplay}
                canEdit={permissions.includes(Can.ManageObjectTemplates)}
                onToggleSort={toggleSortOrder}
                currentSortAsc={sort === "name|asc"}
                onDelete={confirmDeletion}
              />
              <TablePagination
                component="div"
                count={filteredTemplates.length}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={currentPage}
                rowsPerPage={limit}
                rowsPerPageOptions={ROW_LIMITS}
              />
            </>
          )}
        </Card>
      </Box>

      <AlertDialog
        isOpen={showDeleteConfirmation}
        onClose={handleDelete}
        title={`Confirm Deletion of ${templateToDelete?.name}`}
      >
        {DELETE_CONFIRMATION}
      </AlertDialog>
    </PageContainer>
  )
}

export default ObjectTemplatesIndexPage
