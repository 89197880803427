import { initContract } from "@ts-rest/core"
import { z } from "zod"

import { SerializedAndNormalizedGSState } from "gather-game-logic/dist/src/public/models/gameLogicTypes"
import { HttpV2Paths, RequestMethod } from "../../httpAPI"

const c = initContract()

// TODO: reuse a type from somewhere else
type Meeting = {
  id: string
  title: string
}

export const meetings = c.router({
  create: {
    method: RequestMethod.POST,
    path: HttpV2Paths.ExperimentalSpaceMeetings,
    body: z.object({
      areaId: z.string(),
      inviteeIds: z.array(z.string()),
      title: z.string(),
      description: z.string(),
      canInviteesInviteOthers: z.boolean(),
      canInviteesModifyDetails: z.boolean(),
      canInviteesSeeOtherInvitees: z.boolean(),
      meetingType: z.string(),
      visibility: z.string(),
    }),
    responses: { 200: c.type<Meeting>() },
  },
  get: {
    method: RequestMethod.GET,
    path: HttpV2Paths.ExperimentalSpaceMeetings,
    query: z.object({
      recurringMeetingId: z.string().optional(),
    }),
    responses: { 200: c.type<SerializedAndNormalizedGSState<"MeetingV2" | "MapAreaV2">>() },
  },
})
