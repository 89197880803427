import { SelectProps } from "@mui/material"
import Box from "@mui/material/Box"
import Chip from "@mui/material/Chip"
import FormControl from "@mui/material/FormControl"
import FormHelperText from "@mui/material/FormHelperText"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import OutlinedInput from "@mui/material/OutlinedInput"
import Select from "@mui/material/Select"
import React from "react"

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

interface Props<T extends string | number> extends SelectProps<T[]> {
  options: string[]
  value: T[]
  helperText?: string | string[]
  defaultValue?: T[]
  label?: string
}

export function MultipleSelectChip<T extends string | number>({
  options,
  label,
  value,
  error,
  helperText,
  ...props
}: Props<T>): React.ReactElement {
  return (
    <FormControl sx={{ minWidth: 300, width: "100%" }} error={error}>
      {label && <InputLabel id="chip-label">{label}</InputLabel>}
      <Select
        {...props}
        labelId={label ? "chip-label" : ""}
        multiple
        value={value}
        input={<OutlinedInput label={label} />}
        renderValue={(selected: T[]) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected.map((val: T) => (
              <Chip key={val} label={val} />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {options.map((opt) => (
          <MenuItem key={opt} value={opt}>
            {opt}
          </MenuItem>
        ))}
      </Select>
      {helperText && <FormHelperText sx={{ mt: -1 }}>{helperText}</FormHelperText>}
    </FormControl>
  )
}

export default MultipleSelectChip
