import QueryKeys from "features/queryKeys"
import { useQuery } from "react-query"

import * as ObjectTemplateCategoryGroupAPI from "../../api/objectTemplateCategories"

const useObjectTemplateCategories = () =>
  useQuery(
    QueryKeys.ObjectTemplateCategories,
    ObjectTemplateCategoryGroupAPI.listAdminObjectCategories,
    {
      initialData: [],
    },
  )

export default useObjectTemplateCategories
