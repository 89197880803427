import { initContract } from "@ts-rest/core"
import { z } from "zod"

import { GuestPassStatus, SpaceUserResource } from "gather-common/dist/src/public/resources/space"
import { HttpV2Paths, RequestMethod } from "../../httpAPI"
import { zodUpdateGuestBodySchema } from "../../resources/guests"

const c = initContract()

const MAX_SEARCH_LIMIT = 500

export const guests = c.router({
  list: {
    method: RequestMethod.GET,
    path: HttpV2Paths.SpaceGuests,
    query: z.object({
      guestPassStatus: z.nativeEnum(GuestPassStatus).optional(),
      searchLimit: z.coerce.number().positive().max(MAX_SEARCH_LIMIT).optional(),
    }),
    metadata: {
      maxSearchLimit: MAX_SEARCH_LIMIT,
    },
    responses: { 200: c.type<SpaceUserResource[]>() },
  },
  get: {
    method: RequestMethod.GET,
    path: HttpV2Paths.SpaceGuest,
    responses: { 200: c.type<SpaceUserResource>() },
  },
  update: {
    method: RequestMethod.PATCH,
    path: HttpV2Paths.SpaceGuest,
    body: zodUpdateGuestBodySchema,
    responses: { 200: c.noBody() },
  },
})
