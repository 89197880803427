import QueryKeys from "features/queryKeys"
import { useQuery, UseQueryResult } from "react-query"

import * as ObjectTemplatesAPI from "../../api/objectTemplates"

/**
 * Fetches all object templates
 * @returns ReactQuery object where data is type of ObjectTemplate[] | undefined
 */
export const useObjectTemplates = (): UseQueryResult<
  Awaited<ReturnType<typeof ObjectTemplatesAPI.listObjectTemplates>>
> =>
  useQuery([QueryKeys.ObjectTemplates], ObjectTemplatesAPI.listObjectTemplates, {
    initialData: undefined,
    retry: false,
  })

/**
 * Fetches a single object template and its variants
 * @returns ReactQuery object where data is type of ObjectTemplate | undefined
 */
export const useObjectTemplate = (
  id: string,
): UseQueryResult<Awaited<ReturnType<typeof ObjectTemplatesAPI.getObjectTemplate>>> =>
  useQuery([QueryKeys.ObjectTemplate, id], () => ObjectTemplatesAPI.getObjectTemplate(id), {
    initialData: undefined,
    retry: false,
  })
