import { List, ListItem } from "@mui/material"
import { FC, useMemo } from "react"
import { useParticipants } from "state/avClientStateViewerSlice"

import ParticipantMenu from "./ParticipantMenu"
import { activeParticipant } from "./participants.logic"
import { Label, Pane, PaneContent, PaneHeader, ScrollPane } from "./ui"

export type ParticipantsProps = { selfParticipant?: string }

const Participants: FC<ParticipantsProps> = ({ selfParticipant = "" }) => {
  const { participants, currentParticipants } = useParticipants(selfParticipant)
  const sortedPaticipants = useMemo(() => {
    const activeSet = new Set(currentParticipants)

    return [
      ...participants.filter((participant) => activeSet.has(participant)),
      ...participants.filter((participant) => !activeSet.has(participant)),
    ]
  }, [participants, currentParticipants])

  return (
    <Pane sx={{ height: "100%", overflow: "hidden" }}>
      <PaneHeader>
        <Label>Participants</Label>
      </PaneHeader>
      <PaneContent sx={{ maxHeight: "100%" }}>
        <ScrollPane>
          <List>
            {sortedPaticipants.map((participant) => (
              <ListItem
                className={`participant ${activeParticipant(currentParticipants, participant)}`}
                key={participant}
                secondaryAction={<ParticipantMenu participant={participant} />}
              >
                {participant}
              </ListItem>
            ))}
          </List>
        </ScrollPane>
      </PaneContent>
    </Pane>
  )
}

export default Participants
