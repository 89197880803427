import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp"
import Box from "@mui/material/Box"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import React, { FC } from "react"

import { CoreRole, SpaceResource } from "gather-common/dist/src/public/resources/space"
import UserSpaceRow from "./UserSpaceRow"

interface Props {
  spaces: SpaceResource[]
  roles: {
    [x: string]: CoreRole[]
  }
  onToggleSort: () => void
  currentSortAsc: boolean
  userId?: string
}

const UserSpacesTable: FC<Props> = ({ spaces, roles, onToggleSort, currentSortAsc, userId }) => (
  <Box sx={{ minWidth: "100%" }}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Space ID</TableCell>
          <TableCell
            onClick={onToggleSort}
            sx={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            Name
            <Box
              sx={{
                mb: -1,
                ml: -1,
              }}
            >
              {currentSortAsc ? (
                <ArrowDropDownIcon color="primary" sx={{ ml: 2 }} fontSize="small" />
              ) : (
                <ArrowDropUpIcon color="primary" sx={{ ml: 2 }} fontSize="small" />
              )}
            </Box>
          </TableCell>
          <TableCell>User Roles</TableCell>
          <TableCell>Invoke Grapes</TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {spaces.map((data: SpaceResource) => (
          <UserSpaceRow key={data.id} space={data} roles={roles[data.id]} userId={userId} />
        ))}
      </TableBody>
    </Table>
  </Box>
)

export default UserSpacesTable
