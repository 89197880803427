import { initContract } from "@ts-rest/core"
import { z } from "zod"

import {
  CoreRole,
  Space,
  UserHomeSpaceResource,
} from "gather-common/dist/src/public/resources/space"
import { USE_CASES_FILTER_OPTIONS } from "gather-common/dist/src/public/useCases"
import { HttpV2Paths, RequestMethod } from "../../httpAPI"

const c = initContract()

type UserVisitedSpaceResource = Record<string, UserHomeSpaceResource>

export const spaces = c.router({
  list: {
    method: RequestMethod.GET,
    path: HttpV2Paths.UserSpaces,
    query: z.object({
      roles: z.array(z.nativeEnum(CoreRole)).optional(),
      useCase: z.nativeEnum(USE_CASES_FILTER_OPTIONS).optional(),
    }),
    responses: { 200: c.type<Space[]>() },
  },
  listRecent: {
    method: RequestMethod.GET,
    path: HttpV2Paths.UserRecentSpaces,
    responses: { 200: c.type<UserVisitedSpaceResource>() },
  },
  listOwned: {
    method: RequestMethod.GET,
    path: HttpV2Paths.UserOwnedSpaces,
    responses: { 200: c.type<UserVisitedSpaceResource>() },
  },
})
