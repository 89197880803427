import { switchEnv } from "gather-env-config/dist/src/public/env"

// NODE_ENV is special in that it must be "development" or "production"
// for webpack (set in build.js and start.js), which changes compile
// outputs (including source maps)
// Instead, let's use ENVIRONMENT as we do for all other apps
// const env = process.env.NODE_ENV

const UNSUPPORTED_ENV = () => {
  throw new Error("Unsupported environment")
}

// TODO [rebuild] this is duplicated in a couple places
const firebaseProject = switchEnv({
  prod: "gather-town-v2",
  staging: "gather-town-v2",
  dev: "gather-town-v2-dev",
  local: "gather-town-v2-dev",
  test: UNSUPPORTED_ENV,
})
// TODO [rebuild]: this URL is hard-coded A records to firebaseapp.com, which fails HTTPS TLS validation. Can we set this to the firebaseapp.com domain?
const firebaseAuthDomain = switchEnv({
  prod: "google-auth.gather.town",
  staging: "google-auth.gather.town",
  dev: `${firebaseProject}.firebaseapp.com`,
  local: `${firebaseProject}.firebaseapp.com`,
  test: UNSUPPORTED_ENV,
})
const firebaseStorageProject = switchEnv({
  prod: "gather-town",
  staging: "gather-town",
  dev: "gather-town-dev",
  local: "gather-town-dev",
  test: UNSUPPORTED_ENV,
})
export default {
  // security note: Only put stuff here that you're okay with being included in the bundle sent to clients. FRONTEND_ secrets are okay to send.
  apiKey: process.env.FRONTEND_FIREBASE_API_KEY,
  authDomain: firebaseAuthDomain,
  databaseURL: `https://${firebaseProject}.firebaseio.com`,
  projectId: firebaseProject,
  // v2 note: This domain is intentional, it's cdn.gather.town (firebase storage bucket), which is not migrating to static.gather.town yet (s3 bucket)
  storageBucket: `${firebaseStorageProject}.appspot.com`,
  messagingSenderId: process.env.FRONTEND_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.FRONTEND_FIREBASE_APP_ID,
}
