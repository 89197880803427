import { initContract } from "@ts-rest/core"
import { z } from "zod"

import { NewRelicCustomEvent } from "gather-common/dist/src/public/metrics/newrelic/customEvents"
import { HttpV2Paths, RequestMethod } from "../../httpAPI"

const c = initContract()

export const newRelic = c.router({
  customEvents: {
    create: {
      method: RequestMethod.POST,
      path: HttpV2Paths.NewRelicCustomEvents,
      body: z
        .array(
          z.object({
            eventType: z.nativeEnum(NewRelicCustomEvent),
            attributes: z.record(z.string(), z.union([z.string(), z.number(), z.boolean()])),
          }),
        )
        // In standard operation, event batches sent from official clients should never reach anywhere close to 300 events.
        // Huge event batches are likely either (1) caused by extreme edge cases or (2) sent from malicious actors.
        .max(300),
      responses: { 200: z.undefined() },
    },
  },
})
