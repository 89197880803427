import loadable from "@loadable/component"
import AuthGuard from "components/authentication/AuthGuard"
import PermittedRouteGuard from "components/authentication/PermittedRouteGuard"
import DashboardLayout from "components/layout/DashboardLayout"
import LoadingScreen from "components/LoadingScreen"
import AdminUsersIndexPage from "pages/dashboard/adminUsers/IndexPage"
import BulkUsersIndexPage from "pages/dashboard/bulkUsers/IndexPage"
import ClientStateIndexPage from "pages/dashboard/clientState/IndexPage"
import ClientStateViewerPage from "pages/dashboard/clientState/ViewerPage"
import MapInspectorIndexPage from "pages/dashboard/mapInspector/IndexPage"
import MapInspectorDetailsPage from "pages/dashboard/mapInspector/MapsTab/DetailsPage"
import ObjectTemplateCategoriesIndexPage from "pages/dashboard/objectTemplateCategories/IndexPage"
import ObjectTemplateDetailsPage from "pages/dashboard/objectTemplates/DetailsPage"
import ObjectTemplateEditFormPage from "pages/dashboard/objectTemplates/EditFormPage"
import ObjectTemplatesIndexPage from "pages/dashboard/objectTemplates/IndexPage"
import ObjectTemplateNewFormPage from "pages/dashboard/objectTemplates/NewFormPage"
import SpacesIndexPage from "pages/dashboard/spaces/IndexPage"
import UsersIndexPage from "pages/dashboard/users/IndexPage"
import WearablesDetailsPage from "pages/dashboard/wearables/DetailsPage"
import WearablesIndexPage from "pages/dashboard/wearables/IndexPage"
import WearablesNewFormPage from "pages/dashboard/wearables/NewFormPage"
import React from "react"

import { AdminPermission as Can } from "gather-admin-common/dist/src/public/roles/types"

const LOADABLE_OPTIONS = {
  fallback: <LoadingScreen />,
}

const Placeholder = loadable(() => import("pages/dashboard/Placeholder"), LOADABLE_OPTIONS)

const SPACE_ROUTES = [
  {
    path: "spaces",
    children: [
      {
        path: "",
        element: (
          <PermittedRouteGuard required={[Can.ViewSpaces, Can.ManageSpaces]}>
            <SpacesIndexPage />
          </PermittedRouteGuard>
        ),
      },
    ],
  },
]

const MAPMAKER_ROUTES = [
  {
    path: "mapmaker",
    children: [
      {
        path: "objects",
        children: [
          {
            path: "",
            element: (
              <PermittedRouteGuard required={[Can.ViewObjectTemplates]}>
                <ObjectTemplatesIndexPage />
              </PermittedRouteGuard>
            ),
          },
          {
            path: ":objectId",
            children: [
              {
                path: "",
                element: (
                  <PermittedRouteGuard required={[Can.ViewObjectTemplates]}>
                    <ObjectTemplateDetailsPage />
                  </PermittedRouteGuard>
                ),
              },
              {
                path: "edit",
                element: (
                  <PermittedRouteGuard required={[Can.ManageObjectTemplates]}>
                    <ObjectTemplateEditFormPage />
                  </PermittedRouteGuard>
                ),
              },
            ],
          },
          {
            path: "new",
            element: (
              <PermittedRouteGuard required={[Can.ManageObjectTemplates]}>
                <ObjectTemplateNewFormPage />
              </PermittedRouteGuard>
            ),
          },
        ],
      },
      {
        path: "object-categories",
        children: [
          {
            path: "",
            element: (
              <PermittedRouteGuard required={[Can.ManageObjectTemplateCategories]}>
                <ObjectTemplateCategoriesIndexPage />
              </PermittedRouteGuard>
            ),
          },
        ],
      },
      {
        path: "inspector",
        children: [
          {
            path: "",
            element: (
              <PermittedRouteGuard required={[Can.ViewMapInspector]}>
                <MapInspectorIndexPage />
              </PermittedRouteGuard>
            ),
          },
          {
            path: "/dashboard/mapmaker/inspector/:spaceId/maps/:mapId",
            element: (
              <PermittedRouteGuard required={[Can.ViewMapInspector]}>
                <MapInspectorDetailsPage />
              </PermittedRouteGuard>
            ),
          },
        ],
      },
    ],
  },
]

const WEARABLE_ROUTES = [
  {
    path: "wearables",
    children: [
      {
        path: "",
        element: (
          <PermittedRouteGuard required={[Can.ManageWearables]}>
            <WearablesIndexPage />
          </PermittedRouteGuard>
        ),
      },
      {
        path: ":wearableName",
        element: (
          <PermittedRouteGuard required={[Can.ManageWearables]}>
            <WearablesDetailsPage />
          </PermittedRouteGuard>
        ),
      },
      {
        path: "new",
        element: (
          <PermittedRouteGuard required={[Can.ManageWearables]}>
            <WearablesNewFormPage />
          </PermittedRouteGuard>
        ),
      },
    ],
  },
]

const USERS_ROUTES = [
  {
    path: "users",
    children: [
      {
        path: "",
        element: (
          <PermittedRouteGuard required={[Can.ManageUsers]}>
            <UsersIndexPage />
          </PermittedRouteGuard>
        ),
      },
    ],
  },
]

const BULK_USERS_ROUTES = [
  {
    path: "bulk-users",
    children: [
      {
        path: "",
        element: (
          <PermittedRouteGuard required={[Can.ManageUsers]}>
            <BulkUsersIndexPage />
          </PermittedRouteGuard>
        ),
      },
    ],
  },
]

const ADMIN_USERS_ROUTES = [
  {
    path: "admin-users",
    children: [
      {
        path: "",
        element: (
          <PermittedRouteGuard required={[Can.ManageAdminUsers]}>
            <AdminUsersIndexPage />
          </PermittedRouteGuard>
        ),
      },
    ],
  },
]

// const ROLE_ROUTES = [
//   {
//     path: "roles",
//     children: [
//       {
//         path: "",
//         element: (
//           <PermittedRouteGuard required={[Can.ManageRoles]}>
//             <Placeholder />
//           </PermittedRouteGuard>
//         ),
//       },
//       {
//         path: "new",
//         element: (
//           <PermittedRouteGuard required={[Can.ManageRoles]}>
//             <Placeholder />
//           </PermittedRouteGuard>
//         ),
//       },
//       {
//         path: ":roleId",
//         element: (
//           <PermittedRouteGuard required={[Can.ManageRoles]}>
//             <Placeholder />
//           </PermittedRouteGuard>
//         ),
//       },
//     ],
//   },
// ];

const AV_ROUTES = [
  {
    path: "client-state",
    children: [
      {
        path: "",
        element: (
          <PermittedRouteGuard required={[Can.ViewClientStateSessions]}>
            <ClientStateIndexPage />
          </PermittedRouteGuard>
        ),
      },
    ],
  },
]

const routes = [
  {
    path: "dashboard",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Placeholder />,
      },
      ...MAPMAKER_ROUTES,
      ...ADMIN_USERS_ROUTES,
      ...SPACE_ROUTES,
      ...USERS_ROUTES,
      ...BULK_USERS_ROUTES,
      ...WEARABLE_ROUTES,
      ...AV_ROUTES,
      // ...ROLE_ROUTES,
    ],
  },
  {
    path: "dashboard/client-state/:sessionId",
    element: (
      <AuthGuard>
        <ClientStateViewerPage />
      </AuthGuard>
    ),
  },
]

export default routes
