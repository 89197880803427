import { initContract } from "@ts-rest/core"
import { isNil } from "ramda"
import { z } from "zod"

import { UploadedFileInfo, UploadedFileType } from "gather-common/dist/src/public/resources/files"
import { isNotNil } from "gather-common-including-video/dist/src/public/fpHelpers"
import { HttpV2Paths, RequestMethod } from "../../httpAPI"
import { FILE_SIZE_LIMIT } from "../../imageUpload"

const c = initContract()

export const zodImageBufferUnderSizeLimit = z
  .any()
  .refine((v) => isNotNil(v), "Invalid file")
  .refine(
    (v) => isNil(v) || Buffer.from(v).byteLength <= FILE_SIZE_LIMIT,
    "File exceeds maximum size limit of 5mb",
  )

export const files = c.router({
  list: {
    method: RequestMethod.GET,
    path: HttpV2Paths.UserFiles,
    query: z.object({
      type: z.nativeEnum(UploadedFileType),
      spaceId: z.string().optional(),
    }),
    responses: { 200: c.type<UploadedFileInfo[]>() },
  },
  create: {
    method: RequestMethod.POST,
    path: HttpV2Paths.UserFiles,
    body: z.object({
      bytes: zodImageBufferUnderSizeLimit,
      type: z.nativeEnum(UploadedFileType),
      spaceId: z.string().optional(),
    }),
    responses: { 200: c.type<UploadedFileInfo>() },
  },

  get: {
    method: RequestMethod.GET,
    path: HttpV2Paths.UserFile,
    responses: { 200: c.type<UploadedFileInfo>() },
  },
  delete: {
    method: RequestMethod.DELETE,
    path: HttpV2Paths.UserFile,
    body: c.noBody(),
    responses: { 200: c.noBody() },
  },
})
