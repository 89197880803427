import { initContract } from "@ts-rest/core"
import { z } from "zod"

import {
  SpaceInfo,
  SpaceSettingsKeys,
  WriteableSpaceSettingsSchema,
  ZodSpaceSettingsKeys,
} from "gather-common/dist/src/public/resources/space"
import { HttpV2Paths, RequestMethod } from "../../httpAPI"

const c = initContract()

export const settings = c.router({
  get: {
    method: RequestMethod.GET,
    path: HttpV2Paths.SpaceSettings,
    query: z.object({
      spaceSettings: z.array(ZodSpaceSettingsKeys),
    }),
    responses: { 200: c.type<Pick<SpaceInfo["settings"], SpaceSettingsKeys>>() },
  },
  update: {
    method: RequestMethod.PATCH,
    path: HttpV2Paths.SpaceSettings,
    body: WriteableSpaceSettingsSchema,
    responses: { 200: c.noBody() },
  },

  map: {
    update: {
      method: RequestMethod.PATCH,
      path: HttpV2Paths.SpaceSettingsMap,
      body: z.object({
        map: z.string(),
      }),
      responses: { 200: c.noBody() },
    },
  },
  password: {
    update: {
      method: RequestMethod.PATCH,
      path: HttpV2Paths.SpaceSettingsPassword,
      body: z.object({
        newPassword: z.string(),
      }),
      responses: { 200: c.noBody() },
    },
  },
})
