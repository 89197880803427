import { initContract } from "@ts-rest/core"
import { z } from "zod"

import { InviteToken, MapBackground } from "gather-common/dist/src/public/resources/invite"
import { HttpV2Paths, RequestMethod } from "../httpAPI"
import { zodUuid } from "../zodHelpers"

const c = initContract()

export const inviteTokens = c.router({
  create: {
    method: RequestMethod.POST,
    path: HttpV2Paths.InviteTokens,
    body: z.object({
      spaceId: zodUuid,
      expiresAt: z.string().optional(),
      role: z.string().optional(),
      email: z.string().optional(),
      inviterName: z.string().optional(),
      deskId: z.string().optional(),
      deskMapId: z.string().optional(),
    }),
    responses: { 200: c.type<InviteToken>() },
  },
  get: {
    method: RequestMethod.GET,
    path: HttpV2Paths.InviteToken,
    responses: {
      200: c.type<{
        tokenData: InviteToken
        mapData?: MapBackground
      }>(),
    },
  },
})
