import { IncomingHttpHeaders } from "http"

import { is } from "ramda"

export const CF_IP_HEADER = "cf-connecting-ip"
export const FORWARDED_IP_HEADER = "x-forwarded-for"
// These headers are added with https://github.com/gathertown/iaac/blob/main/components/cloudflare/transforms.tf
export const CF_IP_LAT_HEADER = "gather-ip-latitude"
export const CF_IP_LONG_HEADER = "gather-ip-longitude"
export const CF_IP_COUNTRY_HEADER = "gather-geo-country"

export function httpRequestIp(req: { ip: string; headers: IncomingHttpHeaders }): string
export function httpRequestIp(req: {
  ip?: string
  headers: IncomingHttpHeaders
}): string | undefined
export function httpRequestIp(req: {
  ip?: string
  headers: IncomingHttpHeaders
}): string | undefined {
  // http traffic is proxied through CloudFlare, so all the normal headers just have CF IPs
  // instead of the user's actual IP. Use `cf-connecting-ip` instead:
  // https://developers.cloudflare.com/fundamentals/reference/http-request-headers/#cf-connecting-ip
  // If that isn't present, use x-forwarded-for:
  // https://developers.cloudflare.com/fundamentals/reference/http-request-headers/#x-forwarded-for
  // If neither of these are present, it means the request wasn't proxied through CF.
  // Fall back to the `ip`, which is populated on Express requests.
  const ccip = req.headers[CF_IP_HEADER] || req.headers[FORWARDED_IP_HEADER]
  return is(String, ccip) ? ccip : req.ip
}

// https://developers.cloudflare.com/ruleset-engine/rules-language/fields/
// Represents the 2-letter country code in "ISO 3166-1 Alpha 2 format".
// https://www.iso.org/obp/ui/#search/code/
export function httpRequestIpCountry(req: { headers: IncomingHttpHeaders }): string | undefined {
  const ipCountry = req.headers[CF_IP_COUNTRY_HEADER]
  return is(String, ipCountry) ? ipCountry : undefined
}

// Get the latitude and longitude from Cloudflare headers.
// If they aren't available or don't parse as numbers, returns null.
export const httpRequestIpLatLon = (req: {
  headers: IncomingHttpHeaders
}): [number, number] | null => {
  const { [CF_IP_LAT_HEADER]: latHeader, [CF_IP_LONG_HEADER]: lonHeader } = req.headers ?? {}
  const latStr = Array.isArray(latHeader) ? latHeader[0] : latHeader
  const lonStr = Array.isArray(lonHeader) ? lonHeader[0] : lonHeader
  const lat = parseFloat(latStr || "")
  const lon = parseFloat(lonStr || "")
  const latLonOk =
    lat !== undefined && !Number.isNaN(lat) && lon !== undefined && !Number.isNaN(lon)
  return latLonOk ? [lat, lon] : null
}
