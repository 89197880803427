import { initContract } from "@ts-rest/core"
import { z } from "zod"

import { HttpV2Paths, RequestMethod } from "../httpAPI"

const c = initContract()

const supportRequestSchema = z.object({
  spaceId: z.string().optional(),
  spaceUrl: z.string().url().optional(),
  contactReason: z.string(),
  browserName: z.string().optional(),
  email: z.string().optional(),
  name: z.string().optional(),
  spaceUseCase: z.string().optional(),
  rating: z.number().optional(),
  messageBody: z.string().optional(),
  deviceInfo: z.string().optional(),
  isMobile: z.boolean().optional(),
  gates: z.array(z.string()).optional(),
  performanceProblemDescription: z.string().optional(),
  videoDebugFeedback: z.record(z.string(), z.any()).optional(),
  videoDebugSessionData: z.record(z.string(), z.any()).optional(),
  videoAllowedData: z.record(z.string(), z.boolean()).optional(),
  videoSubscribedData: z.record(z.string(), z.any()).optional(),
  gameDebugInfo: z.record(z.string(), z.any()).optional(),
  locale: z.string().optional(),
  browserLanguage: z.string().optional(),
  desktopLogFileName: z.string().optional(),
  participants: z.record(z.string(), z.string()).optional(),
  affectedUsers: z.array(z.string()).optional(),
})

const debugDataEncodedSchema = z.object({
  videoDebugFeedback: z.string().optional(),
  videoDebugSessionData: z.string().optional(),
  videoAllowedData: z.string().optional(),
  videoSubscribedData: z.string().optional(),
  gameDebugInfo: z.string().optional(),
})

export const supportRequestSchemaEncoded = supportRequestSchema.merge(debugDataEncodedSchema)

export const supportRequests = c.router({
  create: {
    method: RequestMethod.POST,
    path: HttpV2Paths.SupportRequests,
    body: supportRequestSchemaEncoded,
    metadata: { supportRequestSchema, supportRequestSchemaEncoded },
    responses: { 200: c.noBody() },
  },
})
