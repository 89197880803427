import { Box } from "@mui/material"
import { drop, join, pipe, split } from "ramda"
import React, { FC, memo } from "react"

import { RenderRowProps, WithRestProps } from "./types"
import { formatMultiArgumentPayloads, formatTimestamp, isWebRTCIssueMessage } from "./utils"

const getWebRTCIssue = pipe<[string], string[], string[], string>(split("/"), drop(1), join("/"))

export const RenderWebRTCIssueRow: FC<WithRestProps<RenderRowProps>> = memo(
  ({ message, className = "", ...props }) => {
    if (!isWebRTCIssueMessage(message)) return null
    const { type, timestamp, ...rest } = message

    return (
      <div className={`row-message webrtcIssue-message ${className}`} {...props}>
        <Box flexShrink={0}>{formatTimestamp(timestamp)}</Box>
        <div>
          <div className="webrtcIssue-message-container message-verbose">
            <div className="webrtcIssue-message-name message-icon">
              WebRTC Issue: {getWebRTCIssue(type)}
            </div>
            <div>{formatMultiArgumentPayloads(JSON.stringify(rest))}</div>
          </div>
        </div>
      </div>
    )
  },
)

export const RenderSimpleWebRTCIssueRow: FC<WithRestProps<RenderRowProps>> = memo(
  ({ message, className = "", ...props }) => {
    if (!isWebRTCIssueMessage(message)) return null
    const { type, timestamp, ...rest } = message

    return (
      <div
        className={`row-message webrtcIssue-message webrtcIssue-message-simple   ${className}`}
        {...props}
      >
        <Box flexShrink={0}>{formatTimestamp(timestamp)}</Box>
        <div>
          <div className="webrtcIssue-message-container">
            <div className="webrtcIssue-message-name">{getWebRTCIssue(type)}</div>
            <div>{formatMultiArgumentPayloads(JSON.stringify(rest))}</div>
          </div>
        </div>
      </div>
    )
  },
)
