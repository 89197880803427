import { initContract } from "@ts-rest/core"

import { HttpV2Paths, RequestMethod } from "../../httpAPI"

const c = initContract()

export const deleteRecordings = c.router({
  receive: {
    method: RequestMethod.POST,
    path: HttpV2Paths.ScheduledDeleteRecordings,
    body: c.noBody(),
    responses: { 200: c.noBody() },
  },
})
