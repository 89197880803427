import { initContract } from "@ts-rest/core"
import { z } from "zod"

import { HttpV2Paths, RequestMethod } from "../../httpAPI"

const c = initContract()

export const cloudflare = c.router({
  verify: {
    method: RequestMethod.POST,
    path: HttpV2Paths.CloudflareSiteVerify,
    body: z.object({ cf_token: z.string() }),
    responses: { 200: c.type<{ success: boolean }>() },
  },
})
