import { initContract } from "@ts-rest/core"

import { UserId } from "gather-common/dist/src/public/resources/userIds"
import { HttpV2Paths, RequestMethod } from "../../httpAPI"

const c = initContract()

export const id = c.router({
  get: {
    method: RequestMethod.GET,
    path: HttpV2Paths.UserIds,
    responses: { 200: c.type<UserId>() },
  },
})
