import { initContract } from "@ts-rest/core"

import { HttpV2Paths, RequestMethod } from "../../../../httpAPI"
import { SSOLinkedSpace } from "../../../../resources/sso"

const c = initContract()

export const source = c.router({
  get: {
    method: RequestMethod.GET,
    path: HttpV2Paths.SpaceSSOLinkedSpaceSource,
    responses: { 200: c.type<SSOLinkedSpace | null>() },
  },
})
