import { initContract } from "@ts-rest/core"

import { HttpV2Paths, RequestMethod } from "../../../httpAPI"
import { AdminSpaceMap } from "../../../spaces"

const c = initContract()

export const maps = c.router({
  get: {
    method: RequestMethod.GET,
    path: HttpV2Paths.AdminSpaceMap,
    responses: {
      200: c.type<AdminSpaceMap>(),
    },
  },
  list: {
    method: RequestMethod.GET,
    path: HttpV2Paths.AdminSpaceMaps,
    responses: {
      200: c.type<AdminSpaceMap[]>(),
    },
  },
})
