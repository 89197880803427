import { tsrAPI } from "gather-browser-common/dist/src/public/tsRestClient"
import { AVCLIENTSTATE_DEFAULT_PAGE_SIZE } from "gather-common/dist/src/public/av"
import {
  BrowserVideoClientStateSession,
  BrowserVideoClientStateSessionIndex,
} from "gather-prisma-types/dist/src/public/client"

export type AvClientSessionParams = {
  userAgentOs?: string
  userAgentBrowser?: string
  avStrategy?: string
  spaceIdOrUserId?: string
  email?: string
  id?: string
}

export const listAvClientStateSessions = async (
  page = 1,
  pageSize = AVCLIENTSTATE_DEFAULT_PAGE_SIZE,
  params: AvClientSessionParams = {},
): Promise<BrowserVideoClientStateSessionIndex[]> => {
  try {
    const response = await tsrAPI.admin.avClientStateSessions.list({
      query: {
        page,
        pageSize,
        ...params,
      },
    })

    return response
  } catch (e) {
    let message = "Failed to retrieve A/V client state sessions."

    if (e instanceof Error) {
      message = e.message
    }

    return Promise.reject(message)
  }
}

export const fetchAvClientStateSessionById = async (
  sessionId?: string,
): Promise<BrowserVideoClientStateSession | undefined> => {
  try {
    if (sessionId) {
      const response = await tsrAPI.admin.avClientStateSessions.get({
        params: { sessionId },
      })

      return response
    } else {
      throw new Error("Missing sessionId")
    }
  } catch (e) {
    let message = "Failed to retrieve A/V client state session."

    if (e instanceof Error) {
      message = e.message
    }

    return Promise.reject(message)
  }
}

export const fetchAvClientStateUserFeedbackBySessionId = async (
  sessionId?: string,
): Promise<Awaited<ReturnType<typeof tsrAPI.admin.avClientStateSessions.feedback.get>>> => {
  try {
    if (sessionId) {
      const response = await tsrAPI.admin.avClientStateSessions.feedback.get({
        params: { sessionId },
      })

      return response
    } else {
      throw new Error("Missing sessionId")
    }
  } catch (e) {
    let message = "Failed to retrieve user feedback."

    if (e instanceof Error) {
      message = e.message
    }

    return Promise.reject(message)
  }
}
