import { Class } from "type-fest"

export const getErrorMessage = (error: unknown): string =>
  (error instanceof Error && error.message) || ""

/**
 * For rethrowing all errors except a particular type that you anticipate and specially handle.
 */
export function rethrowExcept<T extends Class<unknown>>(
  type: T,
  error: unknown,
): asserts error is InstanceType<T> {
  if (error instanceof type) return
  throw error
}
