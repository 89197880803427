import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import Avatar from "@mui/material/Avatar"
import Box from "@mui/material/Box"
import Collapse from "@mui/material/Collapse"
import IconButton from "@mui/material/IconButton"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import Typography from "@mui/material/Typography"
import Label from "components/dashboard/Label"
import React, { FC, useState } from "react"

import { MapObject as ObjectDB } from "gather-http-common/dist/src/public/spaces"
import ObjectInstanceTable from "./ObjectInstancesTable"

interface Props {
  group: ObjectDB[]
  templateId: string
  index: number
  onModalOpen: (obj: ObjectDB) => void
}

const ObjectGroupRow: FC<Props> = ({ group, templateId, onModalOpen }) => {
  const [open, setOpen] = useState<boolean>(false)
  const displayObject = group[0]

  if (displayObject === undefined) {
    return (
      <TableRow hover key={templateId}>
        {templateId}
      </TableRow>
    )
  }

  return (
    <>
      <TableRow hover key={displayObject.id}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>

        <TableCell>
          <Avatar variant="square" src={displayObject.normal} />
        </TableCell>

        <TableCell>
          <Typography color="textPrimary" variant="body2">
            {displayObject._name}
          </Typography>
          <Typography color="textSecondary" variant="body2">
            {templateId}
          </Typography>
        </TableCell>

        <TableCell>
          <Typography color="textPrimary" variant="body2">
            {group.length}
          </Typography>
        </TableCell>

        <TableCell>
          <Typography color="textPrimary" variant="body2">
            {displayObject.width} x {displayObject.height}
          </Typography>
        </TableCell>

        <TableCell>
          {displayObject._tags?.map((tag: string, index: number) => (
            <Box component="span" sx={{ mr: 1, mt: 2 }} key={`${displayObject.id}-${tag}`}>
              <Label key={`tag-${index}`} color="primary">
                {tag}
              </Label>
            </Box>
          ))}
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <ObjectInstanceTable group={group} onModalOpen={onModalOpen} />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

export default ObjectGroupRow
