// Webpack version 5+ does not support Buffer natively anymore
import { tsrAPI } from "gather-browser-common/dist/src/public/tsRestClient"
import { Wearable, WearablePart } from "gather-common/dist/src/public/resources/users"

/**
 * API request to fetch all wearables in the database.
 * @returns list of Wearables
 */
export const listWearables = async (): Promise<Wearable[]> => {
  try {
    const response = await tsrAPI.admin.wearables.list({})

    return response
  } catch (e) {
    let message = "Failed to retrieve wearables."

    if (e instanceof Error) {
      message = e.message
    }

    return Promise.reject(message)
  }
}

/**
 * API request to upload wearables
 * @param wearables - form body of wearables being uploaded
 * @returns Wearable[] promise
 */
export const createWearables = async (wearables: Wearable[]): Promise<Wearable[]> => {
  try {
    const response = await tsrAPI.admin.wearables.create({
      body: wearables,
    })
    return response
  } catch (e) {
    let message = "Failed to upload wearables."

    if (e instanceof Error) {
      message = e.message
    }

    return Promise.reject(message)
  }
}

export type EditableWearableFields = Pick<
  Wearable,
  "name" | "type" | "subType" | "style" | "isDefault"
>

/**
 * API request to bulk patch wearables using a record
 * @param data - record consisted of id and data to be updated
 * @returns Record<string, Wearable> Promise
 */
export const updateWearables = async (
  data: Record<string, EditableWearableFields>,
): Promise<Awaited<ReturnType<typeof tsrAPI.admin.wearables.updateMany>>> => {
  const response = await tsrAPI.admin.wearables.updateMany({
    body: data,
  })

  return response
}

/**
 * API request to delete a wearable
 * @param wearableId - ID of wearable being deleted
 * @returns void Promise
 */
export const deleteWearable = async (wearableId: string): Promise<void> => {
  try {
    return tsrAPI.admin.wearables.delete({
      params: { wearableId },
    })
  } catch (e) {
    let message = "Failed to delete wearable."

    if (e instanceof Error) {
      message = e.message
    }

    return Promise.reject(message)
  }
}

export type EditableWearablePartFields = Partial<Pick<WearablePart, "spritesheetUrl" | "layerId">>
