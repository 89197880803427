import Box from "@mui/material/Box"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import React, { FC } from "react"

import { RecordingPrisma } from "gather-common/dist/src/public/resources/recording"
import UserRecordingRow from "./UserRecordingRow"

interface Props {
  recordings: RecordingPrisma[]
  userId?: string
}

const UserRecordingsTable: FC<Props> = ({ recordings, userId }) => (
  <Box sx={{ minWidth: "100%" }}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>ID</TableCell>
          <TableCell>Recording Start Time</TableCell>
          <TableCell>Recording End Time</TableCell>
          <TableCell>Recording Expiry</TableCell>
          <TableCell>
            Generate Recording Download Link (generated links expire in 24 hours)
          </TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {recordings.map((data: RecordingPrisma) => (
          <UserRecordingRow key={data.id} recording={data} userId={userId} />
        ))}
      </TableBody>
    </Table>
  </Box>
)

export default UserRecordingsTable
