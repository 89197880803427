import { configureStore } from "@reduxjs/toolkit"

import avClientStateViewerReducer from "./avClientStateViewerSlice"

export const store = configureStore({
  reducer: {
    avClientStateViewer: avClientStateViewerReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
})

export type RootState = ReturnType<typeof store.getState>
