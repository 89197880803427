import { initContract } from "@ts-rest/core"
import { z } from "zod"

import { SpaceUserOnboardingChecklist } from "gather-common/dist/src/public/resources/onboardingChecklist"
import { HttpV2Paths, RequestMethod } from "../../../httpAPI"

const c = initContract()

export const onboardingChecklist = c.router({
  get: {
    method: RequestMethod.GET,
    path: HttpV2Paths.SpaceOnboardingChecklist,
    responses: {
      200: c.type<SpaceUserOnboardingChecklist>(),
    },
  },
  update: {
    method: RequestMethod.PATCH,
    path: HttpV2Paths.SpaceOnboardingChecklist,
    body: z.object({
      item: z
        .object({
          taskId: z.string(),
          isComplete: z.boolean(),
        })
        .optional(),
      maybeLater: z.boolean().optional(),
    }),
    responses: {
      200: c.noBody(),
    },
  },
})
