import { tsrAPI } from "gather-browser-common/dist/src/public/tsRestClient"
import { getErrorMessage } from "gather-common/dist/src/public/utils"

export const createSpaceUserGrapes = async (userId: string, spaceId: string): Promise<void> => {
  try {
    return await tsrAPI.admin.spaces.users.grapes.create({
      params: {
        spaceId,
        userId,
      },
    })
  } catch (e) {
    throw new Error(getErrorMessage(e, "Failed to invoke grapes for user."))
  }
}
