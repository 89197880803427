import { tsrAPI } from "gather-browser-common/dist/src/public/tsRestClient"
import { SpaceUserResource } from "gather-common/dist/src/public/resources/space"
import { getErrorMessage } from "gather-common/dist/src/public/utils"

export const listSpaceUsers: (
  spaceId: string | undefined,
) => Promise<Awaited<ReturnType<typeof tsrAPI.admin.spaces.users.list>>> = async (
  spaceId,
): Promise<SpaceUserResource[]> => {
  if (!spaceId) throw new Error("Missing spaceId")

  try {
    const spaceUsers = await tsrAPI.admin.spaces.users.list({
      params: { spaceId },
    })
    return spaceUsers || []
  } catch (error) {
    throw new Error(getErrorMessage(error, "Failed to retrieve users."))
  }
}
