import QueryKeys from "features/queryKeys"
import { useMutation, useQueryClient } from "react-query"

import { AdminSpace } from "gather-http-common/dist/src/public/spaces"
import { toggleStaffAccess } from "../../../api/spaces"
import { SpaceMutationCallbacks, SpaceMutationContext, SpaceUpdateVariables } from "./types"

/**
 * Mutation hook that updates a space
 * TODO @alecia - actually make this accept fields to update entire space
 * @returns ReactQuery useMutation hook
 */
const useToggleStaffAccess = (callbacks?: SpaceMutationCallbacks) => {
  const queryClient = useQueryClient()

  return useMutation((variables: SpaceUpdateVariables) => toggleStaffAccess(variables.spaceId), {
    onMutate: async (variables) => {
      const { spaceId } = variables
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      queryClient.cancelQueries([QueryKeys.Space, spaceId])

      // Snapshot the previous values
      const previousSpace = queryClient.getQueryData<AdminSpace>([QueryKeys.Space, spaceId])

      if (previousSpace) {
        const updatedSpace: AdminSpace = {
          ...previousSpace,
          settings: {
            ...previousSpace.settings,
            allowStaffAccess: !previousSpace.settings.allowStaffAccess,
          },
        }
        queryClient.setQueryData<AdminSpace>([QueryKeys.Space, spaceId], updatedSpace)
      }

      return { previousSpace }
    },

    onError: (_err, variables, context?: SpaceMutationContext) => {
      const { previousSpace } = context ?? {}
      const { spaceId } = variables

      if (previousSpace) {
        queryClient.setQueryData<AdminSpace>([QueryKeys.Space, spaceId], previousSpace)
      }

      if (callbacks?.onError) {
        callbacks?.onError()
      }
    },

    onSuccess: () => {
      if (callbacks?.onSuccess) {
        callbacks?.onSuccess()
      }
    },

    onSettled: (_data, _err, variables) => {
      const { spaceId } = variables
      queryClient.invalidateQueries([QueryKeys.Space, spaceId])
    },
  })
}

export default useToggleStaffAccess
