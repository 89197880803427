import { useQuery, UseQueryResult } from "react-query"

import * as WearablesAPI from "../../api/wearables"

/**
 * Fetches all wearables
 * @returns ReactQuery object where data is type of Wearable | undefined
 */
export const useWearables = (): UseQueryResult<
  Awaited<ReturnType<typeof WearablesAPI.listWearables>>
> =>
  useQuery("wearables", WearablesAPI.listWearables, {
    initialData: undefined,
    retry: false,
  })
