export type { SpaceRecording, SpaceRecordingBudgetInfo } from "../../resources/recording"
export {
  DELETE_RECORDING_TIME_MS,
  IGNORE_STALE_INITIATING_RECORDINGS_THRESHOLD,
  MAXIMUM_RECORDING_LENGTH_MS,
  PERSIST_RECORDING_TIME_DAYS,
  PERSIST_RECORDING_TIME_MS,
  PERSIST_RECORDING_TIME_STILL_SHOW_MS,
  RECORDING_PER_USER_MS,
} from "../../resources/recording"
export type { RecordingPrisma } from "gather-prisma-types/dist/src/public/client"
