import { tsrAPI, tsrAPIPathParams } from "gather-browser-common/dist/src/public/tsRestClient"

export const getSpaceRecordings = async (
  spaceId?: string,
): Promise<Awaited<ReturnType<typeof tsrAPI.spaces.recordings.list>>> =>
  await tsrAPI.spaces.recordings.list({
    params: {
      spaceId: tsrAPIPathParams.spaceId(spaceId),
    },
  })

export const getSpaceRecordingBudget = async (
  spaceId?: string,
): Promise<{
  hasValidPaidPlanForRecording: boolean
  recordingTimeUsedMs: number
  recordingBudgetMs: number
  currentSubscriptionStart: string
}> => {
  const result = await tsrAPI.spaces.recordings.budget.get({
    params: {
      spaceId: tsrAPIPathParams.spaceId(spaceId),
    },
  })
  return (
    result ?? {
      hasValidPaidPlanForRecording: false,
      recordingTimeUsedMs: 0,
      recordingBudgetMs: 0,
      currentSubscriptionStart: "",
    }
  )
}
