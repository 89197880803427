import { Buffer } from "buffer"

import {
  NewObjectTemplateFields,
  UpdateObjectTemplateFields,
} from "gather-admin-common/dist/src/public/objectTemplates/types"
import { tsrAPI } from "gather-browser-common/dist/src/public/tsRestClient"
import { Orientation } from "gather-common/dist/src/public/constants"

/**
 * API request to fetch all object templates in the database
 * @returns list of ObjectTemplates
 */
export const listObjectTemplates = async (): Promise<
  Awaited<ReturnType<typeof tsrAPI.admin.objectTemplates.list>>
> => {
  try {
    const response = await tsrAPI.admin.objectTemplates.list()

    return response
  } catch (e) {
    let message = "Failed to retrieve object templates."

    if (e instanceof Error) {
      message = e.message
    }

    return Promise.reject(message)
  }
}

/**
 * API request to fetch a single object templates in the database
 * @param templateId - ID of the object template to retrieve
 * @returns ObjectTemplate with variants
 */
export const getObjectTemplate = async (
  templateId: string,
): Promise<Awaited<ReturnType<typeof tsrAPI.admin.objectTemplates.get>>> => {
  try {
    const response = await tsrAPI.admin.objectTemplates.get({
      params: { objectTemplateId: templateId },
    })

    return response
  } catch (e) {
    let message = "Failed to retrieve object template."

    if (e instanceof Error) {
      message = e.message
    }

    return Promise.reject(message)
  }
}

/**
 * API request to create a new object template
 * @param fields - form body of object template being created
 * @returns void promise
 */
export const submitNewObjectTemplate = async (
  fields: NewObjectTemplateFields,
): Promise<Awaited<ReturnType<typeof tsrAPI.admin.objectTemplates.create>>> => {
  try {
    return await tsrAPI.admin.objectTemplates.create({
      body: fields,
    })
  } catch (e) {
    let message = "Failed to create new object template."

    if (e instanceof Error) {
      message = e.message
    }

    return Promise.reject(message)
  }
}

/**
 * API Request to update a template's raw data fields
 * @param templateId - ID of the object template to be updated
 * @param fields - form fields that were submitted
 * @returns Promise
 */
export const updateTemplate: (
  templateId: string,
  fields: UpdateObjectTemplateFields,
) => Promise<void> = async (templateId, fields): Promise<void> => {
  try {
    return await tsrAPI.admin.objectTemplates.update({
      params: { objectTemplateId: templateId },
      body: fields,
    })
  } catch (e) {
    let message = "Failed to update object template."

    if (e instanceof Error) {
      message = e.message
    }

    return Promise.reject(message)
  }
}

/**
 * API request to delete an object template
 * @param templateId - ID of object template being deleted
 * @returns void Promise
 */
export const deleteObjectTemplate = async (templateId: string): Promise<void> => {
  try {
    return await tsrAPI.admin.objectTemplates.delete({
      params: { objectTemplateId: templateId },
    })
  } catch (e) {
    let message = "Failed to delete object template."

    if (e instanceof Error) {
      message = e.message
    }

    return Promise.reject(message)
  }
}

export type BulkUploadObject = {
  name: string
  variants: {
    orientation: Orientation
    color: string
    normal: Buffer
    normalFileType: string
  }[]
}

/**
 * API request to delete a variant belonging to a specific object template
 * @param templateId - ID of object template the variant belongs to
 * @param variantId - ID of object variant to be deleted
 * @returns void Promise
 */
export const bulkUploadObjects = async (
  objects: BulkUploadObject[],
): Promise<Awaited<ReturnType<typeof tsrAPI.admin.objectTemplates.bulkUploadList>>> => {
  const data = await tsrAPI.admin.objectTemplates.bulkUploadList({
    body: objects,
  })

  return data
}
