import { AdminPermission as Can, AdminRoleGroup } from "./types"

/*
  Static Role Definitions
*/

export const Default: AdminRoleGroup = {
  permissions: [],
}

export const Admin: AdminRoleGroup = {
  permissions: [
    Can.ManageRoles,
    Can.ManageAdminUsers,
    Can.ToggleSpaceStaffAccess, // TODO @alecia - remove this permission later
  ],
}

export const Art: AdminRoleGroup = {
  permissions: [
    Can.ViewObjectTemplates,
    Can.ManageObjectTemplates,
    Can.UpdateTutorialAreas,
    Can.ManageWearables,
    Can.ViewMapInspector,
  ],
}

export const ArtLead: AdminRoleGroup = {
  permissions: [
    Can.ViewObjectTemplates,
    Can.ManageObjectTemplates,
    Can.ManageObjectTemplateCategories,
    Can.UpdateTutorialAreas,
    Can.ManageWearables,
    Can.ViewMapInspector,
  ],
}

export const Clients: AdminRoleGroup = {
  permissions: [
    Can.ViewMapInspector,
    Can.ViewReservations,
    Can.UpdateReservations,
    Can.CreateReservations,
    Can.ViewObjectTemplates,
    Can.ManageSpaces,
    Can.ManageUsers,
    Can.ViewClientStateSessions,
  ],
}

export const ClientLead: AdminRoleGroup = {
  permissions: [
    Can.ViewMapInspector,
    Can.ViewReservations,
    Can.UpdateReservations,
    Can.DeleteReservations,
    Can.CreateReservations,
    Can.ViewObjectTemplates,
    Can.ManageSpaces,
    Can.ManageUsers,
    Can.ViewClientStateSessions,
  ],
}

export const Finance: AdminRoleGroup = {
  permissions: [],
}

export const Engineering: AdminRoleGroup = {
  permissions: [
    Can.ViewMapInspector,
    Can.ViewObjectTemplates,
    Can.ManageObjectTemplates,
    Can.ManageObjectTemplateCategories,
    Can.ManageWearables,
    Can.ViewReservations,
    Can.UpdateReservations,
    Can.DeleteReservations,
    Can.CreateReservations,
    Can.ManageSpaces,
    Can.ManageUsers,
    Can.UpdateTutorialAreas,
    Can.ViewClientStateSessions,
  ],
}

export const MobileEngineering: AdminRoleGroup = {
  permissions: [Can.ManageMobileDeviceTokens],
}

interface AdminRoleList {
  [key: string]: AdminRoleGroup
}

export const AdminRole: AdminRoleList = {
  Default,
  Admin,
  Art,
  ArtLead,
  Clients,
  ClientLead,
  Finance,
  Engineering,
  MobileEngineering,
}
