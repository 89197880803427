export enum CompanyRole {
  "Executive (C-level / VP)" = "Executive (C-level / VP)",
  "Director" = "Director",
  "Manager" = "Manager",
  "Team Member / Individual Contributor" = "Team Member / Individual Contributor",
  "Freelancer" = "Freelancer",
  "Student" = "Student",
  "Other" = "Other",
}

export enum CompanyWorkType {
  "Leadership" = "Leadership",
  "Human Resources" = "Human Resources",
  "Engineering" = "Engineering",
  "Product Management" = "Product Management",
  "Project or Program Management" = "Project or Program Management",
  "Education Professional" = "Education Professional",
  "Design" = "Design",
  "Marketing" = "Marketing",
  "IT" = "IT",
  "Communications" = "Communications",
  "Customer Support" = "Customer Support",
  "Data Analytics" = "Data Analytics",
  "Finance/Accounting" = "Finance/Accounting",
  "Sales" = "Sales",
  "Legal" = "Legal",
  "Other" = "Other",
}

export enum LearnAboutType {
  "I've used Gather before" = "I've used Gather before",
  "Social Media" = "Social Media",
  "YouTube" = "YouTube",
  "Blog" = "Blog",
  "News Article" = "News Article",
  "Google Search" = "Google Search",
  "Word of mouth" = "Word of mouth",
  "Other" = "Other",
}

// noinspection JSUnusedGlobalSymbols -- read dynamically
export enum CompanySize {
  MAX_10 = "1-10",
  MAX_50 = "11-50",
  MAX_200 = "51-200",
  MAX_500 = "201-500",
  MAX_1000 = "501-1,000",
  MAX_10000 = "1,001-10,000",
  OVER_10000 = "10,000+",
}
