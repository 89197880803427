/* Material Icons */
// import ClearIcon from "@mui/icons-material/Clear"; // TODO @alecia: add back in
import DeleteIcon from "@mui/icons-material/Delete"
import EditIcon from "@mui/icons-material/ModeEdit"
/* MUI Components */
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Tooltip from "@mui/material/Tooltip"
import PermittedComponent from "components/authentication/PermittedComponent"
import AlertDialog from "components/dashboard/AlertDialog"
import { BreadcrumbTrail } from "components/layout/dashboard/Breadcrumbs"
import PageContainer from "components/layout/dashboard/PageContainer"
import { useObjectTemplate } from "features/objectTemplates/hooks"
import useDeleteObjectTemplate from "features/objectTemplates/mutations/useDeleteObjectTemplate"
import React, { FC, useCallback, useState } from "react"
import { toast } from "react-hot-toast"
import { useNavigate, useParams } from "react-router-dom"

import { AdminPermission as Can } from "gather-admin-common/dist/src/public/roles/types"
import ObjectTemplateDetails from "./partials/ObjectTemplateDetails"

const PAGE_TITLE = "Object Template Details"
const DELETE_CONFIRMATION =
  "Are you sure you want to delete this object template? This action is not reversible."

const ObjectTemplateDetailsPage: FC = () => {
  const { objectId } = useParams()
  const tempId = decodeURIComponent(objectId ?? "")
  const navigate = useNavigate()

  const { data: objectTemplate, isLoading } = useObjectTemplate(tempId)
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false)

  const onSuccessfulDeletion = () => {
    toast.success("Object Template was successfully deleted.")
  }

  const onDeletionError = () => {
    toast.error(`Error: Object Template "${objectTemplate?.name}" could not be deleted.`)
  }

  const confirmDeletion = useCallback(
    () => setShowDeleteConfirmation(true),
    [setShowDeleteConfirmation],
  )

  const { mutate: deleteObjectTemplate } = useDeleteObjectTemplate({
    onSuccess: onSuccessfulDeletion,
    onError: onDeletionError,
  })

  const handleDelete = useCallback(
    (confirm: boolean) => {
      setShowDeleteConfirmation(false)

      if (confirm && objectTemplate?.id) {
        deleteObjectTemplate(objectTemplate.id)
        navigate("/dashboard/mapmaker/objects")
      }
    },
    [setShowDeleteConfirmation, deleteObjectTemplate, objectTemplate, navigate],
  )

  const breadcrumbs: BreadcrumbTrail[] = [
    {
      to: "/dashboard/mapmaker/objects",
      title: "Manage Object Templates",
    },
  ]

  return (
    <PageContainer
      pageTitle={PAGE_TITLE}
      breadcrumbs={breadcrumbs}
      buttons={
        !isLoading &&
        objectTemplate && (
          <PermittedComponent permissions={[Can.ManageObjectTemplates]} requiresAll={true}>
            <Button
              sx={{ mr: 2 }}
              color="primary"
              onClick={() => navigate(`/dashboard/mapmaker/objects/${tempId}/edit`)}
              variant="contained"
              startIcon={<EditIcon />}
            >
              Edit
            </Button>

            <Tooltip title="NOTE: You cannot undo this" placement="top" arrow>
              <Button
                color="error"
                onClick={confirmDeletion}
                variant="contained"
                startIcon={<DeleteIcon />}
              >
                Delete
              </Button>
            </Tooltip>
          </PermittedComponent>
        )
      }
    >
      {isLoading ? (
        <Box>Loading...</Box>
      ) : (
        objectTemplate && <ObjectTemplateDetails id={tempId} objectTemplate={objectTemplate} />
      )}
      <AlertDialog
        isOpen={showDeleteConfirmation}
        onClose={handleDelete}
        title={`Confirm Deletion of ${objectTemplate?.name}`}
      >
        {DELETE_CONFIRMATION}
      </AlertDialog>
    </PageContainer>
  )
}

export default ObjectTemplateDetailsPage
