import { initContract } from "@ts-rest/core"

import { CoreRole, SpaceResource } from "gather-common/dist/src/public/resources/space"
import { HttpV2Paths, RequestMethod } from "../../../httpAPI"

const c = initContract()

export const spaces = c.router({
  list: {
    method: RequestMethod.GET,
    path: HttpV2Paths.AdminAuthUserSpaces,
    responses: {
      200: c.type<{
        spaces: SpaceResource[]
        userRoles: {
          [key: string]: CoreRole[]
        }
      }>(),
    },
  },
})
