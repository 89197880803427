import { tsrAPI } from "gather-browser-common/dist/src/public/tsRestClient"
import { getErrorMessage } from "gather-common/dist/src/public/utils"

export const getSpaceCapacity = async (spaceId: string): Promise<number> => {
  try {
    const response = await tsrAPI.admin.spaces.capacity.get({ params: { spaceId } })

    return response.maxCapacity
  } catch (error) {
    throw new Error(getErrorMessage(error, "Failed to retrieve space capacity."))
  }
}
