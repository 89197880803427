import { initContract } from "@ts-rest/core"
import { z } from "zod"

import { HttpV2Paths, RequestMethod } from "../../../httpAPI"

const c = initContract()

export const notifications = c.router({
  create: {
    method: RequestMethod.POST,
    path: HttpV2Paths.AdminAuthUserSendPushNotifications,
    body: z.object({
      title: z.string().optional(),
      body: z.string().optional(),
    }),
    responses: {
      200: c.noBody(),
    },
  },
})
