import { curry, identity, ifElse, keys, mapObjIndexed, paths, pipe, values, zipObj } from "ramda"

import { isDictionary } from "./utils"

export const VIDEO_STATS_KEYS_TO_PATH = {
  videoEnabled: ["videoEnabled"],
  state: ["state"],
  currentState: ["video", "summary", "currentState"],
  muted: ["muted"],
  enabled: ["enabled"],
  readyState: ["video", "summary", "readyState"],
  width: ["video", "summary", "webrtcProps", "width"],
  height: ["video", "summary", "webrtcProps", "height"],
  sfuAddress: ["video", "summary", "webrtcProps", "sfuAddress"],
  videoBitrate: ["video", "summary", "webrtcProps", "videoBitrate"],
  audioBitrate: ["video", "summary", "webrtcProps", "audioBitrate"],
  webrtcConnState: ["video", "summary", "webrtcProps", "webrtcConnState"],
  iceTransportPolicy: ["video", "summary", "webrtcProps", "iceTransportPolicy"],
}

export const getVideoStats = (
  keyToPath: Record<string, string[]>,
  playerInfo: { [player: string]: unknown },
): Record<string, unknown> =>
  mapObjIndexed(pipe(ifElse(isDictionary, extractVideoStats(keyToPath), identity)), playerInfo)

export const extractVideoStats = curry(
  (keyToPath: Record<string, string[]>, playerInfo: { [player: string]: unknown }) =>
    zipObj(keys(keyToPath), paths(values(keyToPath), playerInfo)),
)
