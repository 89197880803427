/* External modules */
import { BreadcrumbTrail } from "components/layout/dashboard/Breadcrumbs"
/* Local modules */
import PageContainer from "components/layout/dashboard/PageContainer"
import React, { FC } from "react"

import NewObjectTemplateForm from "./partials/NewObjectTemplateForm"

const PAGE_TITLE = "New Object Template"

const breadcrumbs: BreadcrumbTrail[] = [
  {
    to: "/dashboard/mapmaker/objects",
    title: "Manage Object Templates",
  },
]

const ObjectTemplateNewFormPage: FC = () => (
  <PageContainer pageTitle={PAGE_TITLE} breadcrumbs={breadcrumbs}>
    <NewObjectTemplateForm />
  </PageContainer>
)

export default ObjectTemplateNewFormPage
