/* External modules */
/* Material Icons */
import AddIcon from "@mui/icons-material/Add"
/* MUI Components */
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import Divider from "@mui/material/Divider"
import FormHelperText from "@mui/material/FormHelperText"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import TagField from "components/inputs/TagField"
import { ObjectTemplateFields } from "features/objectTemplates/types"
import { FormikProps } from "formik"
import React, { FC, useCallback, useState } from "react"

import { NewObjectVariant } from "gather-admin-common/dist/src/public/objectTemplates/types"
import { ObjectVariant } from "gather-common/dist/src/public/resources/objectTemplates"
import { UpdatedVariantFields } from "../../../../features/objectTemplates/mutations/types"
import EditObjectVariantForm from "./EditObjectVariantForm"
import InteractionOptionsFields from "./InteractionOptionsFields"
import NewObjectVariantForm from "./NewObjectVariantForm"
/* Local modules */
import ObjectVariantsTable from "./ObjectVariantsTable"

const ObjectTemplateForm: FC<FormikProps<ObjectTemplateFields>> = (formik) => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    setFieldValue,
  } = formik
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [variantToEdit, setVariantToEdit] = useState<NewObjectVariant | ObjectVariant | undefined>()

  const handleEdit = useCallback(
    (variant: NewObjectVariant | ObjectVariant) => {
      setVariantToEdit(variant)
      setIsEditing(true)
      setModalOpen(true)
    },
    [setVariantToEdit, setIsEditing, setModalOpen],
  )

  const handleModalClose = useCallback(() => {
    setIsEditing(false)
    setModalOpen(false)
  }, [setModalOpen, setIsEditing])

  const handleModalOpen = useCallback(() => {
    setModalOpen(true)
  }, [setModalOpen])

  const handleNewVariant = useCallback(
    (newVariant: NewObjectVariant) => {
      // by default, the first variant uploaded becomes the default variant
      const variant = values.variants.length === 0 ? { ...newVariant, default: true } : newVariant
      setFieldValue("variants", [...values.variants, variant])
      handleModalClose()
    },
    [setFieldValue, handleModalClose, values.variants],
  )

  const handleEditVariant = useCallback(
    (updated: UpdatedVariantFields) => {
      const listToUpdate = values.variants.filter(
        (v: ObjectVariant | NewObjectVariant) =>
          !(v.color === variantToEdit?.color && v.orientation === variantToEdit?.orientation),
      )
      setFieldValue("variants", [...listToUpdate, updated])
      handleModalClose()
    },
    [
      handleModalClose,
      setFieldValue,
      values.variants,
      variantToEdit?.color,
      variantToEdit?.orientation,
    ],
  )

  const handleUpdateDefault = useCallback(
    (updated: ObjectVariant | NewObjectVariant) => {
      const updatedVariants = [...values.variants].map((variant) => {
        if (variant.default) {
          return {
            ...variant,
            default: false,
          }
        } else if (variant.color === updated.color && variant.orientation === updated.orientation) {
          return {
            ...updated,
            default: true,
          }
        }
        return variant
      })
      setFieldValue("variants", updatedVariants)
    },
    [setFieldValue, values.variants],
  )

  return (
    <>
      <form onSubmit={handleSubmit} autoComplete="off">
        <Box sx={{ display: "flex", gap: 10, flexWrap: "wrap", flexDirection: "row", mt: 1 }}>
          <Box sx={{ maxWidth: 600 }}>
            <Box
              sx={{
                display: "flex",
                gap: 3,
                justifyContent: "flex-start",
                flexWrap: "wrap",
              }}
            >
              <Typography variant="subtitle1">General Details</Typography>

              <TextField
                fullWidth
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
                label="Name"
                name="name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
                variant="outlined"
              />

              <TextField
                fullWidth
                multiline
                rows={2}
                error={Boolean(touched.desc && errors.desc)}
                helperText={touched.desc && errors.desc}
                label="Description"
                name="desc"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.desc}
                variant="outlined"
              />

              <TagField
                value={values.tags}
                name="tags"
                error={Boolean(touched.tags && errors.tags)}
                helperText={touched.tags && errors.tags}
                onChange={(newValue) => setFieldValue("tags", newValue)}
                onBlur={handleBlur}
              />

              <Box sx={{ width: "100%", my: 1 }}>
                <Divider />
              </Box>

              <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                <Typography variant="subtitle1" sx={{ width: "100%" }}>
                  Variants
                </Typography>

                <Box sx={{ width: 200 }}>
                  <Button
                    variant="contained"
                    component="label"
                    color="primary"
                    onClick={handleModalOpen}
                  >
                    <AddIcon />
                    Add Variant
                  </Button>
                </Box>
              </Box>

              {touched.variants && errors.variants && (
                <FormHelperText error={!!errors.variants}>{`${errors.variants}`}</FormHelperText>
              )}

              {values.variants.length > 0 && (
                <ObjectVariantsTable
                  variants={values.variants}
                  onUpdateDefault={handleUpdateDefault}
                  onEdit={handleEdit}
                  onRemove={(variant: ObjectVariant | NewObjectVariant) => {
                    const newList = values.variants.filter(
                      (v: ObjectVariant | NewObjectVariant) =>
                        !(v.color === variant.color && v.orientation === variant.orientation),
                    )
                    setFieldValue("variants", newList)
                  }}
                />
              )}
            </Box>
          </Box>

          <InteractionOptionsFields {...formik} />
        </Box>

        <Divider sx={{ mt: 5 }} />

        <Box sx={{ p: 2, display: "flex", justifyContent: "flex-end" }}>
          <Button
            color="primary"
            disabled={isSubmitting}
            sx={{ ml: 1 }}
            type="submit"
            variant="contained"
          >
            Create Template
          </Button>
        </Box>
      </form>

      <Dialog
        fullWidth
        maxWidth="xs"
        onClose={handleModalClose}
        open={modalOpen}
        sx={{ overflowY: "auto" }}
      >
        <NewObjectVariantForm onClose={handleModalClose} onSubmit={handleNewVariant} />
      </Dialog>

      <Dialog
        fullWidth
        maxWidth="xs"
        onClose={handleModalClose}
        open={modalOpen}
        sx={{ overflowY: "auto" }}
      >
        {isEditing ? (
          <EditObjectVariantForm
            variantToEdit={variantToEdit}
            onClose={handleModalClose}
            onSubmit={handleEditVariant}
          />
        ) : (
          <NewObjectVariantForm onClose={handleModalClose} onSubmit={handleNewVariant} />
        )}
      </Dialog>
    </>
  )
}

export default ObjectTemplateForm
