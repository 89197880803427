/* External Modules */
import { Buffer } from "buffer"

/* Material UI */
import Box from "@mui/material/Box"
import Divider from "@mui/material/Divider"
import FormControlLabel from "@mui/material/FormControlLabel"
import MenuItem from "@mui/material/MenuItem"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import { Field, FormikProps } from "formik"
import { Switch } from "formik-mui"
import React, { FC } from "react"

import { ObjectTemplate } from "gather-common/dist/src/public/resources/objectTemplates"
import { isTemplateType } from "gather-common/dist/src/public/utils"
import { Interaction } from "gather-http-common/dist/src/public/spaces"
/* Local modules */
import FileUploadField from "../../../../components/inputs/FileUploadField"
import { ObjectTemplateFields } from "../../../../features/objectTemplates/types"

const INTERACTION_TYPES = Object.keys(Interaction)
  .filter((x) => !(parseInt(x) >= 0))
  .map((intType, index) => ({
    label: intType,
    value: index.toString(),
  }))

interface Props {
  oldTemplate?: ObjectTemplate
}

const InteractionOptionsFields: FC<Props & FormikProps<ObjectTemplateFields>> = ({
  oldTemplate,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
}) => (
  <Box sx={{ maxWidth: 600 }}>
    <Box
      sx={{
        display: "flex",
        gap: 3,
        justifyContent: "flex-start",
        flexWrap: "wrap",
      }}
    >
      <Typography variant="subtitle1" sx={{ width: "100%" }}>
        Interaction Options
      </Typography>

      <Box sx={{ width: "100%" }}>
        <Box sx={{ maxWidth: 220 }}>
          <TextField
            fullWidth
            select
            label="Interaction Type"
            name="type"
            value={values.type}
            onChange={handleChange}
            error={Boolean(touched.type && errors.type)}
            helperText={touched.type && errors.type}
          >
            {INTERACTION_TYPES.map((interaction) => (
              <MenuItem key={`itype-${interaction.value}`} value={parseInt(interaction.value)}>
                <Typography sx={{ textTransform: "capitalize" }}>{interaction.label}</Typography>
              </MenuItem>
            ))}
          </TextField>
        </Box>
      </Box>

      {values.type !== Interaction.NONE && (
        <>
          <Box sx={{ maxWidth: 170 }}>
            <TextField
              fullWidth
              type="number"
              error={Boolean(touched.distThreshold && errors.distThreshold)}
              helperText={touched.distThreshold && errors.distThreshold}
              label="Dist Threshold"
              name="distThreshold"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.distThreshold}
              variant="outlined"
            />
          </Box>

          <Box sx={{ width: 400 }}>
            <TextField
              fullWidth
              error={Boolean(touched.previewMessage && errors.previewMessage)}
              helperText={touched.previewMessage && errors.previewMessage}
              label="Preview Message"
              name="previewMessage"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.previewMessage}
              variant="outlined"
            />
          </Box>
        </>
      )}

      {values.type === Interaction.EMBEDDED_WEBSITE && (
        <>
          <TextField
            fullWidth
            error={Boolean(touched.deterministicUrlPrefix && errors.deterministicUrlPrefix)}
            helperText={touched.deterministicUrlPrefix && errors.deterministicUrlPrefix}
            label="Deterministic URL Prefix"
            name="deterministicUrlPrefix"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.deterministicUrlPrefix}
            variant="outlined"
          />

          <TextField
            fullWidth
            error={Boolean(touched.url && errors.url)}
            helperText={touched.url && errors.url}
            label="URL"
            name="url"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.url}
            variant="outlined"
          />
        </>
      )}

      {values.type === Interaction.POSTER && (
        <>
          <TextField
            fullWidth
            error={Boolean(touched.blurb && errors.blurb)}
            helperText={touched.blurb && errors.blurb}
            label="Blurb"
            name="blurb"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.blurb}
            variant="outlined"
          />

          <FileUploadField
            label="Preview Image"
            name="preview"
            value={values.preview}
            previewUrl={
              oldTemplate && isTemplateType(oldTemplate, Interaction.POSTER)
                ? oldTemplate.preview
                : undefined
            }
            error={Boolean(touched.preview && (errors.preview || errors.previewFileType))}
            helperText={touched.preview && (errors.preview || errors.previewFileType)}
            onChange={(value?: Buffer, fileType?: string) => {
              setFieldValue("preview", value)
              setFieldValue("previewFileType", fileType)
            }}
            onBlur={handleBlur}
          />

          <FileUploadField
            label="Poster Image"
            name="image"
            value={values.image}
            previewUrl={
              oldTemplate && isTemplateType(oldTemplate, Interaction.POSTER)
                ? oldTemplate.image
                : undefined
            }
            error={Boolean(touched.image && (errors.image || errors.imageFileType))}
            helperText={touched.image && (errors.image || errors.imageFileType)}
            onChange={(value?: Buffer, fileType?: string) => {
              setFieldValue("image", value)
              setFieldValue("imageFileType", fileType)
            }}
            onBlur={handleBlur}
          />
        </>
      )}

      {values.type === Interaction.VIDEO && (
        <TextField
          fullWidth
          error={Boolean(touched.video && errors.video)}
          helperText={touched.video && errors.video}
          label="Video URL"
          name="video"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.video}
          variant="outlined"
        />
      )}

      {values.type === Interaction.EXTERNAL_CALL && (
        <TextField
          fullWidth
          error={Boolean(touched.zoomLink && errors.zoomLink)}
          helperText={touched.zoomLink && errors.zoomLink}
          label="Zoom Link"
          name="zoomLink"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.zoomLink}
          variant="outlined"
        />
      )}

      {values.type === Interaction.NOTE && (
        <TextField
          fullWidth
          error={Boolean(touched.message && errors.message)}
          helperText={touched.message && errors.message}
          label="Message"
          name="message"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.message}
          variant="outlined"
        />
      )}

      <Box sx={{ width: "100%", my: 1 }}>
        <Divider />
      </Box>

      <Typography variant="subtitle1" sx={{ width: "100%" }}>
        Sound Options
      </Typography>

      <Box sx={{ width: "100%" }}>
        <FileUploadField
          label="Audio"
          name="src"
          kind="audio"
          value={values.src}
          previewUrl={oldTemplate?.sound?.src}
          error={Boolean(touched.src && (errors.src || errors.srcFileType))}
          helperText={touched.src && (errors.src || errors.src)}
          onChange={(value?: Buffer, fileType?: string) => {
            setFieldValue("src", value)
            setFieldValue("srcFileType", fileType)
          }}
          onBlur={handleBlur}
        />
      </Box>

      {(values.src !== undefined || oldTemplate?.sound?.src !== undefined) && (
        <>
          <Box sx={{ maxWidth: 100 }}>
            <TextField
              fullWidth
              type="number"
              error={Boolean(touched.volume && errors.volume)}
              helperText={touched.volume && errors.volume}
              label="Volume"
              name="volume"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.volume}
              variant="outlined"
            />
          </Box>

          <Box sx={{ maxWidth: 150 }}>
            <TextField
              fullWidth
              type="number"
              error={Boolean(touched.maxDistance && errors.maxDistance)}
              helperText={touched.maxDistance && errors.maxDistance}
              label="Max Distance"
              name="maxDistance"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.maxDistance}
              variant="outlined"
            />
          </Box>

          <Box sx={{ width: "100%", display: "flex", gap: 6 }}>
            <FormControlLabel
              control={<Field type="checkbox" label="Loop" name="audioLoop" component={Switch} />}
              label="Loop"
            />

            <FormControlLabel
              control={
                <Field
                  type="checkbox"
                  label="Is Positional"
                  name="isPositional"
                  component={Switch}
                />
              }
              label="Is Positional"
            />
          </Box>
        </>
      )}
    </Box>
  </Box>
)

export default InteractionOptionsFields
