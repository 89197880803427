import { Box, Chip } from "@mui/material"
import React, { FC } from "react"

interface ChipProps {
  list: string[]
}

const ChipList: FC<ChipProps> = ({ list }) => (
  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
    {list.sort().map((item) => (
      <Chip key={item} label={item} />
    ))}
  </Box>
)

export default ChipList
