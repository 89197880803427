import styled from "@emotion/styled"
import { PauseCircle, PlayCircle } from "@mui/icons-material"
import { Box, Button, IconButton, Slider, SliderProps } from "@mui/material"
import { isArray } from "lodash"
import { DateTime } from "luxon"
import React, { FC, useCallback, useEffect, useRef, useState } from "react"
import {
  useAVClientStateViewerDispatchers,
  useCurrentStep,
  useCurrentTimestamp,
  useIsPlaying,
  useTimestamps,
} from "state/avClientStateViewerSlice"

import { Label, Pane, PaneContent, PaneHeader } from "./ui"

const useTimelinePlayer = (timestamps: number[], currentStep: number) => {
  const playerTimer = useRef<ReturnType<typeof setTimeout>>()
  const isPlaying = useIsPlaying()
  const [playSpeed, setPlaySpeed] = useState(1)

  const {
    dispatchPlaying,
    dispatchPaused,
    dispatchStepIncrement: nextStep,
  } = useAVClientStateViewerDispatchers()

  const clearTimer = useCallback(() => {
    if (playerTimer.current) {
      clearTimeout(playerTimer.current)
    }
  }, [])

  const toggleSpeed = useCallback(() => {
    setPlaySpeed((playSpeed) => (playSpeed === 32 ? 1 : playSpeed * 2))
  }, [])

  const stopPlaying = useCallback(() => {
    clearTimer()
    dispatchPaused()
  }, [clearTimer, dispatchPaused])

  const togglePlaying = useCallback(() => {
    isPlaying ? dispatchPaused() : dispatchPlaying()
  }, [isPlaying, dispatchPaused, dispatchPlaying])

  useEffect(() => {
    if (isPlaying) {
      const current = timestamps[currentStep]
      const next = timestamps[currentStep + 1]

      if (next && current) {
        const waitTime = (next - current) / playSpeed

        clearTimer()

        playerTimer.current = setTimeout(() => {
          nextStep()
        }, waitTime)
      } else {
        dispatchPaused()
      }
    }
    return () => {
      clearTimer()
    }
  }, [isPlaying, timestamps, currentStep, playSpeed, dispatchPaused, nextStep, clearTimer])

  return {
    isPlaying,
    playSpeed,
    playerTimer,
    toggleSpeed,
    stopPlaying,
    togglePlaying,
  }
}

export type TimelineProps = SliderProps & {
  label?: string
  isPlaying?: boolean
  playSpeed?: number
}

const MarkedSlider = styled(Slider)(() => ({
  "& .MuiSlider-mark ": {
    position: "absolute",
    display: "inline-block",
    width: 1,
    height: 16,
    backgroundColor: "currentColor",
    cursor: "pointer",
    "&:hover": {
      boxShadow: "0 0 0 2px rgba(58, 133, 137, 0.16)",
    },
  },
}))

const Timeline: FC<TimelineProps> = ({ ...props }) => {
  const currentTimestamp = useCurrentTimestamp()
  const timestamps = useTimestamps()
  const max = timestamps.length > 1 ? timestamps.length - 1 : 1
  const label = `@ ${DateTime.fromMillis(currentTimestamp ?? 0).toFormat("DDDD TT:SSS")}`

  const currentStep = useCurrentStep()
  const { dispatchTimelineChanged: setCurrentStep } = useAVClientStateViewerDispatchers()

  const { playSpeed, isPlaying, toggleSpeed, togglePlaying } = useTimelinePlayer(
    timestamps,
    currentStep,
  )

  const onChange = useCallback((_: unknown, value?: number | number[]) => {
    if (value !== undefined) {
      setCurrentStep(isArray(value) ? value[0] ?? 0 : value)
    }
  }, [])

  return (
    <Pane>
      <PaneHeader>
        <Label>Timeline {label}</Label>
      </PaneHeader>
      <PaneContent>
        <div className="timeline-body">
          <Box gap={1} display="flex">
            <Box display="flex">
              <Box>
                <IconButton onClick={togglePlaying}>
                  {isPlaying ? <PauseCircle /> : <PlayCircle />}
                </IconButton>
              </Box>
            </Box>
            <Box display="flex" flexDirection="column">
              <Button onClick={toggleSpeed}>{playSpeed}x</Button>
            </Box>
          </Box>
          <MarkedSlider
            value={currentStep}
            onChange={onChange}
            defaultValue={0}
            valueLabelDisplay="auto"
            step={1}
            min={0}
            max={max}
            {...props}
          />
        </div>
      </PaneContent>
    </Pane>
  )
}

export default Timeline
