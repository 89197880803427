import { initContract } from "@ts-rest/core"
import { z } from "zod"

import { HttpV2Paths, RequestMethod } from "../../httpAPI"

type Area = {
  id: string
}

const c = initContract()

export const areas = c.router({
  create: {
    method: RequestMethod.POST,
    path: HttpV2Paths.ExperimentalFloorAreas,
    body: z.object({
      capacity: z.number().int().positive().nullable(),
    }),
    responses: { 200: c.type<Area>() },
  },
})
