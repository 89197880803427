import { z } from "zod"

import { DeskInfoV2 } from "@gathertown/gather-game-common/dist/src/public/generated_DO_NOT_TOUCH/events"
import { Point } from "@gathertown/gather-game-common/dist/src/public/Position"

export enum ClaimDeskStage {
  Selecting = "Selecting",
}

export interface DeskPos {
  id: string
  coord: Point
}

// TODO: Edit this when we get rid of locked from desk schema
export const ZodDeskInfo = z.object({
  mapId: z.string(),
  deskId: z.string(),
  description: z.string().optional(),
  locked: z.boolean().optional(),
})

export type DeskInfo = z.infer<typeof ZodDeskInfo>

export interface DesksInfoV2 {
  [playerId: string]: DeskInfoV2
}

export type DeskToPlayer = {
  [mapId: string]: { [deskId: string]: string }
}
