/* External modules */
import { Buffer } from "buffer"

/* Material Icons */
import AddIcon from "@mui/icons-material/Add"
/* MUI Components */
import Avatar from "@mui/material/Avatar"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import FormHelperText from "@mui/material/FormHelperText"
import React, { ChangeEvent, FC, FocusEvent, useCallback, useMemo, useState } from "react"
import ReactAudioPlayer from "react-audio-player"
import { isValidAudio, isValidImage } from "utils/uploads"

interface Props {
  label: string
  name: string
  kind?: "image" | "audio"
  previewUrl?: string
  value?: Buffer | string
  error?: boolean
  helperText?: React.ReactNode
  onChange: (buffer?: Buffer, fileType?: string) => void
  onBlur: (e: FocusEvent<HTMLInputElement>) => void
}

const ImageUploadField: FC<Props> = ({
  onChange,
  onBlur,
  value,
  label = "Image",
  kind = "image",
  previewUrl,
  error,
  helperText,
  name,
}) => {
  const [fileType, setFileType] = useState<string | undefined>()

  const fileSrc: string | undefined = useMemo(() => {
    if (!value) return undefined

    if (value instanceof Buffer) return URL.createObjectURL(new Blob([value]))

    return value
  }, [value])

  const isImage = useMemo(() => {
    if (kind !== "image") return false

    if (value instanceof Buffer) return isValidImage(fileType ?? "")

    return value || previewUrl // last resort check
  }, [value, fileType, kind, previewUrl])

  const isAudio = useMemo(() => {
    if (kind !== "audio") return false

    if (value instanceof Buffer) return isValidAudio(fileType ?? "")

    return value || previewUrl
  }, [value, fileType, kind, previewUrl])

  const handleChange = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      const file: File | undefined = e.currentTarget.files?.[0]

      if (!file) return onChange(undefined, undefined)

      const fileData = await file.arrayBuffer()
      setFileType(file.type)
      return onChange(Buffer.from(fileData), file.type)
    },
    [onChange, setFileType],
  )

  return (
    <Box>
      <Box>
        <Button variant="contained" component="label" color="primary">
          <AddIcon />
          {value || previewUrl ? "Replace" : "Upload"} {label}
          <input hidden name={name} type="file" onChange={handleChange} onBlur={onBlur} />
          <input hidden name={`${name}FileType`} />
        </Button>

        {error && <FormHelperText error={error}>{helperText}</FormHelperText>}
      </Box>

      {isImage ? (
        <Avatar
          src={fileSrc || previewUrl}
          variant="square"
          sx={{
            height: 100,
            width: 100,
            mx: "auto",
            mt: 2,
          }}
        />
      ) : isAudio ? (
        <Box sx={{ width: "100%", mt: 2 }}>
          <ReactAudioPlayer src={fileSrc || previewUrl} controls />
        </Box>
      ) : (
        <div></div>
      )}
    </Box>
  )
}

export default ImageUploadField
