import { initContract } from "@ts-rest/core"
import { z } from "zod"

import { HttpV2Paths, RequestMethod } from "../../../httpAPI"
import { Recording } from "../../../resources/recordings"

const c = initContract()
export const recordingsContract = c.router({
  list: {
    method: RequestMethod.GET,
    path: HttpV2Paths.ZapierRecordings,
    query: z.object({
      spaceId: z.string().optional(),
      limit: z.coerce.number().optional(),
    }),
    responses: { 200: c.type<Recording[]>() },
  },
})
