import { toast } from "react-hot-toast"

import { tsrAPI } from "gather-browser-common/dist/src/public/tsRestClient"
import { getErrorMessage } from "gather-common/dist/src/public/utils"

export const listUserSpaces = async (
  userId: string,
): Promise<Awaited<ReturnType<typeof tsrAPI.admin.users.spaces.list>>> => {
  try {
    const response = await tsrAPI.admin.users.spaces.list({
      params: { userId },
    })

    return response
  } catch (error) {
    const message = getErrorMessage(error, "Failed to retrieve joined spaces for user.")
    toast.error(message)
    return Promise.reject(message)
  }
}
