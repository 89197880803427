/* External modules */
/* Material Icons */
import DotsIcon from "@mui/icons-material/MoreHoriz"
/* MUI Components */
import Avatar from "@mui/material/Avatar"
import Box from "@mui/material/Box"
import IconButton from "@mui/material/IconButton"
import Link from "@mui/material/Link"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import Typography from "@mui/material/Typography"
/* Local modules */
import Label from "components/dashboard/Label"
import { InteractionTypes } from "features/maps/types"
import React, { FC, useRef, useState } from "react"
import { Link as RouterLink } from "react-router-dom"

import { ObjectTemplate } from "gather-common/dist/src/public/resources/objectTemplates"

interface Props {
  objTemplate: ObjectTemplate
  canEdit: boolean
  onEdit?: (template: ObjectTemplate) => void
  onDelete?: (template: ObjectTemplate) => void
}

const ObjectTemplateRow: FC<Props> = ({ objTemplate, canEdit, onEdit, onDelete }) => {
  const moreRef = useRef(null)
  const [openMenu, setOpenMenu] = useState<boolean>(false)
  const { desc, name, tags, type: interactionType } = objTemplate
  const defaultVariant = objTemplate.variants?.find((variant) => variant.default === true)

  const handleMenuOpen = () => {
    setOpenMenu(true)
  }

  const handleMenuClose = () => {
    setOpenMenu(false)
  }

  const handleEdit = () => {
    setOpenMenu(false)

    if (onEdit && objTemplate.id) {
      onEdit(objTemplate)
    }
  }

  const handleDelete = () => {
    setOpenMenu(false)

    if (onDelete && objTemplate.id) {
      onDelete(objTemplate)
    }
  }

  return (
    <TableRow hover key={`objTempRow-${objTemplate.id}`}>
      <TableCell>
        {defaultVariant && <Avatar variant="square" src={defaultVariant.normal} />}
      </TableCell>

      <TableCell>
        <Box>
          <Link
            color="textPrimary"
            variant="body1"
            component={RouterLink}
            to={`/dashboard/mapmaker/objects/${objTemplate.id}`}
          >
            {name}
          </Link>
          <Typography color="textSecondary" variant="body2">
            {desc}
          </Typography>
        </Box>
      </TableCell>

      <TableCell sx={{ maxWidth: 250 }}>
        {tags.map((tag: string, index: number) => (
          <Box component="span" sx={{ mr: 1, mt: 2 }} key={`${objTemplate.id}-${tag}`}>
            <Label key={`tag-${index}`} color="primary">
              {tag}
            </Label>
          </Box>
        ))}
      </TableCell>

      <TableCell>
        <Typography color="textPrimary" variant="body1">
          {InteractionTypes[interactionType]}
        </Typography>
      </TableCell>

      {canEdit && (onEdit || onDelete) && (
        <TableCell>
          <IconButton color="inherit" edge="end" onClick={handleMenuOpen} ref={moreRef}>
            <DotsIcon fontSize="small" />
          </IconButton>

          <Menu
            anchorEl={moreRef.current}
            anchorOrigin={{
              horizontal: "center",
              vertical: "bottom",
            }}
            keepMounted
            onClose={handleMenuClose}
            open={openMenu}
          >
            {onEdit && <MenuItem onClick={handleEdit}>Edit</MenuItem>}
            {onDelete && <MenuItem onClick={handleDelete}>Delete</MenuItem>}
          </Menu>
        </TableCell>
      )}
    </TableRow>
  )
}

export default ObjectTemplateRow
