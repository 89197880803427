import { tsrAPI } from "gather-browser-common/dist/src/public/tsRestClient"
import { getErrorMessage } from "gather-common/dist/src/public/utils"

type ArgBody = {
  title: string
  body: string
}

export const createUserNotifications: (userId: string, body: ArgBody) => Promise<void> = async (
  userId,
  { title, body },
): Promise<void> => {
  try {
    await tsrAPI.admin.users.notifications.create({
      params: {
        userId,
      },
      body: { title, body },
    })
  } catch (error) {
    throw new Error(getErrorMessage(error, "Failed to fetch user devices"))
  }
}
