import Masonry from "@mui/lab/Masonry"
import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import CardHeader from "@mui/material/CardHeader"
import CardMedia from "@mui/material/CardMedia"
import Divider from "@mui/material/Divider"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import Typography from "@mui/material/Typography"
import BooleanLabel from "components/dashboard/BooleanLabel"
import DetailsCard from "components/dashboard/DetailsCard"
import TableCellTitle from "components/dashboard/TableCellTitle"
import React, { FC } from "react"

import { AdminSpaceMap } from "gather-http-common/dist/src/public/spaces"
import { displayDate } from "../../../../../../utils/dateUtils"

interface Props {
  map?: AdminSpaceMap
}

const GeneralTab: FC<Props> = ({ map }) => (
  <Box sx={{ mt: 2 }}>
    <Masonry columns={2} spacing={3}>
      <DetailsCard header="Details">
        <Table>
          <TableBody>
            <TableRow>
              <TableCellTitle>ID</TableCellTitle>
              <TableCell>
                <Typography variant="body2" color="textPrimary">
                  {map?.id}
                </Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCellTitle>Name</TableCellTitle>
              <TableCell>
                <Typography variant="body2" color="textPrimary">
                  {map?.name}
                </Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCellTitle>Description</TableCellTitle>
              <TableCell>
                <Typography variant="body2" color="textPrimary">
                  {map?.description}
                </Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCellTitle>Updated At</TableCellTitle>
              <TableCell>
                <Typography variant="body2" color="textPrimary">
                  {displayDate(map?.updatedAt) || "Never"}
                </Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCellTitle>Most Recent Update ID</TableCellTitle>
              <TableCell>
                <Typography variant="body2" color="textPrimary">
                  {map?.mostRecentUpdateId || "N/A"}
                </Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCellTitle>Dimensions</TableCellTitle>
              <TableCell>
                <Typography variant="body2" color="textPrimary">
                  {map?.dimensions[0]} x {map?.dimensions[1]}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </DetailsCard>

      <DetailsCard header="Settings">
        <Table>
          <TableBody>
            <TableRow>
              <TableCellTitle>Spawn</TableCellTitle>
              <TableCell>
                {map?.spawn && (
                  <Typography variant="body2" color="textPrimary">
                    ({map?.spawn?.x}, {map?.spawn?.y})
                  </Typography>
                )}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCellTitle>Use Drawn BG?</TableCellTitle>
              <TableCell>
                <BooleanLabel condition={!!map?.useDrawnBG} />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCellTitle>Enabled Chats</TableCellTitle>
              <TableCell>
                <List sx={{ pt: 0, pb: 0, mb: 0 }}>
                  {map?.enabledChats?.map((chat) => (
                    <ListItem disableGutters sx={{ pt: 0 }} key={chat}>
                      {chat}
                    </ListItem>
                  ))}
                </List>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCellTitle>Decoration</TableCellTitle>
              <TableCell>
                <Typography variant="body2" color="textPrimary">
                  {map?.description}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </DetailsCard>

      {map?.backgroundImagePath && (
        <Card variant="outlined">
          <CardHeader title="Background Image" />
          <Divider />
          <CardMedia
            image={map?.backgroundImagePath}
            sx={{
              height: 200,
            }}
          />
          <Divider />
          <Table>
            <TableBody>
              <TableRow>
                <TableCellTitle>Background Image Path</TableCellTitle>
                <TableCell>
                  <Typography variant="body2" color="textPrimary" sx={{ wordBreak: "break-all" }}>
                    {map?.backgroundImagePath}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Card>
      )}

      {map?.foregroundImagePath && (
        <Card variant="outlined">
          <CardHeader title="Foreground Image" />
          <CardMedia
            image={map?.foregroundImagePath}
            sx={{
              height: 200,
            }}
          />
          <Table>
            <TableBody>
              <TableRow>
                <TableCellTitle>Foreground Image Path</TableCellTitle>
                <TableCell>
                  <Typography variant="body2" color="textPrimary" sx={{ wordBreak: "break-all" }}>
                    {map?.foregroundImagePath}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Card>
      )}

      {map?.miniMapImagePath && (
        <Card variant="outlined">
          <CardHeader title="Mini Map Image" />
          <CardMedia
            image={map?.miniMapImagePath}
            sx={{
              height: 200,
            }}
          />
          <Table>
            <TableBody>
              <TableRow>
                <TableCellTitle>Mini Map Image Path</TableCellTitle>
                <TableCell>
                  <Typography variant="body2" color="textPrimary" sx={{ wordBreak: "break-all" }}>
                    {map?.miniMapImagePath}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Card>
      )}
    </Masonry>
  </Box>
)

export default GeneralTab
