import { TabPanel as DefaultTabPanel } from "@mui/lab"
import { styled } from "@mui/material"

export const Label = styled("div")({
  display: "inline-block",
  padding: "16px 16px",
})

export const Pane = styled("div")({
  display: "flex",
  flexDirection: "column",
  background: "var(--container)",
  flexGrow: 1,
})

export const PaneContent = styled("div")({
  height: "100%",
  flexGrow: 1,
})

export const PaneHeader = styled("div")({
  borderBottom: "1px solid var(--divider)",
})

export const TabPanel = styled(DefaultTabPanel)({
  padding: 0,
  height: "100%",
  width: "100%",
})

export const SplitPane = styled("div")({
  display: "flex",
  flexGrow: 1,
  gap: 1,
})
