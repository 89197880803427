import { initContract } from "@ts-rest/core"

import { HttpV2Paths, RequestMethod } from "../../httpAPI"
import { AdminSpace } from "../../spaces"

const c = initContract()

export const spaces = c.router({
  get: {
    method: RequestMethod.GET,
    path: HttpV2Paths.AdminSpace,
    responses: {
      200: c.type<AdminSpace>(),
    },
  },
  toggleStaffAccess: {
    method: RequestMethod.PATCH,
    path: HttpV2Paths.AdminSpace,
    body: c.noBody(),
    responses: {
      200: c.noBody(),
    },
  },
})
