import CloseIcon from "@mui/icons-material/Close"
import Masonry from "@mui/lab/Masonry"
import AppBar from "@mui/material/AppBar"
import Avatar from "@mui/material/Avatar"
import Box from "@mui/material/Box"
import Dialog from "@mui/material/Dialog"
import IconButton from "@mui/material/IconButton"
import SlideTransition from "@mui/material/Slide"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import DetailsCard from "components/dashboard/DetailsCard"
import Label from "components/dashboard/Label"
import TableCellTitle from "components/dashboard/TableCellTitle"
import { InteractionTypes } from "features/maps/types"
import React, { FC } from "react"

import { MapObject as ObjectDB } from "gather-http-common/dist/src/public/spaces"

interface Props {
  open: boolean
  obj?: ObjectDB
  onClose: () => void
}

const ObjectInstanceModal: FC<Props> = ({ open, onClose, obj }) => {
  const showProperties = obj && obj.properties && Object.keys(obj.properties).length > 0
  const showSound = obj && obj.sound && Object.keys(obj.sound).length > 0

  return (
    <Dialog
      fullScreen
      onClose={onClose}
      open={open}
      TransitionComponent={SlideTransition}
      PaperProps={{
        sx: {
          backgroundColor: "background.default",
        },
      }}
    >
      {open && obj && (
        <>
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Object Instance Details
              </Typography>
            </Toolbar>
          </AppBar>

          <Box
            sx={{
              p: 3,
              backgroundColor: "background.default",
              height: "100%",
              minHeight: "calc(100% - 64px)",
            }}
          >
            <Masonry columns={3} spacing={3}>
              <DetailsCard header="General Info">
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCellTitle>ID</TableCellTitle>
                      <TableCell>
                        <Typography color="textPrimary" variant="body2">
                          {obj.id}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCellTitle>TemplateID</TableCellTitle>
                      <TableCell>
                        <Typography color="textPrimary" variant="body2">
                          {obj.templateId}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCellTitle>Name</TableCellTitle>
                      <TableCell>
                        <Typography color="textPrimary" variant="body2">
                          {obj._name}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCellTitle>ObjectPlacerID</TableCellTitle>
                      <TableCell>
                        <Typography color="textPrimary" variant="body2">
                          {obj.objectPlacerId}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCellTitle>Tags</TableCellTitle>
                      <TableCell>
                        {obj._tags?.map((tag: string, index: number) => (
                          <Box component="span" sx={{ mr: 1, mt: 2 }} key={tag}>
                            <Label key={`tag-${index}`} color="primary">
                              {tag}
                            </Label>
                          </Box>
                        ))}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </DetailsCard>

              <DetailsCard header="Interaction">
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCellTitle>Type</TableCellTitle>
                      <TableCell>
                        <Typography color="textPrimary" variant="body2">
                          {InteractionTypes[obj.type]}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCellTitle>DistThreshold</TableCellTitle>
                      <TableCell>
                        <Typography color="textPrimary" variant="body2">
                          {obj.distThreshold}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCellTitle>PreviewMessage</TableCellTitle>
                      <TableCell>
                        <Typography color="textPrimary" variant="body2">
                          {obj.previewMessage}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </DetailsCard>

              <DetailsCard header="Positioning">
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCellTitle>Width</TableCellTitle>
                      <TableCell>
                        <Typography color="textPrimary" variant="body2">
                          {obj.width}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCellTitle>Height</TableCellTitle>
                      <TableCell>
                        <Typography color="textPrimary" variant="body2">
                          {obj.height}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCellTitle>x</TableCellTitle>
                      <TableCell>
                        <Typography color="textPrimary" variant="body2">
                          {obj.x}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCellTitle>y</TableCellTitle>
                      <TableCell>
                        <Typography color="textPrimary" variant="body2">
                          {obj.y}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCellTitle>OffsetX</TableCellTitle>
                      <TableCell>
                        <Typography color="textPrimary" variant="body2">
                          {obj.offsetX}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCellTitle>OffsetY</TableCellTitle>
                      <TableCell>
                        <Typography color="textPrimary" variant="body2">
                          {obj.offsetY}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </DetailsCard>

              <DetailsCard header="Variant">
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCellTitle>Color</TableCellTitle>
                      <TableCell>
                        {obj.color && (
                          <Box sx={{ display: "flex", alignItems: "baseline", gap: 1 }}>
                            <Box
                              sx={{
                                bgcolor: obj.color,
                                width: 14,
                                height: 14,
                                border: "solid 1px white",
                                borderRadius: "3px",
                              }}
                            />
                            <Typography color="textPrimary" variant="body2">
                              {obj.color}
                            </Typography>
                          </Box>
                        )}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCellTitle>Orientation</TableCellTitle>
                      <TableCell>
                        <Typography color="textPrimary" variant="body2">
                          {obj.orientation}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCellTitle>Normal</TableCellTitle>
                      <TableCell>
                        {obj.normal && (
                          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                            <Avatar variant="square" src={obj.normal} />
                            <Typography color="textPrimary" variant="body2">
                              {obj.normal}
                            </Typography>
                          </Box>
                        )}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCellTitle>Highlighted</TableCellTitle>
                      <TableCell>
                        {obj.highlighted && (
                          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                            <Avatar variant="square" src={obj.highlighted} />
                            <Typography color="textPrimary" variant="body2">
                              {obj.highlighted}
                            </Typography>
                          </Box>
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </DetailsCard>

              {showProperties && (
                <DetailsCard header="Properties">
                  <Table>
                    <TableBody>
                      {obj.properties &&
                        Object.keys(obj.properties).map((key) => (
                          <TableRow key={key}>
                            <TableCellTitle>
                              <Box sx={{ textTransform: "capitalize" }}>{key}</Box>
                            </TableCellTitle>

                            <TableCell>
                              {obj.properties && obj.properties[key] && (
                                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                  {(obj.properties[key].includes(".png") ||
                                    obj.properties[key].includes(".jpg") ||
                                    obj.properties[key].includes(".jpeg") ||
                                    obj.properties[key].includes(".gif")) && (
                                    <Avatar variant="square" src={obj.properties[key]} />
                                  )}
                                  <Typography
                                    color="textPrimary"
                                    variant="body2"
                                    sx={{ wordBreak: "break-all" }}
                                  >
                                    {obj.properties[key]}
                                  </Typography>
                                </Box>
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </DetailsCard>
              )}

              {showSound && (
                <DetailsCard header="Sound">
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCellTitle>Src</TableCellTitle>
                        <TableCell>
                          <Typography color="textPrimary" variant="body2">
                            {obj.sound?.src}
                          </Typography>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCellTitle>Volume</TableCellTitle>
                        <TableCell>
                          <Typography color="textPrimary" variant="body2">
                            {obj.sound?.volume}
                          </Typography>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCellTitle>Loop</TableCellTitle>
                        <TableCell>
                          <Typography color="textPrimary" variant="body2">
                            {obj.sound?.loop}
                          </Typography>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCellTitle>MaxDistance</TableCellTitle>
                        <TableCell>
                          <Typography color="textPrimary" variant="body2">
                            {obj.sound?.maxDistance}
                          </Typography>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCellTitle>IsPositional</TableCellTitle>
                        <TableCell>
                          <Typography color="textPrimary" variant="body2">
                            {obj.sound?.isPositional}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </DetailsCard>
              )}

              <DetailsCard header="Miscellaneous">
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCellTitle>ExtensionClass</TableCellTitle>
                      <TableCell>
                        <Typography color="textPrimary" variant="body2">
                          {obj.extensionClass}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCellTitle>CustomState</TableCellTitle>
                      <TableCell>
                        <Typography color="textPrimary" variant="body2">
                          {obj.customState}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCellTitle>NumGoKarts</TableCellTitle>
                      <TableCell>
                        <Typography color="textPrimary" variant="body2">
                          {obj.numGoKarts}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </DetailsCard>
            </Masonry>
          </Box>
        </>
      )}
    </Dialog>
  )
}

export default ObjectInstanceModal
