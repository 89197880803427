import { initContract } from "@ts-rest/core"

import { HttpV2Paths, RequestMethod } from "../../../httpAPI"
import { CalendarData } from "../../../resources/calendars"

const c = initContract()

export const calendars = c.router({
  list: {
    method: RequestMethod.GET,
    path: HttpV2Paths.SpaceUserCalendars,
    responses: { 200: c.type<CalendarData>() },
  },
})
