import { Buffer } from "buffer"

import { getAndAssertSpaceIdFromPath } from "../../public/pathUtils"
import { tsrAPI } from "../../tsRestClient"

export const createFileImage = async (body: {
  bytes: Buffer
  spaceId?: string
  eventId?: string
  isEvent?: boolean
}) =>
  await tsrAPI.files.images.create({
    body: {
      ...body,
      spaceId: body.spaceId ?? getAndAssertSpaceIdFromPath(),
    },
  })
