import { initContract } from "@ts-rest/core"

import { HttpV2Paths, RequestMethod } from "../../httpAPI"
import { SpaceSSO, SpaceSSOSetup } from "../../resources/sso"

const c = initContract()

export const sso = c.router({
  get: {
    method: RequestMethod.GET,
    path: HttpV2Paths.SpaceSSO,
    responses: { 200: c.type<SpaceSSO | null>() },
  },
  create: {
    method: RequestMethod.POST,
    path: HttpV2Paths.SpaceSSO,
    body: c.noBody(),
    responses: { 200: c.type<SpaceSSOSetup>() },
  },
})
