import CssBaseline from "@mui/material/CssBaseline"
import { ThemeProvider } from "@mui/material/styles"
import SplashScreen from "components/SplashScreen"
import useAuth from "features/authentication/useAuth"
import useSettings from "features/settings/useSettings"
import React, { FC, useEffect } from "react"
import { Toaster } from "react-hot-toast"
import { QueryClient, QueryClientProvider } from "react-query"
import { Provider } from "react-redux"
import { useRoutes } from "react-router-dom"
import routes from "routes/index"
import { store } from "state/store"
import { createCustomTheme } from "theme/index"

import {
  AxiosClientEvent,
  axiosClientEventEmitter,
} from "gather-http-v2-client/dist/src/public/axiosClientEventEmitter"

const queryClient = new QueryClient()

const App: FC = () => {
  const content = useRoutes(routes)
  const { settings } = useSettings()
  const auth = useAuth()

  useEffect(
    () =>
      axiosClientEventEmitter.addEventListener(
        AxiosClientEvent.SSOAuthenticationFailed,
        async () => {
          await auth.logout()
          window.location.href = "/login?expired=1"
        },
      ),
    [auth],
  )

  const theme = createCustomTheme({
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme,
  })

  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Toaster position="top-center" />
          {auth.isInitialized ? content : <SplashScreen />}
        </ThemeProvider>
      </QueryClientProvider>
    </Provider>
  )
}

export default App
