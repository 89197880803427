import { Buffer } from "buffer"

import { tsrAPI } from "gather-browser-common/dist/src/public/tsRestClient"

/**
 * API request to upload wearables
 * @param fileBuffer - buffer of file to be uploaded
 * @returns string promise
 */
export const createWearableImages = async (fileBuffer: Buffer): Promise<string> => {
  try {
    const res = await tsrAPI.admin.wearableImages.create({
      body: { bytes: fileBuffer },
    })

    return res
  } catch (e) {
    let message = "Failed to upload wearable image."

    if (e instanceof Error) {
      message = e.message
    }

    return Promise.reject(message)
  }
}
/**
 * API request to upload wearables
 * @param src - form body of wearables being uploaded
 * @returns string promise
 */
export const uploadWearableImageFromRelativePath = async (src: string): Promise<string> => {
  const response = await fetch(src)
  const fileData = await response.arrayBuffer()
  const fileBuffer = Buffer.from(fileData)

  return createWearableImages(fileBuffer)
}
