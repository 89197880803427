import { z } from "zod"

/** @deprecated: use stringified SSOAuthCallbackStateObj: https://linear.app/gather-town/issue/APP-6366/deprecate-origin-app  */
export const SSO_AUTH_CALLBACK_ORIGIN_APP_STATE = "origin_app"

export enum SSOCallbackStateSource {
  WebApp = "WebApp",
  OutlookApp = "OutlookApp",
  MobileApp = "MobileApp",
  Unknown = "Unknown",
}

export type SSOAuthCallbackStateObj = {
  isInMicrosoftOfficeEnvironment?: boolean
  origin?: string
  source: SSOCallbackStateSource
}

// this is the JSON stringified version of the state sent over the wire
export type SSOAuthCallbackRawState = typeof SSO_AUTH_CALLBACK_ORIGIN_APP_STATE | string | null

export type SSOAuthCallbackParsedState =
  | typeof SSO_AUTH_CALLBACK_ORIGIN_APP_STATE
  | SSOAuthCallbackStateObj
  | null

export const zodSSOCallbackStateObj = z.object({
  isInMicrosoftOfficeEnvironment: z.boolean().optional(),
  origin: z.string().max(300).optional(),
  source: z.nativeEnum(SSOCallbackStateSource),
})

export const parseSSOAuthCallbackState = (
  rawState: SSOAuthCallbackRawState,
): SSOAuthCallbackParsedState => {
  // SSO_AUTH_CALLBACK_ORIGIN_APP_STATE will be deprecated
  if (rawState === SSO_AUTH_CALLBACK_ORIGIN_APP_STATE || rawState === null) return rawState

  try {
    return zodSSOCallbackStateObj.parse(JSON.parse(rawState))
  } catch {
    return { source: SSOCallbackStateSource.Unknown }
  }
}

export const isSSOAuthCallbackStateFromInternalSource = (state: SSOAuthCallbackParsedState) =>
  state === SSO_AUTH_CALLBACK_ORIGIN_APP_STATE || (state !== null && "source" in state)

export const getGoogleOauthRedirectUrlFromApiBase = () =>
  process.env.API_BASE_PATH && process.env.GOOGLE_OAUTH_SIGNIN_PATH
    ? // If using ngrok, set the redirect URL origin to the forwarded address for localhost:3000.
      // For more info, see: https://www.notion.so/gathertown/Google-auth-addin-dev-d6c87e34dfb24751acbc703fc7160b67
      `${process.env.API_BASE_PATH}${process.env.GOOGLE_OAUTH_SIGNIN_PATH}`
    : undefined
