import { initContract } from "@ts-rest/core"
import { z } from "zod"

import { Outfit, Wearable, WearableType } from "gather-common/dist/src/public/resources/users"
import { HttpV2Paths, RequestMethod } from "../../httpAPI"
import { zodUuid } from "../../zodHelpers"

const c = initContract()

export type UserOutfitResource = Outfit

// TODO [rebuild] dedupe `ZodDBOutfit`
export const WearablesSchema = z.object({
  skin: z.string(),
  hair: z.string(),
  facial_hair: z.string(),
  top: z.string(),
  bottom: z.string(),
  shoes: z.string(),
  hat: z.string(),
  glasses: z.string(),
  other: z.string(),
  costume: z.union([z.string(), z.undefined()]).optional(),
  mobility: z.union([z.string(), z.undefined()]).optional(),
  jacket: z.union([z.string(), z.undefined()]).optional(),
})

export const wearables = c.router({
  getDefault: {
    method: RequestMethod.GET,
    path: HttpV2Paths.UserDefaultWearables,
    query: z.object({
      color: z.string().optional(),
      type: z.nativeEnum(WearableType).optional(),
      styles: z.array(z.string()).optional(),
    }),
    responses: { 200: c.type<Wearable[]>() },
  },
  getInitial: {
    method: RequestMethod.GET,
    path: HttpV2Paths.UserInitialOutfit,
    query: z.object({ styles: z.array(z.string()).optional() }),
    responses: { 200: c.type<UserOutfitResource>() },
  },

  list: {
    method: RequestMethod.GET,
    path: HttpV2Paths.UserWearables,
    query: z.object({
      spaceId: zodUuid.optional(),
    }),
    responses: { 200: c.type<UserOutfitResource>() },
  },
  create: {
    method: RequestMethod.POST,
    path: HttpV2Paths.UserWearables,
    body: z.object({
      wearables: WearablesSchema,
    }),
    responses: { 200: c.noBody() },
  },
})
