import diff from "microdiff"
import { aperture } from "ramda"

import { Differences, timestampPlayerInfo, timestampSelfInfo } from "./types"
import { stripDetails } from "./utils"

const emptySnapshot = [-1, {}]

export const calculateDiff = (
  snapshots: timestampPlayerInfo[] | timestampSelfInfo[],
): Record<number, Differences> =>
  Object.fromEntries(
    aperture(2, [emptySnapshot, ...snapshots]).map(([prevSnapshot, currentSnapshot]) => [
      currentSnapshot[0],
      diff(stripDetails(prevSnapshot[1]), stripDetails(currentSnapshot[1])),
    ]),
  )
