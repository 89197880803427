import {
  ChevronRightRounded as ChevronRightIcon,
  HomeRounded as HomeIcon,
  SvgIconComponent,
} from "@mui/icons-material"
import { Box, Breadcrumbs as MaterialBreadcrumbs, Typography } from "@mui/material"
import React, { FC } from "react"

import BreadcrumbLink from "./BreadcrumbLink"

export interface BreadcrumbTrail {
  to: string
  title: string
  startIcon?: SvgIconComponent
}
interface Props {
  trail?: BreadcrumbTrail[]
  pageTitle: string
}

const Breadcrumbs: FC<Props> = ({ trail, pageTitle }) => (
  <Box sx={{ px: 3 }}>
    <MaterialBreadcrumbs
      aria-label="breadcrumb"
      separator={<ChevronRightIcon fontSize="small" />}
      sx={{ mt: 2 }}
    >
      <BreadcrumbLink to="/dashboard" title="Dashboard" StartIcon={HomeIcon} />

      {trail &&
        trail.map((breadcrumb, index) => (
          <BreadcrumbLink
            key={`breadcrumb-${index}`}
            to={breadcrumb.to}
            title={breadcrumb.title}
            StartIcon={breadcrumb.startIcon}
          />
        ))}

      <Typography color="textSecondary" variant="subtitle2">
        {pageTitle}
      </Typography>
    </MaterialBreadcrumbs>
  </Box>
)

export default Breadcrumbs
