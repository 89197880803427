import { MapObject as ObjectDB } from "gather-http-common/dist/src/public/spaces"

export const applyInteractiveFilter: (mapObjects: ObjectDB[]) => ObjectDB[] = (
  mapObjects,
): ObjectDB[] => mapObjects.filter((obj) => obj.type > 0)

// Input: "Whiteboard - tOsOBPYRTh6x_dMv_tzFn"
// Output: "tOsOBPYRTh6x_dMv_tzFn"
export const parseTemplateId = (id?: string): string | undefined => {
  const split = id?.split(" - ") || [id]

  if (split.length === 1) return id

  return split[1]
}

export const getObjectNanoID: (obj: ObjectDB) => string = (obj): string => {
  const templateId = parseTemplateId(obj.templateId) ?? "undefined"
  const id = (obj.id ?? "undefined").replace(`${templateId.toString()}_`, "").split(" - ")
  const tempId = ((id[1] ?? id[0]) || "").split("_")

  return tempId[tempId.length - 1] || ""
}
