import { isNil } from "ramda"
import { Tagged } from "type-fest"

import { uuid, validate as uuidValidate } from "gather-common-including-video/dist/src/public/uuid"

export const getEmailParts = (email: string): string[] => email.split("@")

export const getFirstNumberInString = (value: string): number | null => {
  const matches = value.match(/\d+/)

  if (matches) return parseInt(matches[0], 10)

  return null
}

export const replaceAt = (str: string, index: number, replacement: string): string => {
  // we could make `index: NonNegativeInteger<T>` but then it's less convenient DX, and the risk
  // here seems pretty low; better to do a runtime check, so we don't have to fight with TS
  if (index < 0) throw new Error("Index must be non-negative")

  return str.slice(0, index) + replacement + str.slice(index + replacement.length)
}

export const sortByFirstNumberInStringsComparator = (a: string, b: string): number => {
  const valueA = getFirstNumberInString(a)
  const valueB = getFirstNumberInString(b)

  if (valueA === null && valueB === null) return a.localeCompare(b)
  if (valueA === null) return 1
  if (valueB === null) return -1

  return valueA - valueB
}

export function sanitizeString(inputString: string): string {
  return inputString.replace(/[^A-Za-z0-9_-]+/g, "-")
}

export function truncateString(inputString: string, maxLength: number): string {
  if (inputString.length <= maxLength) return inputString

  return `${inputString.substring(0, maxLength - 3)}...`
}

export const validateSpaceId = (id: string): boolean => uuidValidate(id)

export type Uuid = Tagged<string, "Uuid">

export function asUuid(id: undefined): undefined
export function asUuid(id: null): null
export function asUuid(id: string): Uuid
// we need to handle Partial because that comes from factories, and we need to manually cast it back
export function asUuid(id: Partial<Uuid>): Uuid
export function asUuid(id: string | null): Uuid | null
export function asUuid(id: string | undefined): Uuid | undefined
export function asUuid(id: string | Partial<Uuid> | undefined | null): Uuid | undefined | null {
  if (isNil(id)) return id
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  if (validateSpaceId(id as string)) {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return id as Uuid
  }

  throw new Error(`String "${id}" is not a uuid.`)
}

export function asUuids(ids: string[]): Uuid[] {
  return ids.map((id) => asUuid(id))
}

export const typedUuid = () => asUuid(uuid())
