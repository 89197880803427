import { initContract } from "@ts-rest/core"
import { z } from "zod"

import { zodSSOCallbackStateObj } from "../../auth"
import { HttpV2Paths, RequestMethod } from "../../httpAPI"
import { SSOTokenInfo } from "../../resources/sso"

const c = initContract()

export const sso = c.router({
  initiate: {
    method: RequestMethod.POST,
    path: HttpV2Paths.AuthSSORequests,
    body: z.object({
      email: z.string().email(),
      redirectUrl: z.string().optional(),
      state: zodSSOCallbackStateObj.optional(),
    }),
    responses: { 200: c.type<{ authorizationUrl: string }>() },
  },

  swapToken: {
    method: RequestMethod.GET,
    path: HttpV2Paths.AuthSSOTokenSwap,
    query: z.object({
      code: z.string(),
    }),
    responses: { 200: c.type<SSOTokenInfo>() },
  },

  members: {
    get: {
      method: RequestMethod.GET,
      path: HttpV2Paths.AuthSSOUserIsMemberOfActiveOrganizationByEmail,
      query: z.object({
        email: z.string(),
      }),
      responses: { 200: c.type<{ isMember: boolean }>() },
    },
  },
})
