import { Box } from "@mui/material"
import { nth, pipe, split } from "ramda"
import React, { FC, memo } from "react"

import { just } from "gather-common-including-video/dist/src/public/fpHelpers"
import { RenderRowProps, WithRestProps } from "./types"
import { formatMultiArgumentPayloads, formatTimestamp, isActionMessage } from "./utils"

const getActionName = pipe(split("/"), nth(1), just)

export const RenderActionRow: FC<WithRestProps<RenderRowProps>> = memo(
  ({ message, className = "", ...props }) => {
    if (!isActionMessage(message)) return null
    const { payload, type, timestamp } = message

    return (
      <div
        className={`row-message action-message action-message-${getActionName(type)} ${className}`}
        {...props}
      >
        <Box flexShrink={0}>{formatTimestamp(timestamp)}</Box>
        <div>
          <div className="action-message-container">
            <div className="acton-message-name">{getActionName(type)}</div>
            <div>{formatMultiArgumentPayloads(payload)}</div>
          </div>
        </div>
      </div>
    )
  },
)
