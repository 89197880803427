import QueryKeys from "features/queryKeys"
import { useMutation, useQueryClient } from "react-query"

import { ObjectTemplate } from "gather-common/dist/src/public/resources/objectTemplates"
import * as ObjectTemplatesAPI from "../../../api/objectTemplates"
import {
  ObjectTemplateUpdateVariables,
  ObjTempMutationCallbacks,
  ObjTempMutationContext,
} from "./types"

/**
 * Mutation hook that updates an object template
 * @param callbacks - callback functions to be used in the later lifecycle steps of the mutation
 * @returns ReactQuery useMutation hook
 */
const useUpdateObjectTemplate = (callbacks?: ObjTempMutationCallbacks) => {
  const queryClient = useQueryClient()

  return useMutation(
    (variables: ObjectTemplateUpdateVariables) =>
      ObjectTemplatesAPI.updateTemplate(variables.templateId, variables.fields),
    {
      onMutate: async (variables) => {
        const { templateId, fields } = variables

        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries([QueryKeys.ObjectTemplate, templateId])
        await queryClient.cancelQueries([QueryKeys.ObjectTemplate])

        // Snapshot the previous values that are currently cached
        const previousTemplates =
          queryClient.getQueryData<ObjectTemplate[]>([QueryKeys.ObjectTemplates]) ?? []
        const previousTemplate = queryClient.getQueryData<ObjectTemplate>([
          QueryKeys.ObjectTemplate,
          templateId,
        ])

        if (!previousTemplate) {
          throw new Error(
            "Previous template doesn't exist - please contact the Platform Tools team about how you received this error",
          )
        }

        // Not too worried about validation or anything here. This is just for the optimistic
        // interface. This makes sure that the info being displayed on the index table is correct
        // until the data finishes re-fetching upon updating this record.
        const updatedItem = {
          id: previousTemplate?.id,
          desc: fields.desc ?? previousTemplate.desc,
          name: fields.name ?? previousTemplate.name,
          tags: fields.tags ?? previousTemplate.tags,
          type: fields.type !== undefined ? fields.type : previousTemplate.type,
          text: fields.text ?? previousTemplate.text,
          variants: previousTemplate.variants,
        }

        // Find the old item in the list and replace it
        if (previousTemplates) {
          const index = previousTemplates.findIndex(
            (template) => template.id === previousTemplate.id,
          )
          const updatedList = [...previousTemplates]
          updatedList[index] = updatedItem

          queryClient.setQueryData<ObjectTemplate[]>([QueryKeys.ObjectTemplates], updatedList)
        }

        queryClient.setQueryData<ObjectTemplate>(
          [QueryKeys.ObjectTemplate, templateId],
          updatedItem,
        )

        return {
          previousTemplates,
          previousTemplate,
        }
      },

      onError: async (_err, variables, context?: ObjTempMutationContext) => {
        const { previousTemplates, previousTemplate } = context || {}
        const { templateId } = variables

        if (previousTemplates) {
          queryClient.setQueryData<ObjectTemplate[]>([QueryKeys.ObjectTemplates], previousTemplates)
        }

        if (previousTemplate) {
          queryClient.setQueryData<ObjectTemplate>(
            [QueryKeys.ObjectTemplate, templateId],
            previousTemplate,
          )
        }

        callbacks?.onError?.()
      },

      onSuccess: () => {
        callbacks?.onSuccess?.()
      },

      onSettled: async (_data, _err, variables) => {
        const { templateId } = variables
        await queryClient.invalidateQueries([QueryKeys.ObjectTemplates])
        await queryClient.invalidateQueries([QueryKeys.ObjectTemplate, templateId])
      },
    },
  )
}

export default useUpdateObjectTemplate
