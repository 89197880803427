import { tsrAPI } from "gather-browser-common/dist/src/public/tsRestClient"
import { getErrorMessage } from "gather-common/dist/src/public/utils"
import { UserDevice } from "gather-prisma-types/dist/src/public/client"

export const listUserDeviceTokens: (userId: string) => Promise<UserDevice[]> = async (
  userId: string,
): Promise<Awaited<ReturnType<typeof tsrAPI.admin.users.devices.list>>> => {
  try {
    const devices = await tsrAPI.admin.users.devices.list({
      params: {
        userId,
      },
    })

    return devices ?? []
  } catch (error) {
    throw new Error(getErrorMessage(error, "Failed to fetch user devices"))
  }
}
