import Typography from "@mui/material/Typography"
import { PaletteKey } from "components/types"
import React, { FC } from "react"

interface Props {
  children: string | React.ReactNode
  color?: PaletteKey
}

const Label: FC<Props> = ({ color = "primary", children }) => (
  <Typography
    sx={{
      alignItems: "center",
      backgroundColor: (theme) => theme.palette[color].main,
      borderRadius: (theme) => theme.shape.borderRadius,
      color: (theme) => theme.palette[color].contrastText,
      cursor: "default",
      display: "inline-flex",
      flexGrow: 0,
      flexShrink: 0,
      fontFamily: (theme) => theme.typography.fontFamily,
      fontSize: (theme) => theme.typography.pxToRem(11),
      fontWeight: (theme) => theme.typography.fontWeightBold,
      justifyContent: "center",
      letterSpacing: 0.5,
      minWidth: 20,
      marginBottom: (theme) => theme.spacing(1),
      paddingBottom: (theme) => theme.spacing(0.5),
      paddingLeft: (theme) => theme.spacing(1),
      paddingRight: (theme) => theme.spacing(1),
      paddingTop: (theme) => theme.spacing(0.5),
      textTransform: "uppercase",
      whiteSpace: "nowrap",
    }}
  >
    {children}
  </Typography>
)

export default Label
