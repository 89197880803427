import { initContract } from "@ts-rest/core"
import { z } from "zod"

import { HttpV2Paths, RequestMethod } from "../../../httpAPI"
import { SpaceUserSettingResponse } from "../../../resources/spaceUserSettings"
import { zodUuid } from "../../../zodHelpers"

const c = initContract()

export const setting = c.router({
  get: {
    method: RequestMethod.GET,
    path: HttpV2Paths.SpaceUserSettings,
    responses: { 200: c.type<SpaceUserSettingResponse>() },
  },
  update: {
    method: RequestMethod.PATCH,
    path: HttpV2Paths.SpaceUserSettings,
    body: z.object({
      pinnedUsers: z.array(zodUuid),
    }),
    responses: { 200: c.type<SpaceUserSettingResponse>() },
  },
})
