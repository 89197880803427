import { tsrAPI } from "gather-browser-common/dist/src/public/tsRestClient"
import { getErrorMessage } from "gather-common/dist/src/public/utils"
import { AdminSpace } from "gather-http-common/dist/src/public/spaces"
import { getSpaceCapacity } from "./spaces/capacity"

export const getSpace: (spaceId: string | undefined) => Promise<AdminSpace | undefined> = async (
  spaceId,
): Promise<
  | ({
      maxCapacity: number
    } & Awaited<ReturnType<typeof tsrAPI.admin.spaces.get>>)
  | undefined
> => {
  if (!spaceId) throw new Error("Missing spaceId")

  try {
    const maxCapacity = await getSpaceCapacity(spaceId)
    const space = await tsrAPI.admin.spaces.get({
      params: { spaceId },
    })
    return space ? { ...space, maxCapacity } : undefined
  } catch (error) {
    throw new Error(getErrorMessage(error, "Failed to retrieve space."))
  }
}

/**
 * API request to toggle Gather staff access to a space
 * TODO @alecia - transform into the updateSpace request
 * @param spaceId - ID of the space being updated
 * @returns Promise
 */
export const toggleStaffAccess = async (spaceId: string | undefined): Promise<void> => {
  if (!spaceId) throw new Error("Missing spaceId")

  try {
    await tsrAPI.admin.spaces.toggleStaffAccess({
      params: { spaceId },
    })
  } catch (e) {
    throw new Error(getErrorMessage(e, "Failed to toggle staff access."))
  }
}
