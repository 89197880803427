import QueryKeys from "features/queryKeys"
import { useMutation, useQueryClient } from "react-query"

import { Wearable } from "gather-common/dist/src/public/resources/users"
import * as WearablesAPI from "../../../api/wearables"

interface WearableMutationCallbacks {
  /** onError callback function for the mutation */
  onError?: () => void
  /** onSuccess callback function for the mutation */
  onSuccess?: () => void
}

/**
 * Mutation that deletes a wearable document
 * @param callbacks Object containing onError and onSuccess callback methods
 * @returns ReactQuery useMutation hook
 */
const useDeleteWearable = (callbacks?: WearableMutationCallbacks) => {
  const queryClient = useQueryClient()

  return useMutation((wearableId: string) => WearablesAPI.deleteWearable(wearableId), {
    onMutate: async (wearableId: string) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      queryClient.cancelQueries([QueryKeys.Wearables, wearableId])

      // Snapshot the previous values of Wearable
      const previousWearables = queryClient.getQueryData<Wearable[]>([QueryKeys.Wearables])

      const previousWearable = queryClient.getQueryData<Wearable>([QueryKeys.Wearables, wearableId])

      // Remove the Wearable from the current list
      if (previousWearables) {
        const updatedWearableArray = previousWearables.filter(
          (current) => current.id !== wearableId,
        )

        queryClient.setQueryData<Wearable[]>([QueryKeys.Wearables], updatedWearableArray)
      }

      if (previousWearable) {
        queryClient.removeQueries([QueryKeys.Wearables, wearableId], { exact: true })
      }

      return { previousWearables, previousWearable }
    },
    onError: (_err, tempId, context) => {
      if (context?.previousWearables) {
        const { previousWearables } = context

        if (previousWearables) {
          queryClient.setQueryData<Wearable[]>([QueryKeys.Wearables], previousWearables)
        }
      }

      if (context?.previousWearable) {
        const { previousWearable } = context

        if (previousWearable) {
          queryClient.setQueryData<Wearable>([QueryKeys.Wearables, tempId], previousWearable)
        }
      }

      if (callbacks?.onError) {
        callbacks?.onError()
      }
    },

    onSuccess: () => {
      if (callbacks?.onSuccess) {
        callbacks?.onSuccess()
      }
    },

    onSettled: (_data, _err, tempId) => {
      queryClient.invalidateQueries([QueryKeys.Wearables])
      queryClient.invalidateQueries([QueryKeys.Wearables, tempId])
    },
  })
}

export default useDeleteWearable
