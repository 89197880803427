import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import React, { FC } from "react"

import { MapObject as ObjectDB } from "gather-http-common/dist/src/public/spaces"
import ObjectInstanceRow from "./ObjectInstanceRow"

interface Props {
  group: ObjectDB[]
  onModalOpen: (obj: ObjectDB) => void
}

const ObjectInstanceTable: FC<Props> = ({ group, onModalOpen }) => (
  <TableContainer sx={{ maxHeight: 300 }}>
    <Table stickyHeader aria-label="sticky table" size="small">
      <TableHead>
        <TableRow>
          <TableCell>ID</TableCell>
          <TableCell>Color</TableCell>
          <TableCell>Interaction</TableCell>
          <TableCell>Preview Message</TableCell>
          <TableCell>Coordinates</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {group.map((obj) => (
          <ObjectInstanceRow key={obj.id} obj={obj} onModalOpen={onModalOpen} />
        ))}
      </TableBody>
    </Table>
  </TableContainer>
)

export default ObjectInstanceTable
