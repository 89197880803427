import { initContract } from "@ts-rest/core"

import { HttpV2Paths, RequestMethod } from "../../../httpAPI"

const c = initContract()

export const preSignedURL = c.router({
  create: {
    method: RequestMethod.POST,
    path: HttpV2Paths.SpaceRecordingPreSignedURL,
    body: c.noBody(),
    responses: { 200: c.type<{ preSignedURL: string }>() },
  },
})
