import { initContract } from "@ts-rest/core"
import { z } from "zod"

import { HttpV2Paths, RequestMethod } from "../../httpAPI"

const c = initContract()

export const otpRequests = c.router({
  create: {
    method: RequestMethod.POST,
    path: HttpV2Paths.AuthOtpRequests,
    body: z.object({
      email: z.string().email(),
    }),
    responses: { 200: c.type<{ isNewUser: boolean }>() },
  },

  verify: {
    create: {
      method: RequestMethod.POST,
      path: HttpV2Paths.AuthOtpRequestsVerify,
      body: z.object({
        email: z.string().email(),
        otp: z.string(),
      }),
      responses: { 200: c.type<{ token: string }>() },
    },
  },
})
