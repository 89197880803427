import { initContract } from "@ts-rest/core"
import { z } from "zod"

import { HttpV2Paths, RequestMethod } from "../../httpAPI"

const c = initContract()

export const workos = c.router({
  receive: {
    method: RequestMethod.POST,
    path: HttpV2Paths.WorkOSWebhook,
    headers: z.object({
      "workos-signature": z.string(),
    }),
    body: z.object({ id: z.string() }).passthrough(),
    responses: { 200: c.noBody() },
  },
})
