import "firebase/auth"

import firebase from "firebase/app"

import firebaseConfig from "../config"

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig)
}

export default firebase
