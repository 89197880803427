import { initContract } from "@ts-rest/core"
import { z } from "zod"

import { HttpV2Paths, RequestMethod } from "../../httpAPI"

const c = initContract()

export type GoogleAuthMessagePayload = {
  token: string
  name?: string | null
  email: string
}

export const google = c.router({
  token: {
    create: {
      method: RequestMethod.POST,
      path: HttpV2Paths.AuthGoogleTokenSwap,
      body: z.object({
        authCode: z.string(),
      }),
      responses: {
        200: c.type<GoogleAuthMessagePayload>(),
      },
    },
  },
  authorization: {
    create: {
      method: RequestMethod.POST,
      // TODO [rebuild] document if this is used externally?
      path: HttpV2Paths.AuthGoogleSignInAuthUrl,
      body: z.object({
        authCode: z.string(),
      }),
      responses: {
        200: c.type<{ authUrl: string }>(),
      },
    },
  },
})
