import { initContract } from "@ts-rest/core"
import { z } from "zod"

import { GameMapHttpPublic } from "@gathertown/gather-game-common/dist/src/public/gameMap"
import { SpaceMapSize } from "gather-common/dist/src/public/resources/space"
import { HttpV2Paths, RequestMethod } from "../../httpAPI"
import { zodQueryBoolean } from "../../zodHelpers"

const c = initContract()

export const maps = c.router({
  list: {
    method: RequestMethod.GET,
    path: HttpV2Paths.SpaceMaps,
    responses: { 200: c.type<GameMapHttpPublic[]>() },
  },
  get: {
    method: RequestMethod.GET,
    path: HttpV2Paths.SpaceMap,
    responses: { 200: c.type<GameMapHttpPublic>() },
  },
  update: {
    method: RequestMethod.PATCH,
    path: HttpV2Paths.SpaceMap,
    query: z
      .object({
        failOnConflict: zodQueryBoolean.optional(),
      })
      .optional(),
    body: z.object({
      content: z.unknown(),
    }),
    responses: { 200: c.type<{ mostRecentUpdateId: number }>() },
  },
  delete: {
    method: RequestMethod.DELETE,
    path: HttpV2Paths.SpaceMap,
    body: c.noBody(),
    responses: { 200: c.noBody() },
  },

  size: {
    get: {
      method: RequestMethod.GET,
      path: HttpV2Paths.SpaceMapSize,
      responses: { 200: c.type<SpaceMapSize>() },
    },
  },
  preview: {
    method: RequestMethod.GET,
    path: HttpV2Paths.SpaceMapsPreview,
    responses: { 200: c.type<GameMapHttpPublic>() },
  },
})
