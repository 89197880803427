import { initContract } from "@ts-rest/core"
import { z } from "zod"

import { HttpV2Paths, RequestMethod } from "../../httpAPI"

const c = initContract()

export const passwordAttempts = c.router({
  create: {
    method: RequestMethod.POST,
    path: HttpV2Paths.SpacePasswordAttempt,
    body: z.object({
      password: z.string(),
    }),
    responses: { 200: c.noBody() },
  },
})
