import { initContract } from "@ts-rest/core"
import { z } from "zod"

import { SpawnToken } from "gather-common/dist/src/public/resources/space"
import { SpawnTokenType } from "gather-prisma-types/dist/src/public/client"
import { HttpV2Paths, RequestMethod } from "../../httpAPI"

const c = initContract()

export const spawnTokens = c.router({
  create: {
    method: RequestMethod.POST,
    path: HttpV2Paths.SpaceSpawnTokens,
    body: z.object({
      room: z.string(),
      spawn: z.string().optional(),
      type: z.enum([SpawnTokenType.SpawnTile, SpawnTokenType.Desk]),
      deskOwnerId: z.string().optional(),
    }),
    responses: { 200: c.type<SpawnToken>() },
  },
  get: {
    method: RequestMethod.GET,
    path: HttpV2Paths.SpaceSpawnToken,
    responses: { 200: c.type<SpawnToken>() },
  },
})
