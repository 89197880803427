import { z } from "zod"

import { Interaction } from "@gathertown/gather-game-common/dist/src/public/gameMap"
import { Orientation } from "gather-common/dist/src/public/constants"
import { ZodBuffer } from "../../zodHelpers"

const ZodSound = z.object({
  src: ZodBuffer,
  volume: z.number(),
  loop: z.boolean(),
  maxDistance: z.number(),
  isPositional: z.boolean().optional(),
})

export const ZodObjectVariant = z.object({
  color: z.string(),
  orientation: z.number(),
  normal: ZodBuffer.optional(),
  highlighted: ZodBuffer.optional(),
  default: z.boolean(),
})

export const ZodNewObjectTemplate = z.object({
  desc: z.string().optional(),
  name: z.string(),
  tags: z.array(z.string()),
  type: z.nativeEnum(Interaction),
  variants: z.array(ZodObjectVariant),
  sound: ZodSound.optional(),
  distThreshold: z.number().optional(),
  previewMessage: z.string().optional(),
  deterministicUrlPrefix: z.string().optional(),
  url: z.string().optional(),
  preview: ZodBuffer.optional(),
  blurb: z.string().optional(),
  image: ZodBuffer.optional(),
  video: z.string().optional(),
  zoomLink: z.string().optional(),
  message: z.string().optional(),
})

const ZodBulkObjectVariant = z.object({
  color: z.string(),
  orientation: z.nativeEnum(Orientation),
  normal: ZodBuffer,
  normalFileType: z.string(),
})

export const ZodBulkObjectTemplate = z.array(
  z.object({
    name: z.string(),
    variants: z.array(ZodBulkObjectVariant),
  }),
)

// Same as regular ZodSound, but src is optional
const UpdateZodSound = z
  .object({
    src: ZodBuffer,
    volume: z.number(),
    loop: z.boolean(),
    maxDistance: z.number(),
    isPositional: z.boolean(),
  })
  .partial()

export const ZodUpdatedObjectTemplate = z
  .object({
    desc: z.string(),
    name: z.string(),
    tags: z.array(z.string()),
    type: z.nativeEnum(Interaction),
    sound: UpdateZodSound.optional(),
    distThreshold: z.number().optional(),
    previewMessage: z.string().optional(),
    deterministicUrlPrefix: z.string().optional(),
    url: z.string().optional(),
    preview: ZodBuffer.optional(),
    blurb: z.string().optional(),
    image: ZodBuffer.optional(),
    video: z.string().optional(),
    zoomLink: z.string().optional(),
    message: z.string().optional(),
  })
  .partial()

const ZodSpritesheet = z.object({
  spritesheetUrl: z.union([ZodBuffer, z.string()]),
  framing: z.object({
    frameWidth: z.number(),
    frameHeight: z.number(),
  }),
  animations: z.record(
    z.object({
      useSequenceAsRange: z.boolean().optional(),
      sequence: z.array(z.number()),
      loop: z.boolean().optional(),
      frameRate: z.number(),
    }),
  ),
  currentAnim: z.string().optional(),
})

export const ZodUpdatedObjectVariant = z.object({
  color: z.string(),
  orientation: z.number(),
  normal: ZodBuffer.optional(),
  highlighted: ZodBuffer.optional(),
  default: z.boolean(),
  spritesheet: ZodSpritesheet.optional(),
})
