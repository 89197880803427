import { initContract } from "@ts-rest/core"
import { z } from "zod"

import {
  Wearable,
  WearableSubType,
  WearableType,
  ZodWearable,
} from "gather-common/dist/src/public/resources/users"
import { HttpV2Paths, RequestMethod } from "../../httpAPI"

const c = initContract()

export const wearables = c.router({
  delete: {
    method: RequestMethod.DELETE,
    path: HttpV2Paths.AdminWearable,
    body: c.noBody(),
    responses: { 200: c.noBody() },
  },
  list: {
    method: RequestMethod.GET,
    path: HttpV2Paths.AdminWearables,
    responses: { 200: c.type<Wearable[]>() },
  },
  create: {
    method: RequestMethod.POST,
    path: HttpV2Paths.AdminWearables,
    body: z.array(ZodWearable),
    responses: {
      200: c.type<Wearable[]>(),
    },
  },
  updateMany: {
    method: RequestMethod.PATCH,
    path: HttpV2Paths.AdminWearables,
    body: z.record(
      z.string(),
      z.object({
        name: z.string().optional(),
        type: z.nativeEnum(WearableType).optional(),
        subType: z.nativeEnum(WearableSubType).optional().nullable(),
        style: z.string().optional().nullable(),
        isDefault: z.boolean().optional(),
      }),
    ),
    responses: {
      200: c.type<Record<string, Wearable>>(),
    },
  },
})
