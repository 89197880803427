import { DateTime } from "luxon"

/**
 * Simple decorator for dates in the Gather Admin front-end
 * @param date - Date to be converted into a string
 * @param format - string format for how the date will be displayed
 * @returns string
 */
export const displayDate = (
  date: string | null | undefined,
  format = "MMM d, y | h:mm a",
): string => {
  if (date) {
    const dt = DateTime.fromISO(date).toLocal()
    return dt.toFormat(format)
  }

  return ""
}
