import SearchIcon from "@mui/icons-material/Search"
import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import InputAdornment from "@mui/material/InputAdornment"
import TablePagination from "@mui/material/TablePagination"
import TextField from "@mui/material/TextField"
import TabWrapper from "components/dashboard/TabWrapper"
import { applyPagination } from "features/tables/utils"
import { isEmpty } from "ramda"
import React, { ChangeEvent, FC, MouseEvent, useCallback, useMemo, useState } from "react"
import { isCaseInsensitiveMatch } from "utils/filterLogic"

import { RecordingPrisma } from "gather-common/dist/src/public/resources/recording"
import * as SpaceUsersRecordingAPI from "../../../../api/spaces/users/recordings"
import UserRecordingsTable from "./UserRecordingsTable"

interface Props {
  userId?: string
}

const RecordingsTab: FC<Props> = ({ userId }) => {
  const [currentPage, setCurrentPage] = useState(0)
  const [limit, setLimit] = useState(5)
  const [idQuery, setIdQuery] = useState<string>("")
  const [allRecordings, setRecordings] = useState<RecordingPrisma[]>([])

  const handleIdQueryChange = useCallback(async (event: ChangeEvent<HTMLInputElement>) => {
    setIdQuery(event.target.value)
    if (event.target.value === "") {
      setRecordings([])
    } else {
      const recordings = await SpaceUsersRecordingAPI.listSpaceUserRecordings(
        encodeURIComponent(event.target.value || ""),
        userId || "",
      )
      setRecordings(recordings)
    }
  }, [])

  const handlePageChange = useCallback(
    (_event: MouseEvent<HTMLButtonElement> | null, page: number) => {
      setCurrentPage(page)
    },
    [setCurrentPage],
  )

  const handleLimitChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setLimit(parseInt(event.target.value, 10))
      setCurrentPage(0)
    },
    [setLimit, setCurrentPage],
  )

  const applyFilters = useCallback(
    (query: string, recordings: RecordingPrisma[]) =>
      recordings.filter((recording: RecordingPrisma) => {
        let matches = true

        if (query) {
          matches = isCaseInsensitiveMatch(recording.spaceId, query)
        }

        return matches
      }),
    [],
  )

  const filteredRecordings: RecordingPrisma[] = useMemo(
    () => applyFilters(idQuery, allRecordings),
    [idQuery, allRecordings, applyFilters],
  )

  const recordingsToDisplay: RecordingPrisma[] = useMemo(
    () => applyPagination<RecordingPrisma>(filteredRecordings, currentPage, limit),
    [filteredRecordings, currentPage, limit],
  )

  return (
    <TabWrapper>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexWrap: "wrap",
          m: -1,
          mb: 1,
          p: 2,
        }}
      >
        <Box
          sx={{
            width: 400,
            ml: -1,
            mr: 2,
          }}
        >
          <TextField
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              ),
            }}
            onChange={handleIdQueryChange}
            placeholder="Search by Space ID"
            value={idQuery}
            variant="outlined"
          />
        </Box>
      </Box>

      {!isEmpty(allRecordings) ? (
        <Card>
          <UserRecordingsTable recordings={recordingsToDisplay} userId={userId} />
          <TablePagination
            component="div"
            count={filteredRecordings.length}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={currentPage}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
          />
        </Card>
      ) : (
        <Box sx={{ p: 2 }}>Enter a space ID to fetch recordings</Box>
      )}
    </TabWrapper>
  )
}

export default RecordingsTab
