import QueryKeys from "features/queryKeys"
import { useMutation, useQueryClient } from "react-query"

import { ObjectTemplate } from "gather-common/dist/src/public/resources/objectTemplates"
import { deleteVariant } from "../../../api/objectTemplates/variants"
import { trimColor } from "../utils"
import { ObjectVariantDeleteVariables, ObjTempMutationCallbacks } from "./types"

/**
 * Mutation that deletes an object variant from a specific template
 * @param callbacks Object containing onError and onSuccess callback methods
 * @returns ReactQuery useMutation hook
 */
const useDeleteVariant = (callbacks?: ObjTempMutationCallbacks) => {
  const queryClient = useQueryClient()

  return useMutation(
    (variables: ObjectVariantDeleteVariables) =>
      deleteVariant(variables.templateId, variables.variantId),
    {
      onMutate: async (variables: ObjectVariantDeleteVariables) => {
        const { templateId, variantId } = variables

        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries([QueryKeys.ObjectTemplate, templateId])
        await queryClient.cancelQueries([QueryKeys.ObjectTemplates])

        // Snapshot the previous values of ObjectTemplate
        const previousTemplates = queryClient.getQueryData<ObjectTemplate[]>([
          QueryKeys.ObjectTemplates,
        ])

        const previousTemplate = queryClient.getQueryData<ObjectTemplate>([
          QueryKeys.ObjectTemplate,
          templateId,
        ])

        if (!previousTemplate) {
          throw new Error(
            "Previous template doesn't exist - please contact the Platform Tools team about how you received this error",
          )
        }

        // Remove the variant from the current list of variants
        const [targetColor = "", targetOrientation = ""] = variantId.split("-")
        const parsedOrientation = parseInt(targetOrientation)
        const currentColor = trimColor(targetColor)

        const updatedVariants = previousTemplate.variants.filter(
          (variant) =>
            !(
              currentColor === trimColor(variant.color) && variant.orientation === parsedOrientation
            ),
        )

        const updatedTemplate = {
          ...previousTemplate,
          variants: updatedVariants,
        }

        // Replace the template (which has now deleted a variant) in the index
        if (previousTemplates) {
          const index = previousTemplates.findIndex(
            (template) => template.id === previousTemplate.id,
          )
          const updatedList = [...previousTemplates]
          updatedList[index] = updatedTemplate

          queryClient.setQueryData<ObjectTemplate[]>([QueryKeys.ObjectTemplates], updatedList)
        }

        // Update the single version of the record in the cache
        queryClient.setQueryData<ObjectTemplate>(
          [QueryKeys.ObjectTemplate, templateId],
          updatedTemplate,
        )

        return { previousTemplates, previousTemplate }
      },
      onError: (_err, variables, context) => {
        const { previousTemplates, previousTemplate } = context || {}
        const { templateId } = variables

        if (previousTemplates) {
          queryClient.setQueryData<ObjectTemplate[]>([QueryKeys.ObjectTemplates], previousTemplates)
        }

        if (previousTemplate) {
          queryClient.setQueryData<ObjectTemplate>(
            [QueryKeys.ObjectTemplate, templateId],
            previousTemplate,
          )
        }

        callbacks?.onError?.()
      },

      onSuccess: () => {
        callbacks?.onSuccess?.()
      },

      onSettled: async (_data, _err, variables) => {
        const { templateId } = variables
        await queryClient.invalidateQueries([QueryKeys.ObjectTemplates])
        await queryClient.invalidateQueries([QueryKeys.ObjectTemplate, templateId])
      },
    },
  )
}

export default useDeleteVariant
