import { initContract } from "@ts-rest/core"
import { z } from "zod"

import { UserOutfitTemplateResource } from "gather-common/dist/src/public/resources/userOutfitTemplate"
import { UserOutfitTemplateType } from "gather-prisma-types/dist/src/public/client"
import { HttpV2Paths, RequestMethod } from "../../httpAPI"

const c = initContract()

export const templates = c.router({
  list: {
    method: RequestMethod.GET,
    path: HttpV2Paths.UserOutfitTemplates,
    query: z.object({
      type: z.nativeEnum(UserOutfitTemplateType),
    }),
    responses: { 200: c.type<UserOutfitTemplateResource[]>() },
  },
})
