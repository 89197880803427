import { initContract } from "@ts-rest/core"
import { z } from "zod"

import { UploadedFileInfo, UploadedFileType } from "gather-common/dist/src/public/resources/files"
import { HttpV2Paths, RequestMethod } from "../../httpAPI"
import { zodImageBufferUnderSizeLimit } from "../users/files"

const c = initContract()

export const MAX_SEARCH_LIMIT = 500

export const files = c.router({
  get: {
    method: RequestMethod.GET,
    path: HttpV2Paths.SpaceFile,
    responses: { 200: c.type<UploadedFileInfo>() },
  },
  delete: {
    method: RequestMethod.DELETE,
    path: HttpV2Paths.SpaceFile,
    body: c.noBody(),
    responses: { 200: c.noBody() },
  },
  create: {
    method: RequestMethod.POST,
    path: HttpV2Paths.SpaceFiles,
    body: z.object({
      bytes: zodImageBufferUnderSizeLimit,
      type: z.nativeEnum(UploadedFileType),
    }),
    responses: { 200: c.type<UploadedFileInfo>() },
  },
})
