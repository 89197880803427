import { initContract } from "@ts-rest/core"

import { ObjectTemplate } from "gather-common/dist/src/public/resources/objectTemplates"
import { HttpV2Paths, RequestMethod } from "../../httpAPI"
import { BulkUploadResponse } from "../../resources/admin"
import {
  ZodBulkObjectTemplate,
  ZodNewObjectTemplate,
  ZodUpdatedObjectTemplate,
} from "./objectTemplates.types"

const c = initContract()

export const objectTemplates = c.router({
  list: {
    method: RequestMethod.GET,
    path: HttpV2Paths.AdminObjectTemplates,
    responses: {
      200: c.type<ObjectTemplate[]>(),
    },
  },
  create: {
    method: RequestMethod.POST,
    path: HttpV2Paths.AdminObjectTemplates,
    body: ZodNewObjectTemplate,
    responses: {
      200: c.type<string>(),
    },
  },
  get: {
    method: RequestMethod.GET,
    path: HttpV2Paths.AdminObjectTemplate,
    responses: {
      200: c.type<ObjectTemplate>(),
    },
  },
  update: {
    method: RequestMethod.PATCH,
    path: HttpV2Paths.AdminObjectTemplate,
    body: ZodUpdatedObjectTemplate,
    responses: {
      200: c.noBody(),
    },
  },
  delete: {
    method: RequestMethod.DELETE,
    path: HttpV2Paths.AdminObjectTemplate,
    body: c.noBody(),
    responses: {
      200: c.noBody(),
    },
  },
  bulkUploadList: {
    method: RequestMethod.POST,
    path: HttpV2Paths.AdminObjectTemplatesBulk,
    body: ZodBulkObjectTemplate,
    responses: {
      200: c.type<BulkUploadResponse>(),
    },
  },
})
