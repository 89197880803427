import { initContract } from "@ts-rest/core"
import { z } from "zod"

import { HttpV2Paths, RequestMethod } from "../../httpAPI"

const c = initContract()

export const recordingHook = c.router({
  receive: {
    method: RequestMethod.POST,
    path: HttpV2Paths.HookAVRecordingS3,
    headers: z.object({
      "x-gather-auth-key": z.string(),
    }),
    body: z.object({
      recordingId: z.string(),
      url: z.string().optional(),
    }),
    responses: { 200: c.noBody() },
  },
})
