import { initContract } from "@ts-rest/core"
import { DateTime } from "luxon"
import { z } from "zod"

import { HttpV2Paths, RequestMethod } from "../../httpAPI"
import {
  InsightsChartData,
  InsightsSummaryResponse,
  MultiQueryName,
  QueryDetailsName,
  QueryName,
} from "../../resources/insights"
import { alphanumericStringRefinement, datesRefinement, zodUuid } from "../../zodHelpers"

const c = initContract()

const datesSchema = z.object({
  startDate: z
    .string()
    .datetime({ offset: true })
    .default(DateTime.now().minus({ month: 1 }).toISO()),
  endDate: z.string().datetime({ offset: true }).default(DateTime.now().toISO()),
})

const DATES_REFINEMENT_MESSAGE = "The start date should come before the end date."

export const insights = c.router({
  get: {
    method: RequestMethod.GET,
    path: HttpV2Paths.SpaceInsights,
    pathParams: z.object({
      spaceId: zodUuid,
      metric: z.nativeEnum(QueryName),
    }),
    query: datesSchema.refine(datesRefinement, DATES_REFINEMENT_MESSAGE),
    responses: { 200: c.type<InsightsChartData>() },
  },

  details: {
    get: {
      method: RequestMethod.GET,
      path: HttpV2Paths.SpaceInsightsMetricDetails,
      pathParams: z.object({
        spaceId: zodUuid,
        metric: z.nativeEnum(QueryDetailsName),
      }),
      query: datesSchema.merge(
        z.object({
          userIds: z
            .string()
            .array()
            .min(1)
            .max(10)
            .refine(
              alphanumericStringRefinement,
              "Invalid user IDs: must be between 1 and 10 in length",
            ),
        }),
      ),
      responses: { 200: c.type<InsightsChartData>() },
    },
  },

  summary: {
    get: {
      method: RequestMethod.GET,
      path: HttpV2Paths.SpaceInsightsSummary,
      pathParams: z.object({
        spaceId: zodUuid,
        metrics: z.nativeEnum(MultiQueryName),
      }),
      query: datesSchema.refine(datesRefinement, DATES_REFINEMENT_MESSAGE),
      responses: { 200: c.type<InsightsSummaryResponse>() },
    },
  },
})
