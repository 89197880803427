import { initContract } from "@ts-rest/core"

import { ObjectTemplate } from "gather-common/dist/src/public/resources/objectTemplates"
import { HttpV2Paths, RequestMethod } from "../../httpAPI"

const c = initContract()

export const objectTemplates = c.router({
  list: {
    method: RequestMethod.GET,
    path: HttpV2Paths.SpaceObjectTemplates,
    responses: { 200: c.type<ObjectTemplate[]>() },
  },
})
