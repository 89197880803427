import useAuth from "features/authentication/useAuth"
import React, { FC } from "react"
import { Navigate } from "react-router-dom"

interface Props {
  children: string | React.ReactNode
}

const GuestGuard: FC<Props> = ({ children }) => {
  const { isAuthenticated } = useAuth()

  if (isAuthenticated) return <Navigate to="/dashboard" />

  return <>{children}</>
}

export default GuestGuard
