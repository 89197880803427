import { uniq } from "ramda"

import { just } from "gather-common-including-video/dist/src/public/fpHelpers"
import { AdminRoleTypePrisma } from "gather-prisma-types/dist/src/public/client"
import { AdminRole } from "./index"
import { AdminPermission } from "./types"

// From a list of roles, retrieve all of the (unique) permissions
export const getPermissionsFromRoles = (roles: AdminRoleTypePrisma[]): AdminPermission[] =>
  uniq(roles.map((role) => just(AdminRole[role]).permissions).flat())

type PermittedType = (
  required: AdminPermission[] | undefined,
  current: AdminPermission[],
  requiresAll?: boolean,
) => boolean

export const checkIfPermitted: PermittedType = (
  required = [],
  current,
  requiresAll = false,
): boolean => {
  let isPermitted: boolean

  if (required && required.length > 0) {
    if (requiresAll) {
      isPermitted = required.every((r) => current.includes(r))
    } else {
      isPermitted = required.some((r) => current.includes(r))
    }
  } else {
    isPermitted = true
  }

  return isPermitted
}
