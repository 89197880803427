import { initContract } from "@ts-rest/core"
import { z } from "zod"

import { VALID_EMAIL_DOMAIN_PATTERN } from "gather-common/dist/src/public/constants"
import { SSOEmailDomain } from "gather-common/dist/src/public/resources/sso"
import { HttpV2Paths, RequestMethod } from "../../../httpAPI"

const c = initContract()

export const emailDomains = c.router({
  list: {
    method: RequestMethod.GET,
    path: HttpV2Paths.SpaceSSOEmailDomains,
    responses: { 200: c.type<SSOEmailDomain[]>() },
  },
  create: {
    method: RequestMethod.POST,
    path: HttpV2Paths.SpaceSSOEmailDomains,
    body: z.object({
      emailDomain: z.string().regex(VALID_EMAIL_DOMAIN_PATTERN),
    }),
    responses: { 200: c.noBody() },
  },
  delete: {
    method: RequestMethod.DELETE,
    path: HttpV2Paths.SpaceSSOEmailDomains,
    body: z.object({
      emailDomain: z.string().regex(VALID_EMAIL_DOMAIN_PATTERN),
    }),
    responses: { 200: c.noBody() },
  },

  verification: {
    create: {
      method: RequestMethod.POST,
      path: HttpV2Paths.SpaceSSOEmailDomainVerification,
      body: c.noBody(),
      responses: { 200: c.type<SSOEmailDomain[]>() },
    },
  },
})
