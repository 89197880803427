import firebase from "lib/firebase"
import React, { createContext, FC, useEffect, useReducer } from "react"
import { toast } from "react-hot-toast"

import { validateGatherEmail } from "gather-admin-common/dist/src/public/auth"
import { AdminUser } from "gather-http-common/dist/src/public/superAdminUsers"

interface AuthState {
  isInitialized: boolean
  isAuthenticated: boolean
  user?: AdminUser
}

const initialState: AuthState = {
  isInitialized: false,
  isAuthenticated: false,
  user: undefined,
}

// Lint warning auto-ignored when enabling the no-explicit-any rule. Fix this the next time this code is edited! TODO: @ENG-4294 Clean these up! See the linear task for guidance on how to do so.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reducer = (state: any, action: any) => {
  if (action.type === "AUTH_STATE_CHANGED") {
    const { isAuthenticated, user } = action.payload

    return {
      ...state,
      isInitialized: true,
      isAuthenticated,
      user,
    }
  }

  return state
}

const AuthContext = createContext({
  ...initialState,
  platform: "Firebase",
  signInAnonymously: () => Promise.resolve(),
  signInWithGoogle: () => Promise.resolve(),
  logout: () => Promise.resolve(),
})

interface AuthProviderProps {
  children: string | React.ReactNode
}

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(
    () =>
      // Lint warning auto-ignored when enabling the no-explicit-any rule. Fix this the next time this code is edited! TODO: @ENG-4294 Clean these up! See the linear task for guidance on how to do so.
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      firebase.auth().onAuthStateChanged((user: any) => {
        if (user) {
          const isEmployee = validateGatherEmail(user.email)

          if (isEmployee) {
            dispatch({
              type: "AUTH_STATE_CHANGED",
              payload: {
                isAuthenticated: true,
                user: {
                  id: user.uid,
                  avatar: user.photoURL,
                  email: user.email,
                  name: user.displayName,
                },
              },
            })
          } else {
            if (!user.isAnonymous) {
              toast.error("You need to work here to access this tool, you silly goose!")
            }
            dispatch({
              type: "AUTH_STATE_CHANGED",
              payload: {
                isAuthenticated: false,
                user: null,
              },
            })
          }
        } else {
          dispatch({
            type: "AUTH_STATE_CHANGED",
            payload: {
              isAuthenticated: false,
              user: null,
            },
          })
        }
      }),
    [dispatch],
  )

  const signInAnonymously = async () => {
    await firebase.auth().signInAnonymously()
  }

  const signInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider()
    return firebase.auth().signInWithPopup(provider)
  }

  const logout = async () => {
    await firebase.auth().signOut()
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: "Firebase",
        signInAnonymously,
        signInWithGoogle,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthContext
