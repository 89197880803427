import { initContract } from "@ts-rest/core"
import { z } from "zod"

import { SpaceUserOnboardingResource } from "gather-common/dist/src/public/resources/memberOnboarding"
import {
  MemberOnboardingDesk,
  MemberOnboardingStep,
} from "gather-prisma-types/dist/src/public/client"
import { HttpV2Paths, RequestMethod } from "../../../httpAPI"

const c = initContract()

export const onboarding = c.router({
  get: {
    method: RequestMethod.GET,
    path: HttpV2Paths.SpaceUserOnboarding,
    responses: { 200: c.type<SpaceUserOnboardingResource>() },
  },
  update: {
    method: RequestMethod.PATCH,
    path: HttpV2Paths.SpaceUserOnboarding,
    body: z.object({
      currentStep: z.nativeEnum(MemberOnboardingStep).optional(),
      isComplete: z.boolean().optional(),
      isActive: z.boolean().optional(),
      deskSelectionId: z.nativeEnum(MemberOnboardingDesk).nullable().optional(),
      outfitTemplateId: z.string().nullable().optional(),
      shouldSetDesk: z.boolean().optional(),
      deskIdToAssign: z.string().nullable().optional(),
      deskMapIdToAssign: z.string().nullable().optional(),
      name: z.string().optional(),
      lastActiveAt: z.coerce.date().nullable().optional(),
    }),
    responses: { 200: c.type<SpaceUserOnboardingResource>() },
  },
})
