import { initContract } from "@ts-rest/core"

import { HttpV2Paths, RequestMethod } from "../../../httpAPI"

const c = initContract()

export const nookSpawnTokens = c.router({
  list: {
    method: RequestMethod.GET,
    path: HttpV2Paths.SpaceNookSpawnTokens,
    responses: { 200: c.type<Record<string, string>>() },
  },
})
