import { useCallback, useState } from "react"

export const useTabs = (initialTab = "0") => {
  const [currentTab, setCurrentTab] = useState(initialTab)

  const handleChange = useCallback((_: unknown, value: string) => {
    setCurrentTab(value)
  }, [])

  return {
    currentTab,
    setCurrentTab,
    handleChange,
  }
}
