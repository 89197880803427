import loadable from "@loadable/component"
import GuestGuard from "components/authentication/GuestGuard"
import PublicLayout from "components/layout/PublicLayout"
import LoadingScreen from "components/LoadingScreen"
import React from "react"

const LOADABLE_OPTIONS = {
  fallback: <LoadingScreen />,
}

const Login = loadable(() => import("pages/Login"), LOADABLE_OPTIONS)
const SSOCallback = loadable(() => import("pages/SSOCallback"), LOADABLE_OPTIONS)

const PublicRoutes = [
  {
    path: "*",
    element: <PublicLayout />,
    children: [
      {
        path: "",
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        ),
      },
      {
        path: "login",
        children: [
          {
            path: "",
            element: (
              <GuestGuard>
                <Login />
              </GuestGuard>
            ),
          },
          {
            path: "callback",
            element: (
              <GuestGuard>
                <SSOCallback />
              </GuestGuard>
            ),
          },
        ],
      },
    ],
  },
]

export default PublicRoutes
