import { toast } from "react-hot-toast"

import { tsrAPI } from "gather-browser-common/dist/src/public/tsRestClient"
import { ObjectTemplateCategoryGroup } from "gather-common/dist/src/public/resources/objectTemplates"

export const listAdminObjectCategories: () => Promise<
  ObjectTemplateCategoryGroup[]
> = async (): Promise<Awaited<ReturnType<typeof tsrAPI.admin.objectTemplateCategories.list>>> => {
  try {
    return await tsrAPI.admin.objectTemplateCategories.list()
  } catch (error) {
    const message = "Failed to retrieve object template categories."
    toast.error(message)
    return Promise.reject(message)
  }
}

export const updateAdminObjectCategories: (
  categoryGroup: ObjectTemplateCategoryGroup,
) => Promise<void> = async (categoryGroup): Promise<void> => {
  try {
    await tsrAPI.admin.objectTemplateCategories.update({
      params: { categoryId: categoryGroup.id },
      body: {
        categories: categoryGroup.categories,
      },
    })
  } catch (error) {
    const message = "Failed to modify object template category"
    toast.error(message)
    return Promise.reject(message)
  }
}
