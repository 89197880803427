/* External modules */
/* MUI Components */
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Divider from "@mui/material/Divider"
/* Local modules */
import { ObjectTemplateFields } from "features/objectTemplates/types"
import { FormikProps } from "formik"
import React, { FC } from "react"

import { ObjectTemplate } from "gather-common/dist/src/public/resources/objectTemplates"
import GeneralFields from "./GeneralFields"
import InteractionOptionsFields from "./InteractionOptionsFields"

interface Props {
  oldTemplate: ObjectTemplate
}

const UpdateForm: FC<Props & FormikProps<ObjectTemplateFields>> = ({ oldTemplate, ...formik }) => {
  const { handleSubmit, isSubmitting } = formik

  return (
    <>
      <form onSubmit={handleSubmit} autoComplete="off">
        <Box sx={{ display: "flex", gap: 10, flexWrap: "wrap", flexDirection: "row", mt: 1 }}>
          <GeneralFields {...formik} />

          <InteractionOptionsFields oldTemplate={oldTemplate} {...formik} />
        </Box>

        <Divider sx={{ mt: 5 }} />

        <Box sx={{ p: 2, display: "flex", justifyContent: "flex-end" }}>
          <Button
            color="primary"
            disabled={isSubmitting}
            sx={{ ml: 1 }}
            type="submit"
            variant="contained"
          >
            Update Template
          </Button>
        </Box>
      </form>
    </>
  )
}

export default UpdateForm
