import { initContract } from "@ts-rest/core"
import { z } from "zod"

import { HttpV2Paths, RequestMethod } from "../httpAPI"

const c = initContract()

export const invites = c.router({
  create: {
    method: RequestMethod.POST,
    path: HttpV2Paths.Invites,
    body: z.object({
      inviteTokenId: z.string(),
      email: z.string().max(320).email(),
    }),
    responses: { 200: c.noBody() },
  },
})
