import Box from "@mui/material/Box"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import Typography from "@mui/material/Typography"
import { InteractionTypes } from "features/maps/types"
import { getObjectNanoID } from "features/maps/utils"
import React, { FC } from "react"

import { MapObject as ObjectDB } from "gather-http-common/dist/src/public/spaces"

interface Props {
  obj: ObjectDB
  onModalOpen: (obj: ObjectDB) => void
}

const ObjectInstanceRow: FC<Props> = ({ obj, onModalOpen }) => {
  // Remove template ID and name from the nano id of the object instance
  const target = getObjectNanoID(obj)

  const handleOpenModal = () => {
    onModalOpen(obj)
  }

  return (
    <TableRow hover sx={{ cursor: "pointer" }} key={target} onClick={handleOpenModal}>
      <TableCell>
        <Typography color="textSecondary" variant="body2">
          {target}
        </Typography>
      </TableCell>

      <TableCell>
        <Box sx={{ display: "flex", alignItems: "baseline", gap: 1 }}>
          <Box
            sx={{
              bgcolor: obj.color,
              width: 14,
              height: 14,
              border: "solid 1px white",
              borderRadius: "3px",
            }}
          />
          <Typography color="textPrimary" variant="body2">
            {obj.color}
          </Typography>
        </Box>
      </TableCell>

      <TableCell>
        <Typography color="textPrimary" variant="body2">
          {obj.type > 0 && InteractionTypes[obj.type]}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography color="textPrimary" variant="body2">
          {obj.previewMessage}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography color="textPrimary" variant="body2">
          ({obj.x}, {obj.y})
        </Typography>
      </TableCell>
    </TableRow>
  )
}

export default ObjectInstanceRow
