import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp"
import Box from "@mui/material/Box"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import React, { FC } from "react"

import { SpaceUserResource } from "gather-common/dist/src/public/resources/space"
import UserRow from "./UserRow"

interface Props {
  users: SpaceUserResource[]
  onToggleSort: () => void
  // onDelete?: (userId: string) => void; // TODO: future development
  // onUpdate?: (userId: string, roles: CoreRole[]) => void; // TODO: future development
  currentSortAsc: boolean
}

const UsersTable: FC<Props> = ({ users, onToggleSort, currentSortAsc }) => (
  <Box sx={{ minWidth: "100%" }}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>ID</TableCell>
          <TableCell
            onClick={onToggleSort}
            sx={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            Email
            <Box
              sx={{
                mb: -1,
                ml: -1,
              }}
            >
              {currentSortAsc ? (
                <ArrowDropDownIcon color="primary" sx={{ ml: 2 }} fontSize="small" />
              ) : (
                <ArrowDropUpIcon color="primary" sx={{ ml: 2 }} fontSize="small" />
              )}
            </Box>
          </TableCell>
          <TableCell>Roles</TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {users.map((data: SpaceUserResource) => (
          <UserRow key={data.id} user={data} />
        ))}
      </TableBody>
    </Table>
  </Box>
)

export default UsersTable
