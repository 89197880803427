import Box from "@mui/material/Box"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import React, { FC } from "react"

import { MapObject as ObjectDB } from "gather-http-common/dist/src/public/spaces"
import ObjectGroupRow from "./ObjectGroupRow"
import { ObjectGroupInfo, ObjectGroups } from "./tabs/ObjectsTab"

interface Props {
  objectGroups: ObjectGroups
  sortedGroupInfo: ObjectGroupInfo[]
  onModalOpen: (obj: ObjectDB) => void
}

const ObjectsTable: FC<Props> = ({ objectGroups, sortedGroupInfo, onModalOpen }) => (
  <Box sx={{ minWidth: "100%" }}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell />
          <TableCell />
          <TableCell>Template ID</TableCell>
          <TableCell>Instances</TableCell>
          <TableCell>Dimensions</TableCell>
          <TableCell>Tags</TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {sortedGroupInfo.map((item, index: number) => (
          <ObjectGroupRow
            key={item.key}
            templateId={item.key}
            group={objectGroups[item.key] || []}
            index={index}
            onModalOpen={onModalOpen}
          />
        ))}
      </TableBody>
    </Table>
  </Box>
)

export default ObjectsTable
