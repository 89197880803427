import QueryKeys from "features/queryKeys"
import { useMutation, UseMutationResult, useQuery, UseQueryResult } from "react-query"

import { deleteUsers, getUserDetails } from "../../api/users"
import { listUserSpaces } from "../../api/users/spaces"

export const useUser = (
  userId: string,
): UseQueryResult<Awaited<ReturnType<typeof getUserDetails>>> =>
  useQuery([QueryKeys.User, userId], () => getUserDetails(userId), {
    initialData: undefined,
    enabled: userId.length > 0,
    retry: false,
    refetchOnWindowFocus: false,
  })

export const useUserSpaces = (
  userId?: string,
): UseQueryResult<Awaited<ReturnType<typeof listUserSpaces>>> =>
  useQuery([QueryKeys.UserSpaces, userId], () => listUserSpaces(userId || ""), {
    initialData: undefined,
    enabled: !!userId,
    retry: false,
    refetchOnWindowFocus: false,
  })

export const useDeleteUsersByEmails = (): UseMutationResult<
  Awaited<ReturnType<typeof deleteUsers>>,
  unknown,
  { emails: string[] },
  unknown
> => useMutation({ mutationFn: ({ emails }: { emails: string[] }) => deleteUsers(emails) })
