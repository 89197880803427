import { initContract } from "@ts-rest/core"
import { z } from "zod"

import { isNotNil } from "gather-common-including-video/dist/src/public/fpHelpers"
import { ExternalIntegrationType } from "gather-prisma-types/dist/src/public/client"
import { HttpV2Paths, RequestMethod } from "../../../httpAPI"

const c = initContract()

export const auths = c.router({
  create: {
    method: RequestMethod.POST,
    path: HttpV2Paths.SpaceCalendarAuths,
    body: z.object({
      integrationType: z.nativeEnum(ExternalIntegrationType).optional(),
      redirect: z.string().optional(),
    }),
    responses: { 200: c.type<{ authUrl: string }>() },
  },
  update: {
    method: RequestMethod.PATCH,
    path: HttpV2Paths.SpaceCalendarAuth,
    body: z
      .object({
        selectedCalendarIds: z.array(z.string()).optional(),
        autoDndEnabled: z.boolean().optional(),
      })
      .refine(
        (data) =>
          // at least one of the fields is present
          isNotNil(data.selectedCalendarIds) || isNotNil(data.autoDndEnabled),
        {
          message: 'At least one of "selectedCalendarIds" or "autoDndEnabled" must be present',
          path: ["selectedCalendarIds", "autoDndEnabled"],
        },
      ),
    responses: { 200: c.noBody() },
  },
  delete: {
    method: RequestMethod.DELETE,
    path: HttpV2Paths.SpaceCalendarAuth,
    body: c.noBody(),
    responses: { 200: c.noBody() },
  },
})
