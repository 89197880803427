import { initContract } from "@ts-rest/core"
import { z } from "zod"

import { SpaceCapacityWarningPrisma } from "gather-prisma-types/dist/src/public/client"
import { HttpV2Paths, RequestMethod } from "../../../httpAPI"

const c = initContract()

export const warning = c.router({
  create: {
    method: RequestMethod.POST,
    path: HttpV2Paths.SpaceCapacityWarning,
    body: z.object({
      capacity: z.number(),
      userId: z.string(),
    }),
    responses: { 200: c.type<SpaceCapacityWarningPrisma>() },
  },
})
