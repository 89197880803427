import { initContract } from "@ts-rest/core"
import { z } from "zod"

import { HttpV2Paths, RequestMethod } from "../../httpAPI"
import { zodUuid } from "../../zodHelpers"
import { zodImageBufferUnderSizeLimit } from "../users/files"

const c = initContract()

export const uploadImageBodySchema = z.object({
  bytes: zodImageBufferUnderSizeLimit,
  spaceId: zodUuid,
})

export const images = c.router({
  create: {
    method: RequestMethod.POST,
    path: HttpV2Paths.UploadImages,
    body: uploadImageBodySchema,
    responses: { 200: c.type<string>() },
  },
})
