import Label from "components/dashboard/Label"
import React, { FC } from "react"

interface Props {
  condition: boolean
}

const BooleanLabel: FC<Props> = ({ condition }) => (
  <Label color={condition ? "success" : "info"}>{condition ? "Yes" : "No"}</Label>
)

export default BooleanLabel
