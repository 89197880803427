import { Buffer } from "buffer"

import QueryKeys from "features/queryKeys"
import { useMutation, useQueryClient } from "react-query"

import { ObjectTemplate } from "gather-common/dist/src/public/resources/objectTemplates"
import { updateVariant } from "../../../api/objectTemplates/variants"
import { buildSpritesheet, getImagePreviewUrl, trimColor } from "../utils"
import {
  ObjectVariantUpdateVariables,
  ObjTempMutationCallbacks,
  ObjTempMutationContext,
} from "./types"

/**
 * Mutation that updates an object variant document that belongs to an object
 * template
 * @param callbacks Object containing onError and onSuccess callback methods
 * @returns ReactQuery useMutation hook
 */
const useUpdateVariant = (callbacks?: ObjTempMutationCallbacks) => {
  const queryClient = useQueryClient()

  return useMutation(
    (variables: ObjectVariantUpdateVariables) =>
      updateVariant(variables.templateId, variables.variantId, variables.fields),
    {
      onMutate: async (variables) => {
        const { templateId, fields, variantId } = variables

        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries([QueryKeys.ObjectTemplates])
        await queryClient.cancelQueries([QueryKeys.ObjectTemplate, templateId])

        // Snapshot the previous value of all templates currently cached
        const previousTemplates =
          queryClient.getQueryData<ObjectTemplate[]>([QueryKeys.ObjectTemplates]) ?? []
        const previousTemplate = queryClient.getQueryData<ObjectTemplate>([
          QueryKeys.ObjectTemplate,
          templateId,
        ])

        if (!previousTemplate) {
          throw new Error(
            "Previous template doesn't exist - please contact the Platform Tools team about how you received this error",
          )
        }

        // Remove the old version of the variant from the list
        const [targetColor = "", targetOrientation = ""] = variantId.split("-")
        const parsedOrientation = parseInt(targetOrientation)
        const currentColor = trimColor(targetColor)

        const oldVersion = [...previousTemplate.variants].find(
          (variant) =>
            variant.id === variantId ||
            (currentColor === trimColor(variant.color) &&
              variant.orientation === parsedOrientation),
        )

        const updatedVariants = previousTemplate.variants.filter(
          (variant) =>
            !(
              variant.id === variantId ||
              (currentColor === trimColor(variant.color) &&
                variant.orientation === parsedOrientation)
            ),
        )
        const builtSpritesheet = buildSpritesheet(fields)

        // Append this variant to the end of the list
        const updatedTemplate = {
          ...previousTemplate,
          variants: [
            ...updatedVariants,
            {
              color: fields.color ?? "",
              orientation: fields.orientation ?? 0,
              default: fields.default ?? false,
              highlighted:
                fields.highlighted instanceof Buffer
                  ? getImagePreviewUrl(fields.highlighted)
                  : oldVersion?.highlighted ?? "",
              normal:
                fields.normal instanceof Buffer
                  ? getImagePreviewUrl(fields.normal)
                  : oldVersion?.normal ?? "",
              spritesheet:
                builtSpritesheet && fields.spritesheetUrl instanceof Buffer
                  ? {
                      ...builtSpritesheet,
                      spritesheetUrl: getImagePreviewUrl(fields.spritesheetUrl),
                    }
                  : oldVersion?.spritesheet,
            },
          ],
        }

        // Find the old item in the list and replace it
        if (previousTemplates) {
          const index = previousTemplates.findIndex(
            (template) => template.id === previousTemplate.id,
          )
          const updatedList = [...previousTemplates]
          updatedList[index] = updatedTemplate

          queryClient.setQueryData<ObjectTemplate[]>([QueryKeys.ObjectTemplates], updatedList)
        }

        queryClient.setQueryData<ObjectTemplate>(
          [QueryKeys.ObjectTemplate, templateId],
          updatedTemplate,
        )

        return {
          previousTemplates,
          previousTemplate,
        }
      },

      onError: async (_err, variables, context?: ObjTempMutationContext) => {
        const { previousTemplates, previousTemplate } = context || {}
        const { templateId } = variables

        if (previousTemplates) {
          queryClient.setQueryData<ObjectTemplate[]>([QueryKeys.ObjectTemplates], previousTemplates)
        }

        if (previousTemplate) {
          queryClient.setQueryData<ObjectTemplate>(
            [QueryKeys.ObjectTemplate, templateId],
            previousTemplate,
          )
        }

        callbacks?.onError?.()
      },

      onSuccess: () => {
        callbacks?.onSuccess?.()
      },

      onSettled: async (_data, _err, variables) => {
        const { templateId } = variables
        await queryClient.invalidateQueries([QueryKeys.ObjectTemplates])
        await queryClient.invalidateQueries([QueryKeys.ObjectTemplate, templateId])
      },
    },
  )
}

export default useUpdateVariant
