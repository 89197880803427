import { initContract } from "@ts-rest/core"

import { HttpV2Paths, RequestMethod } from "../../httpAPI"
import { PartialNookWithToken } from "../../resources/nooks"

const c = initContract()

export const nooks = c.router({
  list: {
    method: RequestMethod.GET,
    path: HttpV2Paths.SpaceNooksSpawnTokens,
    responses: { 200: c.type<PartialNookWithToken[]>() },
  },
})
