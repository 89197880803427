import { Box } from "@mui/material"
import React, { forwardRef } from "react"
import PerfectScrollbar from "react-perfect-scrollbar"

// Lint warning auto-ignored when enabling the no-explicit-any rule. Fix this the next time this code is edited! TODO: @ENG-4294 Clean these up! See the linear task for guidance on how to do so.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Scrollbar = forwardRef((props: any, ref) => {
  const { children, ...other } = props

  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent,
  )

  if (isMobile) {
    return (
      <Box ref={ref} sx={{ overflowX: "auto" }}>
        {children}
      </Box>
    )
  }

  return (
    <PerfectScrollbar ref={ref} {...other}>
      {children}
    </PerfectScrollbar>
  )
})

Scrollbar.displayName = "Scrollbar"

export default Scrollbar
