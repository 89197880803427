import { Box, ToggleButton, ToggleButtonGroup, Toolbar, Typography } from "@mui/material"
import { pick, values } from "ramda"
import React from "react"

import Events from "./Events"
import { RenderConsoleRow } from "./Logs"
import { PaneContent } from "./ui"
import { isConsoleMessage } from "./utils"

enum Level {
  Error = "error",
  Warning = "warning",
  Info = "info",
  Log = "log",
  Debug = "debug",
}

const messageTypeMap = {
  [Level.Error]: "CONSOLE_MESSAGE/error",
  [Level.Warning]: "CONSOLE_MESSAGE/warn",
  [Level.Info]: "CONSOLE_MESSAGE/info",
  [Level.Log]: "CONSOLE_MESSAGE/log",
  [Level.Debug]: "CONSOLE_MESSAGE/debug",
}

const createfilterdRenderer = (mesageLevels: Set<string>) => (message: unknown) => {
  if (isConsoleMessage(message) && mesageLevels.has(message.type)) return RenderConsoleRow

  return null
}

const LogEvents = () => {
  const [selectedLevels, setSelectedLevels] = React.useState<Level[]>([Level.Error, Level.Warning])

  const handleChange = (_: unknown, filter: Level[]) => {
    if (filter.length === 0) {
      setSelectedLevels([Level.Error, Level.Warning])

      return
    }
    setSelectedLevels(filter)
  }

  const filterdRenderer = React.useMemo(() => {
    const selectedLevelsSet = new Set(values(pick(selectedLevels, messageTypeMap)))

    return createfilterdRenderer(selectedLevelsSet)
  }, [selectedLevels])

  return (
    <PaneContent>
      <Toolbar variant="dense">
        <Typography variant="body1" sx={{ flexGrow: 1 }}>
          Show Levels:
        </Typography>
        <ToggleButtonGroup
          size="small"
          value={selectedLevels}
          color="primary"
          onChange={handleChange}
        >
          <ToggleButton value={"log"}>Log</ToggleButton>
          <ToggleButton value={"error"}>Errors</ToggleButton>
          <ToggleButton value={"warning"}>Warnings</ToggleButton>
          <ToggleButton value={"info"}>Info</ToggleButton>
          <ToggleButton value={"debug"}>Verbose</ToggleButton>
        </ToggleButtonGroup>
      </Toolbar>
      <Box
        sx={{
          overflow: "hidden",
          height: "calc(100% - 48px)",
        }}
      >
        <Events renderers={filterdRenderer} />
      </Box>
    </PaneContent>
  )
}

export default LogEvents
