// TODO [Rebuild] delete comment? Still relevant?
// DO NOT remove or edit these UseCases. Only add if you need to but let @a-lchen know so we can make sure metrics will be aware of a new use case

export enum UseCases {
  Work = "Coworkers / remote team",
  Other = "Other",
}

// Include undefined for convenience so we can check arr.includes(undefined)
export type UseCasesArray = Readonly<(UseCases | undefined)[]>

export const REMOTE_WORK_OFFICE_USE_CASES = [UseCases.Work]

export const REMOTE_WORK_USE_CASES: UseCasesArray = REMOTE_WORK_OFFICE_USE_CASES

export enum USE_CASES_FILTER_OPTIONS {
  RWO = "RWO",
  RW = "RW",
}

export const USE_CASES_FILTER_MAP: Record<USE_CASES_FILTER_OPTIONS, UseCasesArray> = {
  [USE_CASES_FILTER_OPTIONS.RWO]: REMOTE_WORK_OFFICE_USE_CASES,
  [USE_CASES_FILTER_OPTIONS.RW]: REMOTE_WORK_OFFICE_USE_CASES,
}

/** Returns a fixed string (should not change over time) to represent the group the specified use case falls under in metrics. */
export const getMetricsUseCaseGroup = (
  reason?: UseCases,
): "other" | "remotework-office" | "remotework" => {
  if (!reason) return "other"

  // kebab-case will allow us to query with "LIKE"
  if (REMOTE_WORK_OFFICE_USE_CASES.includes(reason)) return "remotework-office"

  if (REMOTE_WORK_USE_CASES.includes(reason)) return "remotework"

  return "other"
}
