import { initContract } from "@ts-rest/core"
import { z } from "zod"

import { HttpV2Paths, RequestMethod } from "../../httpAPI"

const c = initContract()

export const spawnTokenDetails = c.router({
  get: {
    method: RequestMethod.GET,
    path: HttpV2Paths.SpaceSpawnTokenDetails,
    responses: {
      200: z.object({
        name: z.string(),
        capacity: z.number().nullable(),
      }),
    },
  },
})
